import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import PhotoModal from './PhotoModal';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: '20px',
    textAlign: 'left',
    paddingBottom: '10px',
  },
  list: {
    paddingLeft: '20px',
    paddingRight: '20px',
    textAlign: 'left',
  },
  listQuestion: {
    paddingTop: '20px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  listAnswer: {
    paddingTop: '8px',
    marginLeft: '10px',
    marginRight: '10px',
  },
}));

const Questions = (props) => {
  const classes = useStyles();
  const [isQuestionnaire, setIsQuestionnaire] = useState(true);
  const imageQuestionIds = [47,48,49,72,125,126]; // photo filtering
  const filteredAnswers = props.data.filter(
    (index) => !index.outcomeStr.includes('No response') || index.additionalResponse !== ''
  );

  useEffect(() => {
    setIsQuestionnaire(filteredAnswers.length !== 0);
  }, filteredAnswers);

  return (
    <>
      {isQuestionnaire && (
        <Paper elevation={3} square>
          <div style={{ marginLeft: '40px', marginRight: '40px' }}>
            <Typography
              variant='h4'
              color='textPrimary'
              className={classes.title}
            >
              Your Questionnaire / Request
            </Typography>
            <Divider style={{ marginTop: '10px', marginBottom: '20px' }} />
          </div>
          <Grid className={classes.list}>
            <ol>
              {filteredAnswers.map((question, i) => (
                <li key={question.questionStr}>
                  <Typography
                    variant='body2'
                    color='textPrimary'
                    className={classes.listQuestion}
                  >
                    {question.questionStr}
                  </Typography>
                  <Typography
                    variant='h5'
                    color='textPrimary'
                    className={classes.listAnswer}
                  >
                        {/* Photo Response */}
                        {question.additionalResponse !== '' && imageQuestionIds.includes(question.questionId) && (
                            <PhotoModal questionTitle={question.questionStr} additionalResponse={question.additionalResponse} />
                        )}
                        {/* Display outcomeStr */}
                        {!question.outcomeStr.includes('No response') && (
                              <div>
                              {question.outcomeStr.map((answer) => (
                                <div key={answer}>
                                     {answer}
                                </div>
                              ))}
                            </div>
                        )}                          
                        {/* Additional Response */}
                        {question.additionalResponse !== '' && !imageQuestionIds.includes(question.questionId) && (
                          `${question.additionalResponse}`
                        )} 
                  </Typography>
                </li>
              ))}
            </ol>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default Questions;
