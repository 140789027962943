import React, { useState, useRef, useEffect } from 'react';
import {
  FormControlLabel,
  Checkbox,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import CustomTooltip from './CustomTooltip';
import styles from './TermsBox.module.scss';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  termsBox: {
    backgroundColor: '#f7f7f7',
    height: '250px',
    overflowY: 'auto',
    overflowX: 'clip',
    padding: '10px',
    width: '80%',
  },
  tooltipFitContent: {
    width: '80%',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  formcontrol: {
    alignItems: 'flex-start',
  },
  label: {
    marginTop: '5px',
  },
}));

const TermsBox = ({
  name,
  confirmGMPrivacy,
  handleConfirmGMPrivacy,
  children,
  confirmQmerit,
  handleConfirmQmerit,
}) => {
  const classes = useStyles();
  const [check1, setCheck1] = useState(confirmGMPrivacy);
  const [check2, setCheck2] = useState(confirmQmerit);
  const termsBoxRef = useRef();
  const [bottom, setBottom] = useState(confirmGMPrivacy ? true : false);

  useEffect(() => {
    const isBottom = termsBoxRef.current
      ? termsBoxRef.current.scrollHeight -
          Math.round(termsBoxRef.current.scrollTop) <=
        termsBoxRef.current.clientHeight
      : false;
    if (!bottom && isBottom) {
      setBottom(isBottom);
    }
  }, []);

  const handleCheck1 = (e) => {
    setCheck1(e.target.checked);
    handleConfirmGMPrivacy(e.target.checked);
  };

  const handleCheck2 = (e) => {
    setCheck2(e.target.checked);
    handleConfirmQmerit(e.target.checked);
  };

  const handleScroll = (e) => {
    // using rounding in scrollTop and <= to account for decimal values
    const isFrameBottom =
      e.target.scrollHeight - Math.round(e.target.scrollTop) <=
      e.target.clientHeight;
    if (isFrameBottom) {
      setBottom(isFrameBottom);
    }
  };

  return (
    <>
      <div
        className={classes.termsBox}
        onScroll={handleScroll}
        ref={termsBoxRef}
      >
        <div className='paragraph-1--secondary'>{children}</div>
      </div>

      <CustomTooltip
        title={
          bottom ? '' : 'Please read through all of the terms before accepting'
        }
        className={classes.tooltipFitContent}
      >
        <Grid
          container
          style={{
            marginTop: '2%',
          }}
        >
          <FormControlLabel
            labelPlacement='end'
            className={classes.formcontrol}
            data-dtm='personal information acknowledgement'
            control={
              <Checkbox
                size='small'
                checked={check1}
                onChange={handleCheck1}
                name={name}
                style={{ paddingTop: 20, alignSelf: 'start' }}
              />
            }
            label={
              <div
                color={bottom ? 'textPrimary' : '#afafaf'}
                className={`body3 ${styles.label}`}
              >
                By checking this box and accepting this offer, I acknowledge
                that I have read these Cadillac LYRIQ Customer Charging
                Offer Terms and Conditions and agree to be bound the same.
              </div>
            }
            disabled={!bottom}
          />
        </Grid>
      </CustomTooltip>

      <div className={classes.tooltipFitContent}>
        <Grid container>
          <FormControlLabel
            labelPlacement='end'
            className={classes.formcontrol}
            data-dtm='personal information acknowledgement'
            control={
              <Checkbox
                size='small'
                checked={check2}
                onChange={handleCheck2}
                name={name}
                style={{ paddingTop: 20, alignSelf: 'start' }}
              />
            }
            label={
              <div
                className={`body3 ${styles.label}`}
              >
                By clicking Accept below, I consent to GM sharing my name,
                address, phone number, and email address with Qmerit to
                facilitate the match to a qualified electrician in my area for
                purposes of installing charging at my home, and to GM, Qmerit,
                and those acting on their behalf contacting me (including by
                autodialed calls) at the telephone number and email address I
                provided in connection with this offer. All information shared
                with Qmerit and Qmerit's services will be subject to the&nbsp;
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://qmerit.com/privacy-policy/'
                >
                  Qmerit Privacy Statement
                </a>
                .
              </div>
            }
          />
        </Grid>
      </div>
    </>
  );
};

export default TermsBox;
