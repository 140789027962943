import React, { useEffect, useState } from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
import TakeAPhoto from '../UIElements/TakeAPhoto';

const DropDialog = (props) => {
	const [open, setOpen] = useState(false);

	const topText = props.text;
	const bottomContainerText = props.bottomText;

	const [buttonText, setButtonText] = useState('Take a photo');

	useEffect(() => {
		if (props.files.length) {
			setButtonText('Retake a photo');
		} else {
			setButtonText('Take a photo');
		}
	}, [props.files]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = (files) => {
		//Saving files and closing Modal
		setOpen(false);
		props.onSave(files);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	return (
		<div>
			<DropzoneDialog
				open={open}
				onSave={handleSave.bind(this)}
				filesLimit={props.filesLimit}
				acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
				showPreviews={true}
				maxFileSize={5000000}
				onClose={handleClose.bind(this)}
				submitButtonText={'Upload'}
				showAlerts={false}
				previewGridProps={{ container: { spacing: 6 }, item: { xs: 8, sm: 6, md: 4 } }}
			/>
			<TakeAPhoto
				showInfoTextContainer={true}
				openDialog={handleOpen.bind(this)}
				takeAPhotoText={buttonText}
				topText={topText}
				bottomContainerText={bottomContainerText}
			/>
		</div>
	);
};

DropDialog.defaultProps = {
	filesLimit: 1,
};

export default DropDialog;
