import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Calendar from './Calendar';
import ReviewAppointment from '../Forms/ReviewAppointment';
import SuccessScreen from '../Forms/SuccessScreen';
import { useMsal, useAccount } from '@azure/msal-react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function ScheduleStepper() {
  let params = useParams();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [profileId, setProfileId] = useState(null);
  const [requestId, setRequestId] = useState('');
  const [scheduleId, setScheduleId] = useState('');
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [zipCode, setZIP] = useState('');
  const [booked, setBooked] = useState(false);
  const [electricianId, setElectricianId] = useState('');

  const { accounts } = useMsal();

  const account = useAccount(accounts[0] || {});
  if (account && account.idTokenClaims.sub && !profileId) {
    setProfileId(account.idTokenClaims.sub);
  }

  useEffect(() => {
    const searchRequestId = async () => {
      if (params.requestId) {
        setRequestId(params.requestId);
      }
      if (params.scheduleId) {
        setScheduleId(params.scheduleId);
      }
    };
    searchRequestId();
  }, [params.requestId]);

  const handleNext = (
    requestId,
    date,
    time,
    month,
    day,
    year,
    startTime,
    endTime,
    name,
    address,
    phone,
    email,
    zipCode,
    booked,
    electricianId
  ) => {
    setRequestId(requestId);
    setDate(date);
    setTime(time);
    setStartTime(year + '-' + month + '-' + day + 'T' + startTime);
    setEndTime(year + '-' + month + '-' + day + 'T' + endTime);
    setName(name);
    setAddress(address);
    setPhone(phone);
    setEmail(email);
    setZIP(zipCode);
    setBooked(booked);
    setElectricianId(electricianId);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (newValues) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleConfirm = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  function getSteps() {
    return ['Step 1 of 2', 'Step 2 of 2', 'Success'];
  }

  function getStepContent(stepIndex) {
    const isLastStep = activeStep === steps.length - 1;
    switch (stepIndex) {
      case 0:
        return (
          <Calendar
            profileId={profileId}
            requestId={requestId}
            isLastStep={isLastStep}
            handleNext={handleNext}
          />
        );
      case 1:
        return (
          <ReviewAppointment
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            handleConfirm={handleConfirm}
            requestId={requestId}
            date={date}
            time={time}
            startTime={startTime}
            endTime={endTime}
            name={name}
            address={address}
            phone={phone}
            email={email}
            zipCode={zipCode}
            booked={booked}
            scheduleId={scheduleId}
            electricianId={electricianId}
          />
        );
      case 2:
        return <SuccessScreen />;
      default:
        return 'Unknown stepIndex';
    }
  }

  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.instructions}>
          {getStepContent(activeStep)}
        </Typography>
      </div>
    </div>
  );
}
