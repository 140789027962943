import React, { useState } from 'react';
import { Grid, Typography, Box, makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Toggle from '../UIElements/Toggle';
import Tooltip from './Tooltip';

const useStyles = makeStyles((theme) => ({
	relative: {
		position: 'relative',
		height: '0px',
		width: '0px',
	},
	yes: {
		'z-index': 2,
		color: 'white',
		fontSize: 11,
		position: 'absolute',
		top: '-6px',
		left: '14px',
		fontWeight: 700,
		letterSpacing: '0.05em',
		fontFamily: 'Arial, Times, serif',
		pointerEvents: 'none',
	},
	no: {
		'z-index': 2,
		color: 'black',
		fontSize: 11,
		position: 'absolute',
		top: '-6px',
		left: '-33px',
		fontWeight: 700,
		letterSpacing: '0.05em',
		fontFamily: 'Arial, Times, serif',
		pointerEvents: 'none',
	},
}));

const ToggleQuestion = (props) => {
	const classes = useStyles();
	const [value, setValue] = useState(props.value);

	const handleChange = (e) => {
		setValue(e.target.checked ? props.options[1].value : props.options[0].value);

		props.onChange(e.target.checked ? props.options[1].value : props.options[0].value);
	};

	return (
		<>
			<Grid container>
				<Grid item xs={12} sm={10}>
					<Typography component="div">
						<Box fontSize="h7.fontSize" fontWeight="800">
							<div className="headline-5--primary">
								{props.question}
								{props.tooltipContent && <Tooltip content={props.tooltipContent} />}
								<br />
								<br />
							</div>
						</Box>
					</Typography>
				</Grid>
			</Grid>
			<Grid component="label" container alignItems="center" spacing={1}>
				{value === props.options[1].value && (
					<div className={classes.relative}>
						<span className={classes.yes}>YES</span>
					</div>
				)}
				<Toggle
					checked={value === props.options[1].value ? true : false}
					onChange={handleChange}
					name={props.name}
					checkedIcon={<CheckCircleIcon style={{ fontSize: '30px' }} />}
				/>
				{value !== props.options[1].value && (
					<div className={classes.relative}>
						<span className={classes.no}>NO</span>
					</div>
				)}
			</Grid>
		</>
	);
};

export default ToggleQuestion;
