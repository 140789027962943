import React, { useState } from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import Home from "../Home/Home";
import ScheduleStepper from "../Schedule/ScheduleStepper";
import ViewRequest from "../ViewRequest/ViewRequest";
import LoggedOut from "../LoggedOut/LoggedOut";
import Layout from "./Layout";
import Examples from "../shared/Examples";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../authConfig";
import Appointments from "../Appointments/Appointments";
import CancelScreen from "../Forms/CancelScreen";
import CustomerConsent from "../CustomerConsent/CustomerConsent";
import HomeChargerForm from "../home-charger-form/home-charger-form";
import { Box, ThemeProvider } from "@material-ui/core";
import themeQuestionnaire from "../../styles/questionnaire/mui-theme/theme";
import theme from "../../styles/theme";
import { useEffect } from "react";

import { getEndpointUrls } from "../services/api";
export const HomeRoute = "/";
export const ScheduleRoute = "/schedule/:requestId/:scheduleId";
export const ViewRequestRoute = "/viewRequest/:requestId";
export const LoggedOutRoute = "/loggedout/";
export const ExamplesRoute = "/examples/";
export const AppointmentsRoute = "/Appointments/:id/";
export const CancelScreenRoute = "/cancel/";
export const CustomerConsentRoute = "/CustomerConsent";
export const QuestionnaireRoute = "/electric/ev-charging/installation-form";

const Routing = (props) => {
  const isCustomerConsent = window.location.href.includes("/CustomerConsent");
  const isQuestionnaire = window.location.href.includes(
    "/electric/ev-charging/installation-form"
  );

  const [styles, setStyles] = useState(null);
  const endPointUrls = getEndpointUrls();
  const backendBaseUrl = endPointUrls.backendURL;

  useEffect(() => {
    if (isQuestionnaire) {
      import("../../styles/questionnaire/chevrolet/_brand.scss").then(() => {
        setStyles(
          require("../../styles/questionnaire/chevrolet/mui-theme-exports.module.scss")
        );
      });
    } else {
      import("../../styles/_brand.scss").then(() => {
        setStyles(require("../../styles/mui-theme-exports.module.scss"));
      });
    }
  }, [isQuestionnaire, isCustomerConsent]);

  return (
    <>
      {!isCustomerConsent && !isQuestionnaire && (
        <Layout>
          {/* <Switch> */}
          <Route path={HomeRoute} exact component={Home}>
            {/* <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
          >
            <Home />
          </MsalAuthenticationTemplate> */}
          </Route>
          <Switch>
            <Route path={ScheduleRoute} component={ScheduleStepper}>
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
              >
                <ScheduleStepper />
              </MsalAuthenticationTemplate>
            </Route>
            <Route path={ViewRequestRoute}>
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
              >
                <ViewRequest />
              </MsalAuthenticationTemplate>
            </Route>
            <Route path={AppointmentsRoute}>
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
              >
                <Appointments />
              </MsalAuthenticationTemplate>
            </Route>
          </Switch>
          <Route path={LoggedOutRoute} component={LoggedOut} />
          <Route path={ExamplesRoute} component={Examples} />
          <Route path={CancelScreenRoute} component={CancelScreen} />
        </Layout>
      )}
      {isCustomerConsent && !isQuestionnaire && (
        <ThemeProvider theme={theme(styles)}>
          <Route path={CustomerConsentRoute}>
            <CustomerConsent />
          </Route>
        </ThemeProvider>
      )}
      {!isCustomerConsent && isQuestionnaire && (
        <ThemeProvider theme={themeQuestionnaire(styles)}>
          <Route path={QuestionnaireRoute}>
            <section className="basic-grid-block-section">
              <div className="basic-grid-item">
                <Box style={{ paddingInline: 200 }}>
                  <HomeChargerForm backendBaseUrl={backendBaseUrl} />
                </Box>
              </div>
            </section>
          </Route>
        </ThemeProvider>
      )}
    </>
  );
};
export default withRouter(Routing);
