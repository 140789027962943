import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import SelectQuestion from '../UI/QuestionTypes/SelectQuestion';
import TextQuestion from '../UI/QuestionTypes/TextQuestion';
import RadioQuestion from '../UI/QuestionTypes/RadioQuestion';

const Question10 = (props) => {
	const question = 'Would you like to have a charging outlet (240-volt dryer outlet) or a charging unit installed?';
	const options = [
		{ value: '27', text: 'Charging outlet' },
		{ value: '28', text: 'Charging unit' },
	];

	return (
		<SelectQuestion
			name="question10"
			dataDtm="charging unit or outlet"
			value={props.question10}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Question11 = (props) => {
	const question = 'Have you purchased a charging unit? ';
	const options = [
		{ value: '51', text: 'Yes, I have purchased a charging unit' },
		{ value: '52', text: 'No, I will need Qmerit to assist me with determining which charging unit to purchase' },
	];

	return (
		<RadioQuestion
			name="question11"
			dataDtm="charging unit or outlet"
			value={props.question11}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Question12 = (props) => {
	const question = 'Insert brand and model here';

	return (
		<TextQuestion
			name="question12"
			dataDtm="charging unit or outlet"
			value={props.question12}
			onChange={props.handleChange}
			question={question}
			required={true}
			maxLength={255}
		/>
	);
};

const Group4 = (props) => {
	const classes = useStyles();
	const { submitRef, enableNavButton } = props;
	const [question10, setQuestion10] = useState(props.formData.question10);
	const [question11, setQuestion11] = useState(props.formData.question11);
	const [question12, setQuestion12] = useState(props.formData.question12);

	useEffect(() => {
		const enable = question10 === '27' || question11 === '52' || question12;
		enableNavButton(enable);
	}, [question10, question11, question12]);

	useImperativeHandle(submitRef, () => ({
		validateGroup5Form: () => {
			// add validation if needed
			return [true, { question10: question10, question11: question11, question12: question12 }];
		},
	}));

	const handleQuestion10Change = (newValue) => {
		setQuestion10(newValue);
	};

	const handleQuestion11Change = (newValue) => {
		setQuestion11(newValue);
	};

	const handleQuestion12Change = (newValue) => {
		setQuestion12(newValue);
	};

	return (
		<>
			<Box>
				<form className={classes.root} autoComplete="off">
					<Question10 question10={question10} handleChange={handleQuestion10Change} />
					{question10 && question10 === '28' && (
						<Box mt={2}>
							<Question11 question11={question11} handleChange={handleQuestion11Change} />
							{question11 && question11 === '51' && (
								<Box mt={2}>
									<Question12 question12={question12} handleChange={handleQuestion12Change} />
								</Box>
							)}
						</Box>
					)}
				</form>
			</Box>
		</>
	);
};

export default Group4;
