const questionMap = {
	chargerType:{
		id: 200,
		group: "Charger Type",
		response_type: 'outcomeId',
		required:true 
	},
	question18: {
		id: 44,
		group: 'Vehicle Model Year',
		response_type: 'outcomeId',
		required: true, // test that this is indeed required
	},
	question19: {
		id: 44,
		group: 'Vehicle Model Year',
		response_type: 'additionalResponse',
	},
	question1: {
		id: 14,
		group: 'Rent or Own',
		response_type: 'outcomeId',
		required: true,
	},
	question2a: {
		id: 17,
		group: 'Rent or Own',
		response_type: 'outcomeId',
	},
	question2b: {
		id: 18,
		group: 'Rent or Own',
		response_type: 'outcomeId',
	},
	question3: {
		id: 19,
		group: 'Property Detail',
		response_type: 'outcomeId',
		required: true,
	},
	question4: {
		id: 20,
		group: 'Property Detail',
		response_type: 'outcomeId',
	},
	question28: {
		id: 111,
		group: 'Property Detail',
		response_type: 'outcomeId',
	},
	question29: {
		id: 112,
		group: 'Property Detail',
		response_type: 'additionalResponse',
	},
	question5: {
		id: 21,
		group: 'Garage Type',
		response_type: 'outcomeId',
		required: true,
	},
	question6: {
		id: 22,
		group: 'Garage Type',
		response_type: 'outcomeId',
	},
	question20: {
		id: 45,
		group: 'Garage Type',
		response_type: 'outcomeId',
	},
	question30: {
		id: 113,
		group: 'Garage Type',
		response_type: 'outcomeId',
	},
	question21: {
		id: 71,
		group: 'Install Location',
		response_type: 'outcomeId',
		required: true,
	},
	question8: {
		id: 24,
		group: 'Install Location',
		response_type: 'outcomeId',
		required: true,
	},
	question9: {
		id: 25,
		group: 'Install Location',
		response_type: 'outcomeId',
		required: true,
	},
	question10: {
		id: 26,
		group: 'Charging Unit or Outlet',
		response_type: 'outcomeId',
		required: true,
	},
	question11: {
		id: 27,
		group: 'Charging Unit or Outlet',
		response_type: 'outcomeId',
	},
	question12: {
		id: 28,
		group: 'Charging Unit or Outlet',
		response_type: 'additionalResponse',
	},
	question13: {
		id: 29,
		group: 'Electrical Panel Details',
		response_type: 'outcomeId',
		required: true,
	},
	question26: {
		id: 23,
		group: 'Electrical Panel Details',
		response_type: 'outcomeId',
		required: true,
	},
	question31: {
		id: 117,
		group: 'Electrical Panel Details',
		response_type: 'outcomeId',
	},
	question14: {
		id: 30,
		group: 'Electrical Panel Details',
		response_type: 'outcomeId',
		required: true,
	},
	question15: {
		id: 31,
		group: 'Electrical Panel Details',
		response_type: 'outcomeId',
		required: true,
	},
	question32: {
		id: 114,
		group: 'Electrical Panel Details',
		response_type: 'outcomeId',
		required: true,
	},
	question33: {
		id: 115,
		group: 'Electrical Panel Details',
		response_type: 'outcomeId',
		required: true,
	},
	question34: {
		id: 116,
		group: 'Electrical Panel Details',
		response_type: 'outcomeId',
		required: true,
	},
	question16: {
		id: 32,
		group: 'Upload Images',
		response_type: 'additionalResponse',
	},
	question22: {
		id: 47,
		group: 'Upload Images',
		response_type: 'additionalResponse',
		isImageQuestion: true,
		required: true,
	},
	question23: {
		id: 48,
		group: 'Upload Images',
		response_type: 'additionalResponse',
		isImageQuestion: true,
		required: true,
	},
	question24: {
		id: 49,
		group: 'Upload Images',
		response_type: 'additionalResponse',
		isImageQuestion: true,
		required: true,
	},
	question42: {
		id: 125,
		group: 'Upload Images',
		response_type: 'additionalResponse',
		isImageQuestion: true,
		required: true,
	},
	question25: {
		id: 72,
		group: 'Upload Images',
		response_type: 'additionalResponse',
		isImageQuestion: true,
	},
	question43: {
		id: 126,
		group: 'Upload Images',
		response_type: 'additionalResponse',
		isImageQuestion: true,
	},
	question27: {
		id: 84,
		group: 'Upload Images',
		response_type: 'outcomeId',
	},
	question35: {
		id: 118,
		group: 'Load Calculation',
		response_type: 'additionalResponse',
		required: true,
	},
	question36: {
		id: 119,
		group: 'Load Calculation',
		response_type: 'additionalResponse',
		required: true,
	},
	question37: {
		id: 120,
		group: 'Load Calculation',
		response_type: 'multiResponse',
		required: true,
	},
	question38: {
		id: 121,
		group: 'Load Calculation',
		response_type: 'additionalResponse',
	},
	question39: {
		id: 122,
		group: 'Additional Site Information',
		response_type: 'outcomeId',
		required: true,
	},
	question40: {
		id: 123,
		group: 'Additional Site Information',
		response_type: 'outcomeId',
		required: true,
	},
};

export const generateGuestAnswersBody = (formData) => {
	let body = [];

	Object.keys(formData).map((key) => {
		if (Object.prototype.hasOwnProperty.call(questionMap, key)) {
			let foundKey = false;
			for (let i = 0; i < body.length; i++) {
				if (body[i].questionId === questionMap[key].id) {
					foundKey = true;
					body[i][questionMap[key].response_type] = formData[key];
				}
			}
			if (!foundKey) {
				body.push({
					additionalResponse:
						questionMap[key].response_type === 'additionalResponse' ||
						(questionMap[key].response_type === 'multiResponse' && formData[key])
							? formData[key]
							: '',
					outcomeId:
						// use outcomeId of 205 for "Multi" for any multiResponse questions
						questionMap[key].response_type === 'outcomeId' && formData[key]
							? parseInt(formData[key])
							: questionMap[key].response_type === 'multiResponse' && formData[key]
							? 205
							: 0,
					questionId: questionMap[key].id,
				});
			}
		}
	});

	return body;
};

export const generateSnapshotUpdateBody = (formData) => {
	return {
		addresses: [
			{
				address_line1: formData.address1,
				address_line2: formData.address2,
				city_name: formData.city,
				country_code: 'US',
				postal_code: formData.zip,
				state_or_province: formData.state,
			},
		],
		country_code: 'US',
		emails: [
			{
				email_address: formData.emailAddress,
			},
		],
		person: {
			first_name: formData.firstName,
			last_name: formData.lastName,
		},
		phones: [
			{
				phone_number: formData.phone.replace(/\D/g, ''),
			},
		],
	};
};

// Model no longer required in payload
const getVehicleModel = (outcomeId) => {
	switch (outcomeId) {
		case '37':
			return 'Bolt EV';
		case '38':
			return 'Bolt EUV';
		case '207':
			return 'Bolt EV';
		case '208':
			return 'Bolt EUV';
		case '209' :
			return 'Blazer EV' ;
		case '10':
			return 'other';
		default:
			return 'Bolt EV';
	}
};

export const generateSubmitRequestBody = (formData) => {
	return {
		personalInfo: {
			address1: formData.address1,
			address2: formData.address2,
			city: formData.city,
			country: 'US',
			email: formData.emailAddress,
			firstName: formData.firstName,
			lastName: formData.lastName,
			phoneNumber: formData.phone.replace(/\D/g, ''),
			state: formData.state,
			zip: formData.zip,
		},
		requestComment: 'New Request',
		requestOrigin: 'DRP',
		requestType: 'Self_Pay',
		terminationQuestionId: 0, //what is this?
		requesterId: 'guest',
		brand: 'Chevrolet',
		vehicleModel: getVehicleModel(formData.question18),
		termsAccepted: formData.termsAccepted && formData.piAckAccepted,
		questionnaire: generateGuestAnswersBody(formData),
	};
};

// Checks if all mandatory questions are answered - returns false if all is answered; else returns step name of the first mandatory question unanswered
export const checkMandatoryQuestions = (formData, stepsList) => {
	if (!formData) return 'Welcome';

	let revisitStep = '';
	for (var step in stepsList) {
		Object.keys(questionMap).map((key) => {
			if (questionMap[key].group === stepsList[step]) {
				if (questionMap[key].required && !formData[key]) {
					revisitStep = stepsList[step];
					return;
				}
			}
		});
	}

	return revisitStep || false;
};

export const generateFinalSubmitRequestBody = (formData) => {
	return {
		termsAccepted: formData.termsAccepted && formData.piAckAccepted,
		questionnaire: generateGuestAnswersBody(formData),
		requestId: formData.requestId,
	};
};

/* 
	Input: Question response object from backend 
	Output Example: {key: 'question16', response_type: 'additionalResponse'}
*/

export const getAnswerKeyAndResponseType = (response) => {
	let answerKey = [];
	let key;
	for (let i = 0; i < Object.keys(questionMap).length; i++) {
		key = Object.keys(questionMap)[i];
		if (Object.prototype.hasOwnProperty.call(questionMap, key)) {
			if (response.questionId === questionMap[key].id) {
				answerKey.push({ key: key, responseType: questionMap[key].response_type });
			}
		}
	}
	return answerKey;
};

export const getQuestionIdFromFormKey = (formKey) => {
	return questionMap[formKey] ? questionMap[formKey].id : null;
};

export const determineLatestStep = (formData, steps, images) => {
	//const piErrors = dataValidation(formData);

	let latestStep = 'Welcome'; // If no questions were ever answered, return welcome step

	let hasImages = false;
	for (let i = 0; i < images.length; i++) {
		if (images[i]) {
			hasImages = true;
		}
	}

	if (hasImages) {
		latestStep = 'Upload Images'; // assumes image upload is the last step
	} else {
		for (var step in steps) {
			if (
				steps[step] !== 'Welcome' &&
				steps[step] !== 'Terms' &&
				steps[step] !== 'Address' &&
				steps[step] !== 'Upload Images' &&
				steps[step] !== 'Confirmation'
			) {
				Object.keys(questionMap).map((key) => {
					if (questionMap[key].group === steps[step] && formData[key]) {
						latestStep = steps[step];
					}
				});
			}
		}
	}

	// if (latestStep !== 'Welcome') {
	// 	if (piErrors.length > 0) {
	// 		return 'Address';
	// 	}
	// }

	return latestStep;
};

export const generateAddressValidationBody = (formData) => {
	let body = {
		Header: {
			SourceSystemName: '189162',
			CountryCode: 'US',
		},
		ListOfAddresses: {
			Address: {
				AddressLine1: '',
				City: '',
				'StateOrProvinceCountrySub-DivisionID': '',
				Zipcode: '',
				Country: 'UNITED STATES',
			},
		},
	};

	body.ListOfAddresses.Address.AddressLine1 = formData.address1;
	body.ListOfAddresses.Address.City = formData.city;
	body.ListOfAddresses.Address['StateOrProvinceCountrySub-DivisionID'] = formData.state;
	body.ListOfAddresses.Address.Zipcode = formData.zip;

	return body;
};

export const getImageQuestionsList = () => {
	let imageQuestionList = [];
	for (let i = 0; i < Object.keys(questionMap).length; i++) {
		if (questionMap[Object.keys(questionMap)[i]].isImageQuestion) {
			imageQuestionList.push(Object.keys(questionMap)[i]);
		}
	}
	return imageQuestionList;
};
