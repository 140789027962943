import React, { useEffect, useState } from 'react';
import {
  Typography,
  makeStyles,
  Grid,
  Divider,
  Button,
} from '@material-ui/core';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useAccount,
} from '@azure/msal-react';
import { FiberManualRecord } from '@material-ui/icons';
import { getRequestIds } from '../services/api';
import { Link } from 'react-router-dom';
import { getRequestStatus } from '../services/api';
import LoginButton from '../shared/LoginButton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  root2: {
    justifyContent: 'center',
    maxWidth: '80%',
    paddingTop: '25px',
  },
  root3: {
    textAlign: 'center',
    justifyContent: 'center',
    maxWidth: '80%',
    paddingTop: '25px',
    paddingBottom: '50px',
  },
  textLogo: {
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: '160px',
  },
  heading: {
    textAlign: 'center',
    padding: '50px',
  },
  subtitle: {
    textAlign: 'left',
    marginLeft: '50px',
    marginBottom: '10px',
  },
  requestInfo: {
    textAlign: 'left',
    marginLeft: '50px',
  },
  button: {
    width: '90%',
  },
}));

export default function Home() {
  const classes = useStyles();

  const [profileId, setProfileId] = useState(null);
  const [orderedReqIdsArray, setOrderedReqIdsArray] = useState([]);
  const [reqIdsObj, setReqIdsObj] = useState({});
  const [reqIdsArray, setReqIdsArray] = useState([]);
  const [name, setName] = useState('');
  const scheduleId = 'new';

  const { accounts } = useMsal();
  const { instance } = useMsal();

  const account = useAccount(accounts[0] || {});
  if (account && account.idTokenClaims.sub && !profileId) {
    setProfileId(account.idTokenClaims.sub);
    setName(accounts[0].name);
  }

  useEffect(() => {
    if (profileId) {
      getRequests(profileId);
    }
  }, [profileId]);

  const getRequests = async (profileId) => {
    const res = await getRequestIds(profileId);
    setReqIdsObj(res);
    setReqIdsArray(Object.keys(res));
  };

  useEffect(() => {
    const activeReqIds = reqIdsArray.filter((reqId) => {
      let isActive =
        getRequestStatus(reqIdsObj[reqId].statusId).status === 'Active';
      return isActive;
    });

    const pendingReqIds = reqIdsArray.filter((reqId) => {
      let isPending =
        getRequestStatus(reqIdsObj[reqId].statusId).status === 'Pending';
      return isPending;
    });

    const completeReqIds = reqIdsArray.filter((reqId) => {
      let isComplete =
        getRequestStatus(reqIdsObj[reqId].statusId).status === 'Completed';
      return isComplete;
    });

    const canceledReqIds = reqIdsArray.filter((reqId) => {
      let isCanceled =
        getRequestStatus(reqIdsObj[reqId].statusId).status === 'Canceled';
      return isCanceled;
    });

    let orderedReqIds = activeReqIds.concat(
      pendingReqIds,
      completeReqIds,
      canceledReqIds
    );
    setOrderedReqIdsArray(orderedReqIds);
  }, [reqIdsObj, reqIdsArray]);

  return (
    <>
      <Grid container className={classes.root}>
        <UnauthenticatedTemplate>
          <Grid item xs={12} md={6} disableGutters='true'>
            <picture>
              <source
                media='(max-width: 960px)'
                srcset='images/ev-image-sm-1.png'
                style={{
                  objectFit: 'scale-down',
                }}
              />
              <source
                media='(max-width: 1280px)'
                srcset='images/ev-image-m-1.png'
                style={{
                  objectFit: 'scale-down',
                }}
              />
              <source
                media='(min-width: 1281px)'
                srcset='images/evimage1.png'
              />
              <img
                src='images/evimage1.png'
                alt='logo'
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                }}
              />
            </picture>
          </Grid>
          <Grid item xs={12} md={6} className={classes.textLogo}>
            <img
              src='images/ChevroletEV1.png'
              alt='logo'
              style={{
                marginBottom: '30px',
              }}
            />
            <Typography
              variant='h4'
              style={{
                marginBottom: '30px',
              }}
            >
              Welcome to General Motors<br></br>Home Charging Portal
              <br />
            </Typography>
            <LoginButton instance={instance}/>
          </Grid>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <Grid item xs={12} className={classes.root2}>
            <Typography variant='h2' color='textPrimary'>
              Schedule Your EV Home Charger<br></br>Installation
              <br />
              <br />
            </Typography>
            {name && (
              <Typography variant='h4' color='textPrimary'>
                Welcome, {name} <br></br>
              </Typography>
            )}
            <Typography
              variant='subtitle1'
              color='textPrimary'
              style={{ width: '70%' }}
            >
              <br></br>
              Below is your request(s); you can set up your appointment and view
              your questionnaire.
              <br />
              <br />
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.root3}>
            <Grid container spacing={3} style={{ alignItems: 'center' }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  className={classes.subtitle}
                  variant='h4'
                  color='textPrimary'
                >
                  Scheduling Request(s)
                </Typography>
                <Divider />
              </Grid>
              {reqIdsObj &&
                reqIdsArray &&
                orderedReqIdsArray.map((reqId) => {
                  return (
                    <React.Fragment key={reqId}>
                      <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                        <Typography
                          className={classes.requestInfo}
                          variant='h5'
                          color='textPrimary'
                        >
                          {`${reqIdsObj[reqId].requestId} - ${reqIdsObj[reqId].snapshot.street_address}`}
                          <br></br>
                          <Typography variant='caption' color='textPrimary'>
                            {getRequestStatus(reqIdsObj[reqId].statusId)
                              .status === 'Active' && (
                              <FiberManualRecord
                                style={{ fill: '#17cb49', fontSize: 8 }}
                              />
                            )}
                            {getRequestStatus(reqIdsObj[reqId].statusId)
                              .status === 'Pending' && (
                              <FiberManualRecord
                                style={{ fill: '#ff8139', fontSize: 8 }}
                              />
                            )}
                            {getRequestStatus(reqIdsObj[reqId].statusId)
                              .status === 'Completed' && (
                              <FiberManualRecord
                                style={{ fill: '#1b73ce', fontSize: 8 }}
                              />
                            )}
                            {getRequestStatus(reqIdsObj[reqId].statusId)
                              .status === 'Canceled' && (
                              <FiberManualRecord
                                style={{ fill: 'red', fontSize: 8 }}
                              />
                            )}
                            {`  ${
                              getRequestStatus(reqIdsObj[reqId].statusId).status
                            }`}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                        <Button
                          component={Link}
                          to={`/viewRequest/${reqId}`}
                          variant='contained'
                          color='secondary'
                          className={classes.button}
                        >
                          View Request
                        </Button>
                      </Grid>
                      {getRequestStatus(reqIdsObj[reqId].statusId)
                        .scheduleButton === 'schedule' && (
                        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                          <Button
                            component={Link}
                            to={`/schedule/${reqId}/${scheduleId}`}
                            variant='contained'
                            color='primary'
                            className={classes.button}
                          >
                            Schedule Appointment
                          </Button>
                        </Grid>
                      )}
                      {getRequestStatus(reqIdsObj[reqId].statusId)
                        .scheduleButton === 'disabled' && (
                        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                          <Button
                            disabled={true}
                            variant='contained'
                            className={classes.button}
                          >
                            Schedule Appointment
                          </Button>
                        </Grid>
                      )}
                      {getRequestStatus(reqIdsObj[reqId].statusId)
                        .scheduleButton === 'view' && (
                        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                          <Button
                            component={Link}
                            to={`/Appointments/${reqId}`}
                            variant='contained'
                            color='primary'
                            className={classes.button}
                          >
                            View Appointment
                          </Button>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Divider />
                      </Grid>
                    </React.Fragment>
                  );
                })}
            </Grid>
          </Grid>
        </AuthenticatedTemplate>
      </Grid>
    </>
  );
}
