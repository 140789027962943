import React, { useState } from 'react';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography, Box } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextQuestion from './TextQuestion';
import { useStyles } from '../../Questions/styles';
import Tooltip from './Tooltip';

const RadioQuestion = (props) => {
	const classes = useStyles();
	const [value, setValue] = useState(props.value);
	const [otherChecked, setOtherChecked] = useState(props.value === '10' ? true : false);

	const handleChange = (e) => {
		setValue(e.target.value);

		if (e.target.value === '10') {
			setOtherChecked(true);
		} else {
			setOtherChecked(false);
		}

		props.onChange(e.target.value);
	};

	return (
		<>
			<Grid container>
				<Grid item xs={12} md={props.sampleImageUrl ? 7 : 12}>
					<Grid item xs={12} sm={10}>
						<Typography component="div">
							<Box fontSize="h7.fontSize" fontWeight="800">
								<div className={props.followup ? 'paragraph-1--secondary' : 'block-quote-lg--primary'}>
									{props.question}
									{props.tooltipContent && <Tooltip content={props.tooltipContent} />}
								</div>
								<br />
							</Box>
						</Typography>
					</Grid>
					<Grid item xs={12} sm={10}>
						<FormControl component="fieldset" name={props.name}>
							<RadioGroup value={value} onChange={handleChange} required row={props.radioStyle === 'row'}>
								{props.options.map((key, i) => {
									return (
										<div key={i}>
											{props.options[i].imageUrl && (
												<Grid item xl={12}>
													<img
														className={classes.optionsImage}
														src={props.options[i].imageUrl}
														onError={(e) => {
															e.target.onerror = null;
															e.target.src = null;
															e.target.className = null;
														}}
													/>
												</Grid>
											)}
											<FormControlLabel
												key={i}
												className="stat-radio"
												data-dtm={props.dataDtm}
												value={props.options[i].value}
												control={<Radio checkedIcon={<CheckCircleIcon />} color="primary" />}
												label={
													<div className="paragraph-1--secondary">
														{props.options[i].text}
													</div>
												}
											/>
											{props.options[i].value === '10' && otherChecked ? (
												<Box>
													<TextQuestion
														name={props.otherName}
														value={props.otherValue}
														onChange={props.onOtherChange}
														question={'Please specify'}
														placeholderText={props.otherPlaceholderText}
														fullWidth={true}
													/>
												</Box>
											) : null}
										</div>
									);
								})}
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>
				{props.sampleImageUrl && (
					<Grid item sm={12} md={5}>
						<img className={classes.exampleImage} src={props.sampleImageUrl} alt={props.sampleImageDesc} />
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default RadioQuestion;

RadioQuestion.defaultProps = {
	radioStyle: 'column',
	followup: false,
};
