import { Typography, Grid, Button, ThemeProvider } from '@material-ui/core';
import { CadillacTheme } from './CustomerTheme';

export default function Examples() {
  return (
    <div>
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        spacing={3}
        item
      >
        <Typography variant='h3'>CHEVY FONT EXAMPLES</Typography>
        <Grid
          container
          direction='column'
          justifyContent='left'
          alignItems='left'
          spacing={3}
          item
        >
          <Grid item xs={12}>
            <Typography variant='h1' color='textPrimary'>
              Headline 1 Primary
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h2' color='textPrimary'>
              Headline 2 Primary - Display 2
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h3' color='textPrimary'>
              Headline 3 Primary - Display 3
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h4' color='textPrimary'>
              Headline 4 Primary
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5' color='textPrimary'>
              Headline 5 Primary
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' color='textPrimary'>
              Headline 6 Primary
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1' color='textPrimary'>
              Subtitle 1 Primary - Block quotes small
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle2' color='textPrimary'>
              Subtitle 2 Primary
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' color='textPrimary'>
              Body 1 Primary - Paragraph 1 VAR
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2' color='textPrimary'>
              Body 2 Primary - Paragraph 2
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='button' color='textPrimary'>
              Button 1 Primary
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='caption' color='textPrimary'>
              Caption 1 Primary
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='overline' color='textPrimary'>
              Overline 1 Primary
            </Typography>
          </Grid>
        </Grid>
        <Typography variant='h3'>BUTTON EXAMPLES</Typography>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          spacing={3}
          item
        >
          <Grid item xs={12}>
            <Button variant='contained' color='primary'>
              Button Primary
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant='contained' color='secondary'>
              Button Secondary
            </Button>
          </Grid>
        </Grid>
        <ThemeProvider theme={CadillacTheme}>
          <Typography variant='h3'>CADILLAC FONT EXAMPLES</Typography>
          <Grid
            container
            direction='column'
            justifyContent='left'
            alignItems='left'
            spacing={3}
            item
          >
            <Grid item xs={12}>
              <Typography variant='h1' color='textPrimary'>
                Headline 1 Primary
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h2' color='textPrimary'>
                Headline 2 Primary - Display 2
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h3' color='textPrimary'>
                Headline 3 Primary - Display 3
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h4' color='textPrimary'>
                Headline 4 Primary
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h5' color='textPrimary'>
                Headline 5 Primary
              </Typography>
            </Grid>
            {/*
            <Grid item xs={12}>
              <Typography variant='h6' color='textPrimary'>
                Headline 6 Primary
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle1' color='textPrimary'>
                Subtitle 1 Primary - Block quotes small
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle2' color='textPrimary'>
                Subtitle 2 Primary
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              <Typography variant='body1' color='textPrimary'>
                Body 1 Primary - Paragraph 1 VAR
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' color='textPrimary'>
                Body 2 Primary - Paragraph 2
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant='contained' color='primary'>
                Button 1 Primary
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='caption' color='textPrimary'>
                Caption Primary
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography variant='overline' color='textPrimary'>
                Overline 1 Primary
              </Typography>
            </Grid> */}
          </Grid>
        </ThemeProvider>
      </Grid>
    </div>
  );
}
