/* eslint-disable */
import { fieldAttributes } from './fieldsConfig';

const dataValidation = (data) => {
	let errors = [];

	//Taken and modified from previous form validation
	if (!fieldAttributes.firstName.pattern.test(data.firstName)) {
		errors.push({ type: 'firstName' });
	}

	if (!fieldAttributes.lastName.pattern.test(data.lastName)) {
		errors.push({ type: 'lastName' });
	}

	if (data.emailAddress === '') {
		errors.push({ type: 'email' });
	}

	if (!fieldAttributes.emailAddress.pattern.test(data.emailAddress)) {
		errors.push({ type: 'email' });
	}

	if (data.phone === '') {
		errors.push({ type: 'phone' });
	}

	if (!fieldAttributes.phone.pattern.test(data.phone)) {
		errors.push({ type: 'phone' });
	}

	if (data.address1 === '') {
		errors.push({ type: 'address1' });
	}

	if (data.city === '') {
		errors.push({ type: 'city' });
	}

	if (data.state === '') {
		errors.push({ type: 'state' });
	}

	if (data.zip === '') {
		errors.push({ type: 'zipCode' });
	}

	if (!fieldAttributes.zip.pattern.test(data.zip)) {
		errors.push({ type: 'zipCode' });
	}

	return errors;
};

export default dataValidation;
