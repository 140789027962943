import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { HashRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { CustomerTheme } from './components/shared/CustomerTheme';

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
    // loadProfile(event.payload.state, event.payload.idTokenClaims.sub, event.payload.idToken);
    // console.log('event: ', event);
  }
});

ReactDOM.render(
  <Router>
    <ThemeProvider theme={CustomerTheme}>
      <App pca={msalInstance} />
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
);
