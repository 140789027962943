import React, { useState, useRef, useEffect } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { useStyles } from '../../Questions/styles';
import CustomTooltip from '../UIElements/CustomTooltip';

const TermsBox = ({ name, value, children, onChange }) => {
	const classes = useStyles();
	const [checked, setChecked] = useState(value);
	const termsBoxRef = useRef();
	const [bottom, setBottom] = useState(value ? true : false);

	useEffect(() => {
		const isBottom = termsBoxRef.current
			? termsBoxRef.current.scrollHeight - Math.round(termsBoxRef.current.scrollTop) <=
			  termsBoxRef.current.clientHeight
			: false;
		if (!bottom && isBottom) {
			setBottom(isBottom);
		}
	}, []);

	const handleChange = (e) => {
		setChecked(e.target.checked);
		onChange(e.target.checked);
	};

	const handleScroll = (e) => {
		// using rounding in scrollTop and <= to account for decimal values
		const isFrameBottom = e.target.scrollHeight - Math.round(e.target.scrollTop) <= e.target.clientHeight;
		if (isFrameBottom) {
			setBottom(isFrameBottom);
		}
	};

	return (
		<>
			<div className={classes.termsBox} onScroll={handleScroll} ref={termsBoxRef}>
				<div className="paragraph-1--secondary">{children}</div>
			</div>

			<CustomTooltip
				title={bottom ? '' : 'Please read through all of the terms before accepting'}
				className={classes.tooltipFitContent}
			>
				<div>
					<FormControlLabel
						className="stat-checkbox"
						data-dtm="personal information acknowledgement"
						control={
							<Checkbox
								checked={checked}
								onChange={handleChange}
								name={name}
								color="primary"
								style={bottom ? {} : { pointerEvents: 'none' }}
							/>
						}
						label={<div className={bottom ? 'headline-5--primary' : 'headline-5--disabled'}>Accept</div>}
						disabled={!bottom}
					/>
				</div>
			</CustomTooltip>
		</>
	);
};

export default TermsBox;
