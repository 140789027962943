import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import RadioQuestion from '../UI/QuestionTypes/RadioQuestion';


const Question = (props) => {
	const question = 'What Charger Are You Interested In Installing?';
	const options = [
		{ value: '210', text: '7.7kW Charger' },
		{ value: '211', text: '11.5kW Charger' },
		{ value: '212', text: '19.2kW Charger' },
		{ value: '10', text: 'Other' },


	];

	return (
		<RadioQuestion
			dataDtm="property detail"
			name="question"
			value={props.chargerType}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const ChargerType = (props) => {
	const classes = useStyles();

	const {submitRef, enableNavButton } = props;
	const [chargerType, setChargerType] = useState(props.chargerType);
	const [disabledDropdown, setdisabledDropdown] = useState(
		props.disabledDropdown
	  );
	
	useImperativeHandle(submitRef, () => ({
		validateChargerTypeForm: () => {
		  // add validation if needed
		  return [true, chargerType, disabledDropdown];
		},
	  }));

	useEffect(() => {
		const enable = (chargerType === '210' ||  chargerType === '211' ||  chargerType === '212' || chargerType == '10') ;
		enableNavButton(enable);
	
	}, [chargerType]);


	const handleQuestionChange = (newValue) => {
		setChargerType(newValue);
	};

	

	return (
		<>
			<Box>
				<form className={classes.root} autoComplete="off">
					<Question chargerType={chargerType} handleChange={handleQuestionChange} />
				</form>
			</Box>
		</>
	);
};

export default ChargerType;
