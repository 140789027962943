import React, { useState, useRef, useEffect } from 'react';
import styles from './TermsBox.module.scss';


const Terms = () => {
  return (
    <div className={`body3 ${styles.term}`}>
      <b>Cadillac LYRIQ Customer Charging Offer Terms and Conditions</b>
      <br />
      <br />
      This "Offer" is available to you as part of your purchase or lease of a
      new Cadillac LYRIQ from a participating Cadillac dealer. Subject to
      the definitions, qualifications, limitations and requirements set forth in
      these "Terms and Conditions", you may qualify to pick one of two charging
      offers described below.
      <br />
      <br />
      <br />
      <br />
      DEFINITIONS:
      <br />
      <br />
      Throughout these Terms and Conditions, <b>You</b> and <b>Your</b> or{' '}
      <b>Customer</b> refer to the purchaser or lessee of an Eligible Vehicle as
      identified on the dealership purchase order documentation. <b>GM,</b>{' '}
      <b>We,</b> <b>Us,</b> and <b>Our</b> refer to General Motors, LLC, a
      Delaware limited liability company. In addition, certain words and phrases
      are defined as follows:
      <br />
      <br />
      <b>Offer Term:</b> Offer available to eligible customers who purchase or
      lease a new Cadillac LYRIQ, who take delivery from a Participating
      Dealer, unless the offer is otherwise terminated by GM.
      <br />
      <br />
      <b>Delivery Date</b> means the date your Eligible Vehicle is reported as
      delivered by your Participating Dealer.
      <br />
      <br />
      <b>Eligible Vehicle</b> means a new Cadillac LYRIQ that is
      purchased or leased from a Participating Dealership located in the United
      States.
      <br />
      <br />
      <b>EV Install Form</b> refers to the questionnaire the Customer is sent
      requesting information about the Customer's residential property. This
      information will be used by Qmerit and the Installer in order to provide
      the customer with a detailed breakdown of what work is required and a
      quote for the installation. The EV Install Form will be sent by GM or
      Qmerit.
      <br />
      <br />
      <b>EVgo Credits</b> means 2-years of unlimited charging session credit,
      which time period shall begin once Customer redeems the EVgo activation
      code, which gets sent by GM upon vehicle delivery. Such credits must be
      utilized by an eligible Customer at eligible EVgo Charging Stations. EVgo
      Credits are (i) non-transferable; (ii) not available for commercial
      drivers, fleet drivers, rideshare drivers, and carshare drivers, (iii)
      only useable on an Eligible Vehicle; and (iv) the Customers may not
      violate EVgo's terms and conditions, found here{' '}
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.evgo.com/terms-of-service/'
      >
        https://www.evgo.com/terms-of-service/
      </a>
      . If EVgo reasonably determines that a Customer has violated the terms of
      this Offer, EVgo shall be permitted to temporarily suspend and terminate
      such individual's EVgo Credit.
      <br />
      <br />
      <b>EVgo Charging Stations</b> means EVgo's electric vehicle service
      equipment available to the public on EVgo's public network that supplies
      electricity for the charging and recharging of an EV.
      <br />
      <br />
      <b>Installation Credit</b> means up to $1,500.00 that Cadillac will
      provide for eligible customers toward the total cost of home charging
      installation, where such installation is completed through Qmerit.
      <br />
      <br />
      <b>Installer</b> refers to a suitably skilled, qualified, insured, and
      licensed electrical contractor selected by Qmerit.
      <br />
      <br />
      <b>Participating Dealership, Participating Dealer or Dealer</b> means a
      motor vehicle dealership located in the United States that is authorized
      in writing and verified by GM to advertise and sell new Cadillac electric
      vehicles.
      <br />
      <br />
      <b>Qmerit</b> means Qmerit Electrification, LLC a limited liability
      company organized under the laws of Delaware, and is the GM preferred
      installation provider responsible for matching Customers with Installers
      who will complete the installations.
      <br />
      <br />
      <br />
      <br />
      OFFER PROCESS - How it Works
      <br />
      <br />
      Each eligible Customer will have the option to choose from the following
      offers: 1) an installation Credit, or 2) EVgo Credits. Prior to your
      Eligible Vehicle Delivery Date, you will be contacted by phone or email by
      GM or by your Cadillac Dealer to select the offer of your choice.
      <br />
      <br />
      INSTALLATION CREDIT OFFER
      <br />
      <br />
      For those Customers that choose the Installation Credit offer, GM or
      Qmerit will provide you with a link to the EV Install Form requesting
      information about your home charging installation, such as the address and
      photos of the residential property where you are seeking installation,
      which must be completed and submitted to process the Offer.{' '}
      <b>
        Please review all terms and conditions on the EV Install Form before you
        submit it.
      </b>{' '}
      After you submit the EV Install Form, Qmerit will find and select a local
      Installer to complete the installation. Once Qmerit has selected your
      Installer, Qmerit will provide you with the Installer's business name
      address, phone number and email address, and will provide the Installer
      with your name, address, phone number and email address. Your Installer
      will reach out via the telephone number provided on the EV Install form to
      review and confirm your information. Each Installer will
      obtain, on your behalf, all necessary permitting that may be required
      under applicable local law. The Customer is responsible for obtaining any
      and all other necessary approvals, such as from an applicable homeowners'
      association or landlord, and notifying the Installer of all such
      approvals. Once all required permits and approvals are obtained, the
      Installer will schedule your installation. Once installation is complete,
      the Installer will submit confirmation to Qmerit, and Qmerit will share
      the confirmation with GM. GM will provide up to a $1,500.00 credit toward
      a Customer's installation completed by a Qmerit Installer for Customers
      who choose the Installation Credit Offer. In such a case, the Qmerit
      Installer shall complete the installation and the Customer will be
      responsible to pay the Installer directly for all costs that exceed
      $1,500.00. This credit can be used towards installation of a
      customer-supplied charging station or outlet.{' '}
      <b>
        Installation must be completed within 180 days of your Eligible Vehicle
        Delivery Date.
      </b>{' '}
      A wall charging unit is not included as part of the Installation Credit
      offer and is sold separately. The Customer is solely responsible for all
      costs associated with installation that exceed $1,500.00, to be paid
      directly to Installer. If the Customer receives the Installation Credit
      and proceeds to cancel its order for an Eligible Vehicle before the
      Vehicle is delivered to the Customer, the Customer will be responsible for
      reimbursing GM the total amount of credit paid on the Customer's behalf,
      up to $1,500.00, direct to GM, upon GM's request.
      <br />
      <br />
      EVGO CREDIT OFFER
      <br />
      <br />
      For those Customers that choose the EVgo Credit Offer, GM will provide the
      Customer with an EVgo activation code to be redeemed for two (2) years of
      unlimited EVgo public charging credits to be used toward public charging
      through EVgo.{' '}
      <b>
        The EVgo authorization code must be redeemed at EVgo.com within sixty
        (60) days of Eligible Vehicle Delivery Date.
      </b>{' '}
      Such customers have unlimited access to EVgo's Charging Stations for (2)
      years from the date such customers first redeem the authorization code. If
      you fail to redeem the EVgo activation code within sixty (60) days of your
      Eligible Vehicle Delivery Date, your two (2) year period of unlimited
      charging will begin regardless of activation.
      <br />
      <br />
      GENERAL TERMS
      <br />
      <br />
      <b>AGREEMENT TO THE OFFICIAL RULES:</b> By selecting and receiving benefit
      from one of the offers described herein, each Customer fully and
      unconditionally agrees to and accepts these Terms and Conditions as the
      official rules of the Offer and further agrees the decisions of GM
      regarding this Offer are final and binding in all matters related to the
      Offer. These Terms and Conditions, constitute an agreement (this
      "Agreement") between GM and the Customer, and is the entire agreement
      between the parties concerning its subject matter, supersedes all prior
      agreements and understandings, whether or not written, and is not intended
      to confer upon any person other than the parties, including any creditors,
      any rights, or remedies hereunder.
      <br />
      <br />
      <b>GENERAL CONDITIONS / RELEASE:</b> Each Customer, by participating in
      this Offer, agrees (a) to release, indemnify and hold harmless GM, its
      affiliates, parents, subsidiaries, advertising and Offer agencies and
      their respective past and present directors, officers, employees,
      representatives and agents from and for any liability for any injuries,
      losses or damages of any kind, to persons or property (including without
      limitation death; claims based on publicity rights, defamation or invasion
      of privacy; and any claims based on a violation of a third party's
      intellectual property rights), resulting in whole or in part, directly or
      indirectly, from your participation in this Offer, installation, or in any
      related activity or your acceptance, possession, use, misuse or nonuse of
      a home charging outlet or unit. GM is not responsible in any way for
      Installer actions, recommendations, or any resulting damage caused by or
      attributable to your Installer. GM's failure to enforce any of these Terms
      and Conditions shall not constitute a waiver of that provision. In the
      event of any cancellation, termination or suspension, notice thereof will
      be posted at{' '}
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.cadillac.com/electric-life'
      >
        https://www.cadillac.com/electric-life
      </a>
      .
      <br />
      <br />
      <b>DISPUTES:</b> In consideration for the benefits that you receive under
      this Offer, you agree that any dispute relating to any aspect of your
      relationship with GM or any of its affiliates, including the purchase,
      installation and performance of your home charging outlet or your vehicle,
      shall be fully and finally resolved by binding arbitration administered by
      the American Arbitration Association (the "AAA"). The rules governing the
      arbitration may be accessed at{' '}
      <a target='_blank' rel='noopener noreferrer' href='https://www.adr.org/'>
        www.adr.org
      </a>{' '}
      or by calling the AAA at +1-800-778-7879. To the extent the initial filing
      fee for the arbitration exceeds the initial filing fee for a lawsuit, we
      will pay the difference in fees. ANY ARBITRATION WILL BE CONDUCTED BY THE
      PARTIES IN THEIR INDIVIDUAL CAPACITIES ONLY AND NOT AS A CLASS ACTION OR
      OTHER REPRESENTATIVE ACTION, AND THE PARTIES WAIVE THEIR RIGHT TO FILE A
      CLASS ACTION OR SEEK RELIEF ON A CLASS BASIS. THE PARTIES FURTHER
      EXPRESSLY WAIVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. All
      issues and questions concerning the construction, validity, interpretation
      and enforceability of these Terms and Conditions, Customer's rights and
      obligations, or the rights and obligations of GM in connection with the
      Offer, shall be governed by, and construed in accordance with, the laws of
      State of Michigan, without giving effect to any choice of law or conflict
      of law rules (whether of the State of Michigan or any other jurisdiction),
      which would cause the application of the laws of any jurisdiction other
      than the State of Michigan.
      <br />
      <br />
      <b>Misrepresentations and Fraud:</b> Your participating in the Offer may
      be cancelled by GM, in its sole discretion, if you conceal or misrepresent
      any material fact or circumstance, including but not limited to your
      personal information, Eligible Vehicle, or any information provided on the
      EV Install Form, or otherwise.
      <br />
      <br />
      <b>Non-Transferable:</b> An eligible Customer may not assign or transfer
      its rights under the Offer at any time. The rights under the Offer are
      non-transferable to any subsequent purchaser or lessee, or any other
      person or entity to whom the Eligible Vehicle is conveyed by operation of
      law or otherwise, including without limitation repossession, death, or as
      a gift.
      <br />
      <br />
      <b>Tax Implications:</b> You may be subject to federal, state or local tax
      on any benefits received under this Offer. You should contact a Tax
      advisor/consultant if you have any questions regarding the tax
      implications associated with this Offer.
    </div>
  );
};

export default Terms;
