import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Divider, Backdrop } from '@material-ui/core';
import {
  getRequest,
  getSnapshot,
  getBookedSlots,
  getRequestStatus,
} from '../services/api';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import CustomerInfo from '../CustomerInfo/CustomerInfocmpnt';
import { useParams } from 'react-router-dom';
import AppointmentDetails from './AppointmentDetails';
import ReturnHome from '../shared/ReturnHome';
import { FiberManualRecord } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useMsal, useAccount } from '@azure/msal-react';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  root2: {
    justifyContent: 'center',
    maxWidth: '80%',
    paddingTop: '25px',
  },
  root3: {
    justifyContent: 'center',
    maxWidth: '80%',
    paddingTop: '10px',
    paddingBottom: '30px',
  },
  paper: {
    marginTop: theme.spacing(6),
    padding: '20px',
    textAlign: 'auto',
    color: theme.palette.text.primary,
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  cancel: {
    color: theme.palette.error.main,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Appointments() {
  let { id } = useParams();
  const classes = useStyles();
  const [requestId, setRequestId] = useState(id);
  const [booked, setBooked] = useState(false);
  const [time, setTime] = useState('');
  const [date, setDate] = useState(new Date());
  const [scheduleId, setScheduleId] = useState('');
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [profileId, setProfileId] = useState(null);
  const { accounts } = useMsal();

  const account = useAccount(accounts[0] || {});
  if (account && account.idTokenClaims.sub && !profileId) {
    setProfileId(account.idTokenClaims.sub);
  }

  let history = useHistory();

  //Get information associated with requestId
  useEffect(() => {
    const searchRequestId = async () => {
      try {
        let response = await getRequest(requestId);
        if (profileId !== response.profileId || getRequestStatus(response.statusId).scheduleButton === 'disabled') {
          history.push('/');
        }
        setResults(response);
        response = await getBookedSlots(requestId);
        if (response.data.data.items.length) {
          setBooked(true);
          setDate(new Date(response.data.data.items[0].startTime));
          let start = moment(response.data.data.items[0].startTime);
          let end = moment(response.data.data.items[0].endTime);
          setTime(start.format('h:mm A') + ' - ' + end.format('h:mm A'));
          setScheduleId(response.data.data.items[0].scheduleId);
        } else {
          setBooked(false);
        }
      } catch {}
    };
    searchRequestId();
  }, [requestId, profileId]);

  //Get information associated with snapshotId from requestId results
  useEffect(() => {
    const searchSnapshotId = async (snapshotId) => {
      if (snapshotId) {
        setOpenBackdrop(true);
        setLoading(true);
        const response = await getSnapshot(snapshotId);
        setName(`${response.first_name} ${response.last_name}`);
        setAddress(
          `${response.street_address}${
            response.street_address2 ? ` ${response.street_address2}` : ''
          }, ${response.city_name}, ${response.state_or_province}, ${
            response.postal_code
          }`
        );
        setPhone(`${response.phone_number}`);
        setEmail(`${response.email_address}`);
        setLoading(false);
        setOpenBackdrop(false);
      } else {
      }
    };
    try {
      searchSnapshotId(results.snapshotId);
    } catch {}
  }, [results]);

  return (
    <>
      <ReturnHome />
      <Divider />
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.root2}>
          <Typography variant='h3' color='textPrimary'>
            Your Appointment Details
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.root2}>
          <Typography variant='h4' color='textPrimary'>
            Request ID {requestId}
          </Typography>
        </Grid>
        {loading ? (
          <div></div>
        ) : (
          <>
            {results && (
              <>
                <Grid item xs={12} className={classes.root3}>
                  <Typography
                    variant='subtitle1'
                    color='textPrimary'
                    style={{ marginBottom: '15px' }}
                  >
                    {getRequestStatus(results.statusId).status === 'Active' && (
                      <FiberManualRecord
                        style={{ fill: '#17cb49', fontSize: 8 }}
                      />
                    )}
                    {getRequestStatus(results.statusId).status ===
                      'Pending' && (
                      <FiberManualRecord
                        style={{ fill: '#ff8139', fontSize: 8 }}
                      />
                    )}
                    {getRequestStatus(results.statusId).status ===
                      'Completed' && (
                      <FiberManualRecord
                        style={{ fill: '#1b73ce', fontSize: 8 }}
                      />
                    )}
                    {getRequestStatus(results.statusId).status ===
                      'Canceled' && (
                      <FiberManualRecord style={{ fill: 'red', fontSize: 8 }} />
                    )}
                    {`  ${getRequestStatus(results.statusId).status}`}
                  </Typography>
                  {getRequestStatus(results.statusId).status !==
                    'Completed' && (
                    <Typography variant='subtitle1' color='textPrimary'>
                      If you need to cancel or reschedule your appointment,
                      please do so before your appointment date.
                    </Typography>
                  )}
                </Grid>
                <br />
                <br />
                <Grid style={{ width: '80%', paddingBottom: '50px' }}>
                  <div>
                    <CustomerInfo
                      name={name}
                      address={address}
                      phone={phone}
                      email={email}
                    />
                    <br />
                    <br />
                    <AppointmentDetails
                      booked={booked}
                      time={time}
                      date={date}
                      showButton={true}
                      requestID={requestId}
                      scheduleId={scheduleId}
                      status={getRequestStatus(results.statusId).status}
                    />
                  </div>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
}
