import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import RadioQuestion from '../UI/QuestionTypes/RadioQuestion';

const Question39 = (props) => {
	const question = 'Is your home powered by solar panels?';
	const options = [
		{ value: '18', text: 'Yes' },
		{ value: '19', text: 'No' },
	];

	return (
		<RadioQuestion
			name="question39"
			dataDtm="additional site information"
			value={props.question39}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Question40 = (props) => {
	const question = 'Does your home have an integrated backup generator?';
	const options = [
		{ value: '18', text: 'Yes' },
		{ value: '19', text: 'No' },
	];

	return (
		<RadioQuestion
			name="question40"
			dataDtm="additional site information"
			value={props.question40}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Group10 = (props) => {
	const classes = useStyles();
	const { submitRef, enableNavButton } = props;
	const [question39, setQuestion39] = useState(props.formData.question39);
	const [question40, setQuestion40] = useState(props.formData.question40);

	useEffect(() => {
		const enable = question39 && question40;
		enableNavButton(enable);
	}, [question39, question40]);

	useImperativeHandle(submitRef, () => ({
		validateGroup10Form: () => {
			// add validation if needed
			return [true, { question39: question39, question40: question40 }];
		},
	}));

	const handleQuestion39Change = (newValue) => {
		setQuestion39(newValue);
	};

	const handleQuestion40Change = (newValue) => {
		setQuestion40(newValue);
	};

	return (
		<>
			<Box>
				<form className={classes.root} autoComplete="off">
					<Question39 question39={question39} handleChange={handleQuestion39Change} />
					<Question40 question40={question40} handleChange={handleQuestion40Change} />
				</form>
			</Box>
		</>
	);
};

export default Group10;
