import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	paragraph: {
		display: 'block',
		marginBlock: '1em',
		marginInline: 0,
	}
}))

const Welcome = ({ getIsDealerAuthorized, enableNavButton }) => {

	const classes = useStyles();

	useEffect(() => {
		enableNavButton(true);
	}, []);

	return (
		<>
			<div>
				{getIsDealerAuthorized() ? (
					<div className="block-quote-lg--primary">
						Congratulations on the purchase of your new Chevrolet EV! <br />
						<br /> Let&apos;s get started with your installation request.
					</div>
				) : (
					<div className="block-quote-lg--primary">
						Let&apos;s get started with your installation request.
					</div>
				)}
				<p className={`${classes.paragraph} paragraph-1-alt--secondary`}>Before you start:</p>
				<p className={`${classes.paragraph} paragraph-1--secondary`}>
					As part of the EV Install Form you will be asked to take pictures of the location where you would
					like charging installed. We recommend completing this form on your mobile phone where you can take
					pictures directly from your camera. Uploading inappropriate, obscene, or offensive photos is
					strictly prohibited; GM has the sole and exclusive discretion to disqualify any Customer from this
					Promotion in the event such photos are uploaded.
				</p>
				<p className={`${classes.paragraph} paragraph-1--secondary`}>
					By filling out this EV Install Form you understand and agree that the information you input into the
					form will be handled in accordance with the{' '}
					<a
						href="https://www.gm.com/privacy-statement.html"
						target="_blank"
						rel="noreferrer"
						className="stat-text-link"
						data-dtm="request installation"
					>
						GM Privacy Statement
					</a>
					.
				</p>
			</div>
		</>
	);
};

export default Welcome;
