import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Grid,
  Container,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import { handleLogin, handleLogout } from "../services/authHandler";
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import LoginButton from "../shared/LoginButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    color: theme.palette.text.white,
  },
}));

export default function LoggedOut() {
  const classes = useStyles();
  const { instance } = useMsal();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <AppBar position='sticky' color='secondary'>
        <Toolbar>
          <Grid
            justifyContent='space-between'
            container
            alignItems='center'
          >
            <img src='images/EVLogo.png' alt='logo' className={classes.logo} />

          </Grid>
        </Toolbar>
      </AppBar>
      <Container maxWidth='lg'>
        <Grid alignItems='left' justifyContent='left' spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h3'>
              <br />
              You have signed out!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h4'>
              <br />
              Thanks for stopping by! Click the button to sign in again.
              <br />
              <br />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LoginButton handleOnClick={() => history.push("/")} instance={instance}/>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
