import React, { useState, useEffect } from "react";
import {
  Typography,
  makeStyles,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { Button } from "@mui/material";
import { getRequestId, recreateToEVGo, consent } from "../services/api";
import { ThemeProvider } from "@material-ui/core";
import { CadillacTheme } from "../shared/CustomerTheme";
import TermsBox from "./TermsBox";
import Terms from "./Terms";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    justifyContent: "center",
  },
  image: {
    width: "30%",
  },
  loadingContainer: {
    justifyContent: "center",
  },
  titleContainer: {
    justifyContent: "center",
  },
  titleText: {
    marginLeft: "5%",
    marginRight: "5%",
    marginBottom: "5%",
    textAlign: "center",
  },
  terms: {
    textAlign: "center",
    marginBottom: "3%",
  },
  bodyRootContainer: {
    justifyContent: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  bodyContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "5%",
    marginRight: "5%",
    maxWidth: "1000px",
  },
  bodyContainer2: {
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "6%",
    marginRight: "5%",
    marginBottom: "2%",
    maxWidth: "1000px",
  },
  bodyText: {
    width: "80%",
  },
  buttonContainerRoot: {
    justifyContent: "center",
    marginTop: "3%",
    marginBottom: "5%",
  },
  buttonContainer: {
    justifyContent: "center",
  },
  button: {
    margin: "7px",
    padding: "10px",
    width: "90%",
    maxWidth: "750px",
  },
  dialogContent: { textAlign: "center" },

  // Fix footer position
  pageContainer: {
    position: "relative",
    minHeight: "100vh",
  },
  contentWrap: {
    paddingBottom: "8.5rem",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "8.5rem",
  },
}));

const CustomerConsent = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState();
  const [confirmGMPrivacy, setConfirmGMPrivacy] = useState(false);
  const [confirmQmerit, setConfirmQmerit] = useState(false);
  const [EVgoOpen, setEVgoOpen] = useState(false);
  const [declineOpen, setDeclineOpen] = useState(false);
  const [EVgo, setEVgo] = useState(false);
  const [EVGoError, setEVGoError] = useState(false);
  const [requestIdError, setRequestIdError] = useState(false);
  const [noSessionId, setNoSessionId] = useState(false);
  const [declineError, setDeclineError] = useState(false);
  const [declined, setDeclined] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [acceptError, setAcceptError] = useState(false);
  const [alreadyEVGo, setAlreadyEVGo] = useState(false);
  const [canceledRequest, setCanceledRequest] = useState(false);
  const [alreadyDeclined, setAlreadyDeclined] = useState(false);
  const [alreadyConfirmed, setAlreadyConfirmed] = useState(false);
  const [isCadillac, setIsCadillac] = useState(false);
  const [isCadillacSelfEnroll, setIsCadillacSelfEnroll] = useState(false);

  useEffect(() => {
    const getCustomerInfo = async () => {
      const sessionId = new URLSearchParams(
        window.location.hash.split("?")[1]
      ).get("sessionId");
      if (sessionId) {
        const response = await getRequestId(sessionId); //customer data
        setResults(response);
        if (response) {
          if (response.brand === "Cadillac") {
            setIsCadillac(true);
            if (response.requestOrigin === "CSE") {
              setIsCadillacSelfEnroll(true);
            }
            if (
              response.statusId === 10 ||
              response.statusId === 11 ||
              response.statusId === 12 ||
              response.statusId === 13 ||
              response.statusId === 14 ||
              response.statusId === 15 ||
              response.statusId === 29 ||
              response.statusId === 32
            ) {
              // cancelled requests
              setCanceledRequest(true);
            } else if (response.requestType === "EVGo") {
              // EVGO selection has been confirmed previously
              setAlreadyEVGo(true);
            } else if (
              response.statusId === 33 ||
              response.requestType === "Opt_Out"
            ) {
              // Opt out selection has been confirmed
              setAlreadyDeclined(true);
            } else if (response.consentSubmittedByCustomer !== null) {
              // Install selection has been confirmed and consent accepted
              setAlreadyConfirmed(true);
            }
          }
        } else {
          setRequestIdError(true);
        }
        setLoading(false);
      } else {
        setLoading(false);
        setNoSessionId(true);
      }
    };
    getCustomerInfo();
  }, []);

  const handleConfirmGMPrivacy = (newValue) => {
    setConfirmGMPrivacy(newValue);
  };

  const handleConfirmQmerit = (newValue) => {
    setConfirmQmerit(newValue);
  };

  const handleEVgoDialogOpen = () => {
    setEVgoOpen(true);
  };

  const handleEVgoDialogClose = () => {
    setEVgoOpen(false);
  };

  const handleDeclineDialogOpen = () => {
    setDeclineOpen(true);
  };
  const handleDeclineDialogClose = () => {
    setDeclineOpen(false);
  };

  const handleDecline = async () => {
    setLoading(true);
    const isAccepted = false;
    try {
      const decline = await consent(results.requestId, isAccepted);
      if (decline.status === 200) {
        setDeclined(true);
        setLoading(false);
      }
    } catch (error) {
      setDeclineError(true);
      setLoading(false);
    }
  };

  const handleAccept = async () => {
    setLoading(true);
    const isAccepted = true;
    try {
      const accept = await consent(results.requestId, isAccepted);
      if (accept.status === 200) {
        setAccepted(true);
      }
      setLoading(false);
    } catch (error) {
      setAccepted(false);
      setAcceptError(true);
      setLoading(false);
    }
  };

  const handleSwitchToEVGo = async () => {
    setLoading(true);
    try {
      const switchToEVGo = await recreateToEVGo(results.requestId);
      if (switchToEVGo.status === 200) {
        setEVgo(true);
        setEVGoError(false);
        setLoading(false);
      }
    } catch (error) {
      setEVgo(false);
      setEVGoError(true);
      setLoading(false);
    }
  };

  let content = "";
  if (isCadillac === false) {
    content = "This page is only viewable for Cadillac requests.";
  }
  if (requestIdError === true) {
    content = "Unable to find an associated request.";
  }
  if (noSessionId === true) {
    content = "No Session ID Found.";
  }
  if (EVgo === true) {
    content = `You've successfully switched your request to EVgo Credit Offer. You will receive an email confirmation of this change.`;
  }
  if (EVGoError === true) {
    content = "Unable to switch to EVGo Credit Offer at this time.";
  }
  if (declined === true) {
    content = (
      <>
        <div className={`${classes.titleText} display-2`}>Thank you.</div>
        <div className={`${classes.titleText} paragraph-2`}>
          Your Cadillac LYRIQ Customer Charging Offer has been declined.
        </div>
      </>
    );
  }
  if (declineError === true) {
    content = "Unable to decline at this time.";
  }
  if (accepted === true) {
    content = (
      <>
        <div className={`${classes.titleText} display-2`}>
          Thank you for your submission!
        </div>
        <div className={`${classes.titleText} paragraph-2`}>
          A Qmerit Concierge will send you a questionnaire regarding home
          installation and reach out with next steps.
        </div>
      </>
    );
  }
  if (acceptError === true) {
    content = "Unable to confirm terms at this time.";
  }
  if (canceledRequest === true) {
    content = `This request is canceled.`;
  }
  if (alreadyDeclined === true) {
    content = `Your Cadillac LYRIQ Customer Charging Offer has been declined.`;
  }
  if (alreadyEVGo === true) {
    if (isCadillacSelfEnroll) {
      content = `Your selection has been confirmed for EVgo Credit Offer.`;
    } else
      content = `Your offer selection has been switched to the EVgo Credit Offer.`;
  }
  if (alreadyConfirmed === true) {
    content = `Your offer has already been confirmed. `;
  }

  return (
    <ThemeProvider theme={CadillacTheme}>
      {loading && (
        <Grid container className={classes.loadingContainer}>
          <CircularProgress color="progressPrimary" />
        </Grid>
      )}
      <div className={classes.pageContainer}>
        {isCadillac &&
          !requestIdError &&
          !noSessionId &&
          !EVgo &&
          !EVGoError &&
          !declineError &&
          !declined &&
          !accepted &&
          !acceptError &&
          !alreadyDeclined &&
          !alreadyEVGo &&
          !canceledRequest &&
          !alreadyConfirmed &&
          !loading && (
            <>
              <div className={classes.contentWrap}>
                <Header />
                <Grid container className={classes.titleContainer}>
                  <div
                    className={` ${classes.titleText} display-2`}
                    style={{ marginBottom: "2%" }}
                  >
                    Cadillac LYRIQ Customer Charging Offer
                  </div>
                </Grid>
                <Grid container className={classes.bodyRootContainer}>
                  <Grid container className={classes.bodyContainer}>
                    <div className={`${classes.terms} body1`}>
                      Terms and Conditions
                    </div>
                    <TermsBox
                      name="termsAndConditions"
                      confirmGMPrivacy={confirmGMPrivacy}
                      handleConfirmGMPrivacy={handleConfirmGMPrivacy}
                      confirmQmerit={confirmQmerit}
                      handleConfirmQmerit={handleConfirmQmerit}
                    >
                      <Terms />
                    </TermsBox>
                  </Grid>
                </Grid>
                <Grid container className={classes.buttonContainerRoot}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container className={classes.buttonContainer}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          marginTop: 3,
                          minWidth: 400,
                          borderRadius: 0,
                          padding: "6px 16px",
                        }}
                        disabled={!(confirmGMPrivacy && confirmQmerit)}
                        onClick={handleAccept}
                      >
                        <div className={`body2--white`}>ACCEPT</div>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container className={classes.buttonContainer}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          marginTop: 13,
                          minWidth: 400,
                          borderRadius: 0,
                          padding: "6px 16px",
                        }}
                        onClick={handleDeclineDialogOpen}
                      >
                        <div className={`body2--white`}>DECLINE ALL OFFERS</div>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container className={classes.buttonContainer}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          marginTop: 13,
                          minWidth: 400,
                          borderRadius: 0,
                          padding: "6px 16px",
                        }}
                        onClick={handleEVgoDialogOpen}
                      >
                        <div className={`body2--white`}>
                          SWITCH TO EVGO CREDIT OFFER
                        </div>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Dialog open={EVgoOpen} onClose={handleEVgoDialogClose}>
                  <DialogTitle>
                    <div
                      className={"headline-3--primary"}
                      style={{ textAlign: "center" }}
                    >
                      Switch to EVgo Credit Offer?
                    </div>
                  </DialogTitle>
                  <DialogContent>
                    <div
                      className={"body2--primary"}
                      style={{ textAlign: "center" }}
                    >
                      Are you sure you want to switch from the Home Installation
                      Credit offer to the EVgo credit offer?
                    </div>
                  </DialogContent>
                  <DialogActions style={{ alignSelf: "center", margin: "3%" }}>
                    <Button
                      onClick={handleEVgoDialogClose}
                      variant="contained"
                      color="primary"
                    >
                      <div className={`body2--white`}>
                        No, go back to Home Installation
                      </div>
                    </Button>
                    <Button
                      onClick={handleSwitchToEVGo}
                      variant="contained"
                      color="primary"
                    >
                      <div className={`body2--white`}>
                        Yes, continue to EVgo
                      </div>
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog open={declineOpen} onClose={handleDeclineDialogClose}>
                  <DialogTitle>
                    <h1
                      className={"headline-3--primary"}
                      style={{ textAlign: "center" }}
                    >
                      Decline Cadillac LYRIQ Customer Charging Offer
                    </h1>
                  </DialogTitle>
                  <DialogContent>
                    <div
                      className={"body2--primary"}
                      style={{ textAlign: "center" }}
                    >
                      Are you sure you want to decline this request?
                    </div>
                    <div
                      className={"body2--primary"}
                      style={{ textAlign: "center" }}
                    >
                      You will not receive up to $1500 toward home charging
                      installation or EVgo public charging credits.
                    </div>
                  </DialogContent>
                  <DialogActions style={{ alignSelf: "center", margin: "3%" }}>
                    <Button
                      onClick={handleDeclineDialogClose}
                      variant="contained"
                      color="primary"
                    >
                      <div className={`body2--white`}> No, go back </div>
                    </Button>
                    <Button
                      onClick={handleDecline}
                      variant="contained"
                      color="primary"
                    >
                      <div className={`body2--white`}> Yes, decline </div>
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <div className={classes.footer}>
                <Footer />
              </div>
            </>
          )}
        {!loading &&
          (!isCadillac ||
            requestIdError ||
            noSessionId ||
            EVgo ||
            EVGoError ||
            declined ||
            declineError ||
            accepted ||
            acceptError ||
            canceledRequest ||
            alreadyDeclined ||
            alreadyEVGo ||
            alreadyConfirmed) && (
            <>
              <div className={classes.contentWrap}>
                <Header />
                <Grid container className={classes.titleContainer}>
                  <div className={`${classes.titleText} display-2`}>
                    {content}
                  </div>
                </Grid>
              </div>
              <div className={classes.footer}>
                <Footer />
              </div>
            </>
          )}
      </div>
    </ThemeProvider>
  );
};

export default CustomerConsent;
