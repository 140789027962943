import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    /*'& .MuiTextField-root': {
      marginTop: '15px',
      paddingRight: '15px',
      width: '100%',
    },*/
  },
  button: {
    marginTop: "15px",
    position: "relative",
  },
  buttonDisabled: {
    cursor: "not-allowed",
    pointerEvents: "none",
    // need specificity override for AEM styling
    "&.button[disabled]": {
      color: "#373637",
      backgroundColor: "#fafafa",
      opacity: "1",
    },
  },
  select: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginTop: "15px",
    paddingRight: "15px",
    width: "100%",
  },
  nextStepContainer: {
    /*		position: 'absolute',
		bottom: 40,
		right: 40,*/
    paddingTop: "1.5rem",
  },
  nextStepButtonBack: {
    // specificity applied as override
    "&.stat-icon-link": {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      backgroundColor: "white",
      padding: "0",
      border: "1px solid black",
      color: "black",
    },
  },
  nextStepButtonBackDisabled: {
    opacity: "0.3",
  },
  nextStepButtonBackIcon: {
    transform: "scaleX(-1) rotate(-40deg)",
    marginLeft: "2px",
  },
  nextStepButtonNext: {
    height: "30px",
    "&.button": {
      // additional styles from .button that need to be added to override AEM styles
      width: "150px",
      border: "2px solid transparent",
      "&:hover": {
        color: "#fff",
      },
    },
  },
  hiddenButton: {
    visibility: "hidden",
  },
  exampleImage: {
    height: "auto",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "15px",
    },
  },
  optionsImage: {
    height: "150px",
    width: "150px",
    marginRight: "15px",
    borderRadius: "6px",
  },
  modalRoot: {
    top: "10%",
    width: "90%",
    height: "calc(100vh - 15%)",
    overflowY: "scroll",
    left: "calc(50% - 90%/2)",
    [theme.breakpoints.up("md")]: {
      width: "1000px",
      left: "calc(50% - 500px)",
    },
  },
  modalImage: {
    marginBottom: "10px",
    maxHeight: "100%",
    maxWidth: "100%",
    display: "block",
    height: "80%",
  },
  thumbnailImage: {
    marginLeft: "auto",
    marginRight: "auto",
    maxHeight: "150px",
    maxWidth: "150px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "200px",
    },
  },
  thumbnailContainer: {
    position: "relative",
    // bring the x close icon to the top right corner of the image container
    width: "fit-content",
  },
  closingIcon: {
    cursor: "pointer",
    position: "absolute",
    top: 0,
    right: 0,
  },
  thumbnailClosingIcon: {
    top: "-5px",
    right: "-15px",
  },
  imageUploadContainer: {
    display: "box",
    paddingBottom: "40px",
  },
  imageUploadQuestion: {
    [theme.breakpoints.down("sm")]: {
      boxOrdinalGroup: 2,
    },
  },
  imageUploadSample: {
    [theme.breakpoints.down("sm")]: {
      boxOrdinalGroup: 1,
    },
  },
  inlineIcon: {
    verticalAlign: "bottom",
  },
  additionalInfoBox: {
    height: "170px",
  },
  termsBox: {
    backgroundColor: "#f7f7f7",
    height: "250px",
    overflowY: "auto",
    overflowX: "clip",
    padding: "10px",
    width: "100%",
  },
  tooltipFitContent: {
    width: "fit-content",
  },
  inputField: {
    // need input specificity to override styles on AEM :(
    "&.MuiInputBase-input": {
      padding: "18.5px 14px",
      height: "1.1876em",
      margin: "0",
      boxSizing: "content-box",
      border: "0",
      background: "none",
      font: "inherit",
      boxShadow: "none",
    },
  },

  icon: {
    "&.MuiSelect-icon": {
      right: "-4px",
      top: "16px",
    },
  },
}));

export { useStyles };
