import React from 'react';

/**
 * Takes a Date Object and converts it into a String, {weekDay}, {month} {day} {year}
 * EX: Monday, August 16 2021
 * @param {*} props
 * @returns String
 */
const DateRender = (props) => {
  const month = props.date.toLocaleString('en-US', { month: 'long' });
  const day = props.date.toLocaleString('en-US', { day: '2-digit' });
  const year = props.date.getFullYear();

  return (
    <>
      {month} {day}, {year}
    </>
  );
};

export default DateRender;
