import React, { useState, useEffect } from 'react';
import './Layout.css';
import {
  Box,
  Grid,
  Button,
  AppBar,
  Toolbar,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useMsal } from "@azure/msal-react";
import { handleLogout } from "../services/authHandler";
import { getRequestIds } from "../services/api";
import { patchRequest } from "../services/api";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useHistory } from "react-router-dom";

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    minHeight: '100vh',
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  toolbar: theme.mixins.toolbar,
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  logo: {
    maxWidth: 160,
    maxHeight: 12,
  },
  button: {
    color: theme.palette.text.white,
  },
});

export let profileId = '';

const Layout = (props) => {
  const { classes } = props;
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const history = useHistory();

  const { instance } = useMsal();

  const loadProfile = async (sessionId, profileId) => {
    setOpenBackdrop(true);
    sessionStorage.setItem(
      'gm.profileId',
      JSON.stringify({ profileId: profileId })
    );
    if (sessionId) {
      const message = await patchRequest(sessionId, profileId);
    }
    const res = await getRequestIds(profileId, true);
    setOpenBackdrop(false);
    if (sessionId) {
      history.replace({
        search: "",
      });
      history.go(0);
    }
  }; 
    
  useEffect(() => {
    const handleResponse = (response) => {
      if (response !== null) {
        profileId = response.account.idTokenClaims.sub;
        loadProfile(response.state, response.account.idTokenClaims.sub);
      }
    };

    // Register Callbacks for Redirect flow
    instance
      .handleRedirectPromise()
      .then(handleResponse)
      .catch((error) => {});
  }, [instance]);

  return (
    <div>
      <div id='main-background' />
      <div id='main-layout'>
        <div className={classes.root}>
          <AuthenticatedTemplate>
            <AppBar position='fixed' color='secondary'>
              <Toolbar>
                <Grid
                  justifyContent='space-between'
                  container
                  alignItems='center'
                >
                  <img
                    src='images/EVLogo.png'
                    alt='logo'
                    className={classes.logo}
                  />

                  <div>
                    <Button
                      className={classes.button}
                      onClick={() => handleLogout(instance)}
                      // style={{ paddingRight: '15px' }}
                    >
                      Sign Out
                    </Button>
                  </div>
                </Grid>
              </Toolbar>
            </AppBar>
          </AuthenticatedTemplate>
          <main className={classes.content}>
            <AuthenticatedTemplate>
              <div className={classes.toolbar} />
            </AuthenticatedTemplate>
            <Box width='100%' className='mainContent'>
              {props.children}
            </Box>
          </main>
        </div>
      </div>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
};
export default withStyles(styles)(Layout);
