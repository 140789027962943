import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { createTheme } from '@material-ui/core/styles';

const breakpoints = createBreakpoints({});

export const CustomerTheme = createTheme({
  palette: {
    primary: {
      light: '#1b73ce',
      main: '#0058b3',
      dark: '#000',
    },
    secondary: {
      main: '#373637',
    },
    error: {
      light: '#F99698',
      main: '#E30526',
      dark: '#C8000F',
    },
    warning: {
      light: '#F9EBB6',
      main: '#F27D07',
      dark: '#F0B323',
    },
    info: {
      light: '#F99698',
      main: '#E30526',
      dark: '#C8000F',
    },
    success: {
      light: '#D3EDC8',
      main: '#4A9E38',
      dark: '#07671C',
    },
    disabled: {
      main: '#AFAFAF',
    },
    text: {
      primary: '#373637',
      secondary: '#5f5e5f',
      disabled: '#afafaf',
      white: '#FFF',
    },
  },
  typography: {
    h1: {
      [breakpoints.down('sm')]: {
        fontFamily: 'LouisGlobal2Heavy, Tahoma, sans-serif',
        fontStyle: 'heavy',
        fontSize: '32px',
        fontWeight: '700',
        letterSpacing: '-0.5px',
        lineHeight: '44px',
        textTransform: 'none',
      },
      [breakpoints.up('md')]: {
        fontFamily: 'LouisGlobal2Heavy, Tahoma, sans-serif',
        fontStyle: 'heavy',
        fontSize: '40px',
        fontWeight: '700',
        letterSpacing: '-0.5px',
        lineHeight: '48px',
        textTransform: 'none',
      },
      [breakpoints.up('lg')]: {
        fontFamily: 'LouisGlobal2Heavy, Tahoma, sans-serif',
        fontStyle: 'heavy',
        fontSize: '48px',
        fontWeight: '700',
        letterSpacing: '-0.5px',
        lineHeight: '56px',
        textTransform: 'none',
      },
    },
    h2: {
      [breakpoints.down('sm')]: {
        fontFamily: 'LouisGlobal2Heavy, Tahoma, sans-serif',
        fontSize: '24px',
        fontWeight: '900',
        letterSpacing: '-0.5px',
        lineHeight: '24px',
        textTransform: 'Uppercase',
      },
      [breakpoints.up('md')]: {
        fontFamily: 'LouisGlobal2Heavy, Tahoma, sans-serif',
        fontSize: '38px',
        fontWeight: '900',
        letterSpacing: '-0.5px',
        lineHeight: '38px',
        textTransform: 'Uppercase',
      },
      [breakpoints.up('lg')]: {
        fontFamily: 'LouisGlobal2Heavy, Tahoma, sans-serif',
        fontSize: '52px',
        fontWeight: '900',
        letterSpacing: '-0.5px',
        lineHeight: '56px',
        textTransform: 'Uppercase',
      },
    },
    h3: {
      [breakpoints.down('sm')]: {
        fontFamily: 'LouisGlobal2Reg, Tahoma, sans-serif',
        fontSize: '24px',
        fontWeight: '400',
        letterSpacing: '-0.5px',
        lineHeight: '32px',
        textTransform: 'none',
      },
      [breakpoints.up('md')]: {
        fontFamily: 'LouisGlobal2Reg, Tahoma, sans-serif',
        fontSize: '28px',
        fontWeight: '400',
        letterSpacing: '0px',
        lineHeight: '36px',
        textTransform: 'none',
      },
      [breakpoints.up('lg')]: {
        fontFamily: 'LouisGlobal2Reg, Tahoma, sans-serif',
        fontSize: '42px',
        fontWeight: '400',
        letterSpacing: '-0.5px',
        lineHeight: '50px',
        textTransform: 'none',
      },
    },
    h4: {
      [breakpoints.down('sm')]: {
        fontFamily: 'LouisGlobal2Bold, Tahoma, sans-serif',
        fontSize: '18px',
        fontWeight: '700',
        letterSpacing: '0px',
        lineHeight: '24px',
        textTransform: 'none',
      },
      [breakpoints.up('md')]: {
        fontFamily: 'LouisGlobal2Bold, Tahoma, sans-serif',
        fontSize: '20px',
        fontWeight: '700',
        letterSpacing: '0px',
        lineHeight: '28px',
        textTransform: 'none',
      },
      [breakpoints.up('lg')]: {
        fontFamily: 'LouisGlobal2Bold, Tahoma, sans-serif',
        fontSize: '24px',
        fontWeight: '700',
        letterSpacing: '0px',
        lineHeight: '32px',
        textTransform: 'none',
      },
    },
    h5: {
      [breakpoints.down('sm')]: {
        fontFamily: 'RobotoBold, Helvetica, sans-serif',
        fontSize: '16px',
        fontWeight: '700',
        letterSpacing: '0px',
        lineHeight: '22px',
        textTransform: 'none',
      },
      [breakpoints.up('md')]: {
        fontFamily: 'RobotoBold, Helvetica, sans-serif',
        fontSize: '16px',
        fontWeight: '700',
        letterSpacing: '0px',
        lineHeight: '22px',
        textTransform: 'none',
      },
      [breakpoints.up('lg')]: {
        fontFamily: 'RobotoBold, Helvetica, sans-serif',
        fontSize: '18px',
        fontWeight: '700',
        letterSpacing: '0px',
        lineHeight: '24px',
        textTransform: 'none',
      },
    },
    h6: {
      [breakpoints.down('sm')]: {
        fontFamily: 'RobotoBold, Helvetica, sans-serif',
        fontSize: '14px',
        fontWeight: '700',
        letterSpacing: '0px',
        lineHeight: '20px',
        textTransform: 'none',
      },
      [breakpoints.up('md')]: {
        fontFamily: 'RobotoBold, Helvetica, sans-serif',
        fontSize: '14px',
        fontWeight: '400',
        letterSpacing: '-0.5px',
        lineHeight: '20px',
        textTransform: 'none',
      },
      [breakpoints.up('lg')]: {
        fontFamily: 'RobotoBold, Helvetica, sans-serif',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '-.5px',
        lineHeight: '22px',
        textTransform: 'none',
      },
    },
    subtitle1: {
      [breakpoints.down('sm')]: {
        fontFamily: 'RobotoReg, Helvetica, sans-serif',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '-0.3px',
        lineHeight: '22px',
        textTransform: 'none',
      },
      [breakpoints.up('md')]: {
        fontFamily: 'RobotoReg, Helvetica, sans-serif',
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '-0.3px',
        lineHeight: '22px',
        textTransform: 'none',
      },
      [breakpoints.up('lg')]: {
        fontFamily: 'RobotoReg, Helvetica, sans-serif',
        fontSize: '18px',
        fontWeight: '400',
        letterSpacing: '-0.3px',
        lineHeight: '24px',
        textTransform: 'none',
      },
    },
    body1: {
      fontFamily: 'RobotoBold, Helvetica, sans-serif',
      fontSize: '16px',
      fontWeight: '700',
      letterSpacing: '0px',
      lineHeight: '16px',
      textTransform: 'Uppercase',
    },
    body2: {
      fontFamily: 'RobotoReg, Helvetica, sans-serif',
      fontSize: '14px',
      fontWeight: '400',
      letterSpacing: '0px',
      lineHeight: '20px',
      textTransform: 'none',
    },
    caption1: {
      fontFamily: 'RobotoReg, Helvetica, sans-serif',
      fontSize: '10px',
      fontWeight: '400',
      letterSpacing: '0.3px',
      lineHeight: '13px',
      textTransform: 'none',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        border: '2px solid transparent',
        borderRadius: '50px',
        color: '#0058b3',
        fontFamily: 'Roboto, Helvetica, sans-serif',
        fontSize: '11px',
        fontWeight: '700',
        letterSpacing: '0',
        lineHeight: '16px',
        padding: '0.65rem 1rem',
        transitionDuration: '150ms',
        transitionProperty: 'background-color, border, color, opacity',
        transitionTimingFunction: 'ease-in',
      },
      outlined: {
        border: '1px solid black',
        borderRadius: '50px',
        color: '#000000',
        fontFamily: 'Roboto, Helvetica, sans-serif',
        fontSize: '11px',
        fontWeight: '700',
        letterSpacing: '0',
        lineHeight: '16px',
        padding: '0.65rem 1rem',
        transitionDuration: '150ms',
        transitionProperty: 'background-color, border, color, opacity',
        transitionTimingFunction: 'ease-in',
      },
      containedPrimary: {
        backgroundColor: '#0058b3',
        borderColor: '#0058b3',
        color: '#fff',
        '&:not(:disabled)': {
          '&:hover': {
            // changes colors for hover state
            backgroundColor: '#1b73ce',
            borderColor: '#1b73ce',
          },
        },
        '&.transparent': {
          backgroundColor: 'transparent',
          color: '#0058b3',
          '&:not(:disabled)': {
            '&:hover': {
              backgroundColor: '#0058b3',
              borderColor: '#0058b3',
              color: '#fff',
            },
          },
          '&.no-hover:hover': {
            backgroundColor: 'transparent',
            borderColor: '#0058b3',
            color: '#0058b3',
          },
        },
      },
      containedSecondary: {
        backgroundColor: '#373637',
        borderColor: '#373637',
        color: '#fff',
        '&:not(:disabled)': {
          '&:hover': {
            // changes colors for hover state
            backgroundColor: '#fff',
            borderColor: '#fff',
            color: '#373637',
          },
        },
        '&.transparent': {
          backgroundColor: 'transparent',
          color: '#373637',
          '&:not(:disabled)': {
            '&:hover': {
              backgroundColor: '#373637',
              borderColor: '#373637',
              color: '#e1e1e1',
            },
          },
        },
      },
    },
    MuiToggleButton: {
      root: {
        border: '1px solid black',
        borderRadius: '50px',
        color: '#000000',
        fontFamily: 'Roboto, Helvetica, sans-serif',
        fontSize: '14px',
        fontWeight: '700',
        letterSpacing: '0',
        lineHeight: '16px',
        padding: '0.65rem 1rem',
        transitionDuration: '150ms',
        transitionProperty: 'background-color, border, color, opacity',
        transitionTimingFunction: 'ease-in',
        '&.Mui-selected': {
          backgroundColor: '#0058b3',
          borderColor: '#0058b3',
          color: '#fff',
          '&:not(:disabled)': {
            '&:hover': {
              // changes colors for hover state
              backgroundColor: '#1b73ce',
              borderColor: '#1b73ce',
            },
          },
        },
        '&:not(.Mui-selected)': {
          backgroundColor: '#fff',
          borderColor: '#000000',
          color: '#000000',
          '&:not(:disabled)': {
            '&:hover': {
              // changes colors for hover state
              backgroundColor: '#1b73ce',
              borderColor: '#1b73ce',
            },
          },
        },
      },
    },
  },
});

export const CadillacTheme = createTheme({
  palette: {
    primary: {
      light: '#1b73ce',
      main: '#0058b3',
      dark: '#000',
    },
    secondary: {
      main: '#373637',
    },
    error: {
      light: '#F99698',
      main: '#E30526',
      dark: '#C8000F',
    },
    warning: {
      light: '#F9EBB6',
      main: '#F27D07',
      dark: '#F0B323',
    },
    info: {
      light: '#F99698',
      main: '#E30526',
      dark: '#C8000F',
    },
    success: {
      light: '#D3EDC8',
      main: '#4A9E38',
      dark: '#07671C',
    },
    disabled: {
      main: '#AFAFAF',
    },
    text: {
      primary: '#373637',
      secondary: '#5f5e5f',
      disabled: '#afafaf',
      white: '#FFF',
    },
    progress: {
      primary: '#282828',
    },
  },
  typography: {
    h1: {
      [breakpoints.down('sm')]: {
        /* Display 1/Small/display1-heavy */
        fontFamily: 'CadillacGothicWideBold',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '29px',
        textAlign: 'center',
        letterSpacing: '3.54px',
        textTransform: 'uppercase',
      },
      [breakpoints.up('md')]: {
        fontFamily: 'CadillacGothicWideBold',
        fontStyle: 'normal',
        fontSize: '28px',
        fontWeight: '700',
        letterSpacing: '6.51px',
        lineHeight: '40px',
        textTransform: 'uppercase',
        textAlign: 'center',
      },
      [breakpoints.up('lg')]: {
        /* Display 1/Large/display1-heavy */
        fontFamily: 'CadillacGothicWideBold',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '36px',
        lineHeight: '51px',
        textAlign: 'center',
        letterSpacing: '9.47px',
        textTransform: 'uppercase',
      },
    },
    h2: {
      [breakpoints.down('sm')]: {
        fontFamily: 'CadillacGothicWideBold',
        fontSize: '18px',
        fontWeight: '700',
        letterSpacing: '3.14px',
        lineHeight: '26px',
        textTransform: 'uppercase',
        textAlign: 'center',
      },
      [breakpoints.up('md')]: {
        fontFamily: 'CadillacGothicWideBold',
        fontSize: '32px',
        fontWeight: '700',
        letterSpacing: '5.6px',
        lineHeight: '46px',
        textTransform: 'uppercase',
        textAlign: 'center',
      },
      [breakpoints.up('lg')]: {
        fontFamily: 'CadillacGothicWideBold',
        fontSize: '56px',
        fontWeight: '700',
        letterSpacing: '8.06px',
        lineHeight: '66px',
        textTransform: 'uppercase',
        textAlign: 'center',
      },
    },
    h3: {
      [breakpoints.down('sm')]: {
        fontFamily: 'CadillacGothicBold',
        fontSize: '16px',
        fontWeight: '700',
        letterSpacing: '2.8px',
        lineHeight: '23px',
        textTransform: 'uppercase',
        textAlign: 'center',
      },
      [breakpoints.up('md')]: {
        fontFamily: 'CadillacGothicBold',
        fontSize: '22px',
        fontWeight: '700',
        letterSpacing: '3.84px',
        lineHeight: '32px',
        textTransform: 'uppercase',
        textAlign: 'center',
      },
      [breakpoints.up('lg')]: {
        fontFamily: 'CadillacGothicBold',
        fontSize: '28px',
        fontWeight: '700',
        letterSpacing: '4.88px',
        lineHeight: '41px',
        textTransform: 'uppercase',
        textAlign: 'center',
      },
    },
    h4: {
      [breakpoints.down('sm')]: {
        fontFamily: 'CadillacGothicBold',
        fontSize: '14px',
        fontWeight: '700',
        letterSpacing: '2.48px',
        lineHeight: '20px',
        textTransform: 'uppercase',
        textAlign: 'center',
      },
      [breakpoints.up('md')]: {
        fontFamily: 'CadillacGothicBold',
        fontSize: '16px',
        fontWeight: '700',
        letterSpacing: '2.8px',
        lineHeight: '23px',
        textTransform: 'uppercase',
        textAlign: 'center',
      },
      [breakpoints.up('lg')]: {
        fontFamily: 'CadillacGothicBold',
        fontSize: '18px',
        fontWeight: '700',
        letterSpacing: '3.12px',
        lineHeight: '26px',
        textTransform: 'uppercase',
        textAlign: 'center',
      },
    },
    h5: {
      [breakpoints.down('sm')]: {
        fontFamily: 'CadillacGothicBold',
        fontSize: '26px',
        fontWeight: '700',
        letterSpacing: '4.5px',
        lineHeight: '37px',
        textTransform: 'none',
      },
      [breakpoints.up('md')]: {
        fontFamily: 'CadillacGothicBold',
        fontSize: '44px',
        fontWeight: '700',
        letterSpacing: '7.7px',
        lineHeight: '64px',
        textTransform: 'none',
      },
      [breakpoints.up('lg')]: {
        fontFamily: 'CadillacGothicBold',
        fontSize: '64px',
        fontWeight: '700',
        letterSpacing: '11.2px',
        lineHeight: '92px',
        textTransform: 'none',
      },
    },
    //   h6: {
    //     [breakpoints.down('sm')]: {
    //       fontFamily: 'RobotoBold, Helvetica, sans-serif',
    //       fontSize: '14px',
    //       fontWeight: '700',
    //       letterSpacing: '0px',
    //       lineHeight: '20px',
    //       textTransform: 'none',
    //     },
    //     [breakpoints.up('md')]: {
    //       fontFamily: 'RobotoBold, Helvetica, sans-serif',
    //       fontSize: '14px',
    //       fontWeight: '400',
    //       letterSpacing: '-0.5px',
    //       lineHeight: '20px',
    //       textTransform: 'none',
    //     },
    //     [breakpoints.up('lg')]: {
    //       fontFamily: 'RobotoBold, Helvetica, sans-serif',
    //       fontSize: '16px',
    //       fontWeight: '400',
    //       letterSpacing: '-.5px',
    //       lineHeight: '22px',
    //       textTransform: 'none',
    //     },
    //   },
    //   subtitle1: {
    //     [breakpoints.down('sm')]: {
    //       fontFamily: 'RobotoReg, Helvetica, sans-serif',
    //       fontSize: '16px',
    //       fontWeight: '400',
    //       letterSpacing: '-0.3px',
    //       lineHeight: '22px',
    //       textTransform: 'none',
    //     },
    //     [breakpoints.up('md')]: {
    //       fontFamily: 'RobotoReg, Helvetica, sans-serif',
    //       fontSize: '16px',
    //       fontWeight: '400',
    //       letterSpacing: '-0.3px',
    //       lineHeight: '22px',
    //       textTransform: 'none',
    //     },
    //     [breakpoints.up('lg')]: {
    //       fontFamily: 'RobotoReg, Helvetica, sans-serif',
    //       fontSize: '18px',
    //       fontWeight: '400',
    //       letterSpacing: '-0.3px',
    //       lineHeight: '24px',
    //       textTransform: 'none',
    //     },
    //   },
    body1: {
      [breakpoints.down('sm')]: {
        /* Body1/Small/body1 */
        fontFamily: 'CadillacGothicRegular',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '29px',
        letterSpacing: '0.65px',
      },
      [breakpoints.up('md')]: {
        fontFamily: 'CadillacGothicRegular',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '17px',
        lineHeight: '30.5px',
        letterSpacing: '0.7px',
      },
      [breakpoints.up('lg')]: {
        /* Body1/Large/body1 */
        fontFamily: 'CadillacGothicRegular',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '32px',
        letterSpacing: '0.75px',
      },
    },
    body2: {
      [breakpoints.down('sm')]: {
        fontFamily: 'CadillacGothicRegular',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '25px',
        letterSpacing: '0.6px',
      },
      [breakpoints.up('md')]: {
        fontFamily: 'CadillacGothicRegular',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '26.5px',
        letterSpacing: '0.65px',
      },
      [breakpoints.up('lg')]: {
        fontFamily: 'CadillacGothicRegular',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '15px',
        lineHeight: '28px',
        letterSpacing: '0.7px',
      },
    },
    caption: {
      fontFamily: 'CadillacGothicBold',
      fontStyle: 'normal',
      fontSize: '11px',
      fontWeight: '700',
      letterSpacing: '0.54px',
      lineHeight: '20px',
      textTransform: 'uppercase',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        border: '2px solid transparent',
        borderRadius: '50px',
        color: '#282828',
        fontFamily: 'CadillacGothicRegular',
        fontSize: '14px',
        fontWeight: '200',
        letterSpacing: '1.6px',
        lineHeight: '22px',
        padding: '13px 26px',
        transitionDuration: '150ms',
        transitionProperty: 'background-color, border, color, opacity',
        transitionTimingFunction: 'ease-in',
      },
      // outlined: {
      //   border: '1px solid black',
      //   borderRadius: '50px',
      //   color: '#000000',
      //   fontFamily: 'Roboto, Helvetica, sans-serif',
      //   fontSize: '11px',
      //   fontWeight: '700',
      //   letterSpacing: '0',
      //   lineHeight: '16px',
      //   padding: '0.65rem 1rem',
      //   transitionDuration: '150ms',
      //   transitionProperty: 'background-color, border, color, opacity',
      //   transitionTimingFunction: 'ease-in',
      // },
      containedPrimary: {
        backgroundColor: '#282828',
        borderColor: '#282828',
        color: '#fcfcfc',
        '&:not(:disabled)': {
          '&:hover': {
            // changes colors for hover state
            backgroundColor: '#fcfcfc',
            borderColor: '#282828',
            color: '#282828',
          },
        },
        '&.transparent': {
          backgroundColor: 'transparent',
          color: '#282828',
          '&:not(:disabled)': {
            '&:hover': {
              backgroundColor: '#282828',
              borderColor: '#282828',
              color: '#fcfcfc',
            },
          },
          '&.no-hover:hover': {
            backgroundColor: 'transparent',
            borderColor: '#282828',
            color: '#282828',
          },
        },
        '&:disabled': {
          backgroundColor: '#a0a0a0',
          borderColor: '#a0a0a0',
          color: '#fcfcfc',
        },
      },
      //   containedSecondary: {
      //     backgroundColor: '#373637',
      //     borderColor: '#373637',
      //     color: '#fff',
      //     '&:not(:disabled)': {
      //       '&:hover': {
      //         // changes colors for hover state
      //         backgroundColor: '#fff',
      //         borderColor: '#fff',
      //         color: '#373637',
      //       },
      //     },
      //     '&.transparent': {
      //       backgroundColor: 'transparent',
      //       color: '#373637',
      //       '&:not(:disabled)': {
      //         '&:hover': {
      //           backgroundColor: '#373637',
      //           borderColor: '#373637',
      //           color: '#e1e1e1',
      //         },
      //       },
      //     },
      //   },
      // },
      // MuiToggleButton: {
      //   root: {
      //     border: '1px solid black',
      //     borderRadius: '50px',
      //     color: '#000000',
      //     fontFamily: 'Roboto, Helvetica, sans-serif',
      //     fontSize: '14px',
      //     fontWeight: '700',
      //     letterSpacing: '0',
      //     lineHeight: '16px',
      //     padding: '0.65rem 1rem',
      //     transitionDuration: '150ms',
      //     transitionProperty: 'background-color, border, color, opacity',
      //     transitionTimingFunction: 'ease-in',
      //     '&.Mui-selected': {
      //       backgroundColor: '#0058b3',
      //       borderColor: '#0058b3',
      //       color: '#fff',
      //       '&:not(:disabled)': {
      //         '&:hover': {
      //           // changes colors for hover state
      //           backgroundColor: '#1b73ce',
      //           borderColor: '#1b73ce',
      //         },
      //       },
      //     },
      //     '&:not(.Mui-selected)': {
      //       backgroundColor: '#fff',
      //       borderColor: '#000000',
      //       color: '#000000',
      //       '&:not(:disabled)': {
      //         '&:hover': {
      //           // changes colors for hover state
      //           backgroundColor: '#1b73ce',
      //           borderColor: '#1b73ce',
      //         },
      //       },
      //     },
      //   },
    },
  },
});
