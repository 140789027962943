import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { HomeRoute } from '../Layout/Router';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    marginTop: '100px',
  },
  root2: {
    maxWidth: '60%',
    paddingTop: '25px',
  },
}));

export default function CancelScreen() {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.root2}>
        <Typography variant='h3' color='textPrimary'>
          Canceled
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.root2}>
        <Typography variant='h4' color='textPrimary'>
          Your appointment has been canceled.
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.root2}>
        <Typography variant='subtitle1' color='textPrimary'>
          Please reschedule at your earliest convenience. Just a reminder, you
          only have 180 days to complete your home installation.
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.root2}>
        <Typography variant='subtitle1' color='textPrimary'>
          If you would like to cancel your overall request in exchange for EVgo
          credits, please contact your preferred dealer.
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.root2}>
        <Button
          variant='contained'
          color='primary'
          component={Link}
          to={HomeRoute}
        >
          Return Home
        </Button>
      </Grid>
    </Grid>
  );
}
