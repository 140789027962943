import { useStyles } from '../../Questions/styles';
import { Grid } from '@material-ui/core';
import React from 'react';
import Redo from '@material-ui/icons/Redo';

const NavButton = (props) => {
	const classes = useStyles();

	const createText = () => {
		var text = props.buttonText;
		if (props.currentStep && props.totalSteps) {
			text =
				props.currentStep === props.totalSteps - 1
					? 'Submit'
					: `${props.currentStep}/${props.totalSteps - 1} - ${text}`;
		}
		return text;
	};

	return (
		<div className={classes.nextStepContainer}>
			<Grid container spacing={1} alignItems="center">
				{props.currentStep !== 1 && (
					<Grid item>
						<button
							className={`${classes.nextStepButtonBack} stat-icon-link button ${
								props.backButtonEnabled ? '' : classes.buttonDisabled
							} 
						${props.backButtonEnabled ? '' : classes.nextStepButtonBackDisabled}`}
							data-dtm="request installation"
							disabled={!props.backButtonEnabled}
							onClick={props.handleBack}
						>
							<Redo fontSize={'small'} className={classes.nextStepButtonBackIcon} />
						</button>
					</Grid>
				)}
				<Grid item>
					<button
						className={`${classes.nextStepButtonNext} paragraph-2-alt--primary button stat-button-link ${
							props.nextButtonEnabled ? 'button-primary' : classes.buttonDisabled
						}`}
						data-dtm="request installation"
						disabled={!props.nextButtonEnabled}
						onClick={props.handleNext}
					>
						{createText()}
					</button>
				</Grid>
			</Grid>
		</div>
	);
};

NavButton.defaultProps = {
	buttonText: 'Next',
};

export default NavButton;
