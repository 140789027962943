import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { cancelAppointment, updateRequest } from '../services/api';
import DateRender from '../Schedule/DateRender';
import { Link } from 'react-router-dom';
import { CancelScreenRoute } from '../Layout/Router';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    justifyContent: 'center',
    textAlign: 'center',
  },
  buttons: {
    flexGrow: 1,
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: '10px',
    paddingBottom: '20px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    padding: '10px',
  },
  request: {
    paddingBottom: '10px',
  },
  dialog: {
    textAlign: 'center',
  },
}));

const CancelAppointment = ({
  open,
  close,
  scheduleId,
  requestId,
  date,
  time,
}) => {
  const classes = useStyles();
  const weekDay = date.toLocaleString('en-US', { weekday: 'long' });

  const handleSubmit = (e) => {
    try {
      sendPatch();
    } catch (error) {}
  };

  const sendPatch = async () => {
    try {
      let response = await cancelAppointment(scheduleId);
      response = await updateRequest(requestId);
    } catch (error) {}
  };

  return (
    <div className={classes.root}>
      <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={close}>
        <DialogTitle>
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={close}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div className={classes.form}>
          <DialogContent>
            <Typography variant='h4' className={classes.title}>
              Are you sure you want to cancel your appointment?
            </Typography>
            <Typography variant='h5' className={classes.request}>
              Request ID {requestId}
            </Typography>
            <Typography variant='subtitle1'>{weekDay}</Typography>
            <Typography variant='subtitle1'>
              <DateRender date={date} />
            </Typography>
            <Typography variant='subtitle1'>{time}</Typography>
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <div style={{ padding: '10px' }}>
              <Button variant='outlined' onClick={close}>
                NO, KEEP
              </Button>
            </div>
            <div style={{ padding: '10px' }}>
              <Button
                variant='contained'
                color='primary'
                component={Link}
                to={CancelScreenRoute}
                onClick={() => {
                  handleSubmit();
                }}
              >
                YES, CANCEL
              </Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default CancelAppointment;
