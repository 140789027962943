import React, { Fragment } from 'react';
import styles from './Modal.module.css';
import Backdrop from '../Backdrop/Backdrop';

const Modal = (props) => {
	return (
		<Fragment>
			<Backdrop show={props.show} clicked={props.hideModal} />
			<div
				className={`${styles.Modal} ${props.classes}`}
				style={{
					transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
					opacity: props.show ? '1' : '0',
				}}
			>
				{props.children}
			</div>
		</Fragment>
	);
};

//React.memo replacement for shouldCompontentUpdate to prevent unecessary rendering
React.memo(Modal, (props, nextProps) => {
	return nextProps.show === props.show && nextProps.children === props.children;
});

export default Modal;
