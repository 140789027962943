import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Box,
  Button,
  Divider,
  Paper,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  getAppointmentSlots,
  getBookedSlots,
  getRequest,
  getSnapshot,
} from '../services/api';
import DateRender from './DateRender';
import moment from 'moment';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ReturnHome from '../shared/ReturnHome';
import ErrorAlert from '../shared/ErrorAlert';
import { useHistory } from 'react-router';
import { getRequestStatus } from '../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  root2: {
    justifyContent: 'center',
    maxWidth: '80%',
    paddingTop: '25px',
  },
  root3: {
    justifyContent: 'center',
  },
  title: {
    paddingTop: '20px',
    textAlign: 'center',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  biggrid: {
    width: '80%',
  },
  times: {
    height: '305px',
  },
  button: {
    color: theme.palette.primary.main,
    margin: theme.spacing(1),
    textTransform: 'none',
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  info: {
    marginTop: theme.spacing(1),
  },
  next: {
    textAlign: 'right',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Calendar(props) {
  const { profileId, requestId, isLastStep, handleNext } = props;

  const classes = useStyles();
  const [morning, setMorning] = React.useState(false);
  const [afternoon, setAfternoon] = React.useState(false);
  const [date, changeDate] = useState(new Date());
  const [time, setTime] = useState('');
  const [availableDates, setAvailableDates] = useState([]);
  const [morningTimeSlots, setMorningTimeSlots] = useState([]);
  const [afternoonTimeSlots, setAfternoonTimeSlots] = useState([]);
  const month = date.toLocaleString('en-US', { month: '2-digit' });
  const day = date.toLocaleString('en-US', { day: '2-digit' });
  const year = date.getFullYear();
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [booked, setBooked] = useState(false);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [zipCode, setZIP] = useState('');
  const [results, setResults] = useState({});
  const [electricianId, setElectricianId] = useState('');
  const [fail, setFail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cnt, setCnt] = useState(0);

  const today = new Date();
  const initialDate = new Date();
  initialDate.setUTCHours(today.getUTCHours() + 72);

  let history = useHistory();

  useEffect(() => {
    const checkBooked = async () => {
      if (requestId) {
        try {
          let response = await getRequest(requestId);
          setResults(response);
          if (
            profileId !== response.profileId ||
            getRequestStatus(response.statusId).scheduleButton === 'disabled'
          ) {
            history.push('/');
          }
          setElectricianId(response.assignedGmid);
          response = await getBookedSlots(requestId);
          if (response.data.data.items.length) {
            setBooked(true);
          } else {
            setBooked(false);
          }
        } catch {}
      }
    };
    checkBooked();
  }, [requestId]);

  //Get information associated with snapshotId from requestId results
  useEffect(() => {
    const searchSnapshotId = async (snapshotId) => {
      if (snapshotId) {
        const response = await getSnapshot(snapshotId);
        setName(`${response.first_name} ${response.last_name}`);
        setAddress(
          `${response.street_address}${
            response.street_address2 ? ` ${response.street_address2}` : ''
          }, ${response.city_name}, ${response.state_or_province}, ${
            response.postal_code
          }`
        );
        setZIP(response.postal_code);
        setPhone(`${response.phone_number}`);
        setEmail(`${response.email_address}`);
      } else {
      }
    };
    try {
      searchSnapshotId(results.snapshotId);
    } catch {}
  }, [results]);

  useEffect(() => {
    const retriveAPIdata = async () => {
      setCnt(cnt + 1);
      setLoading(true);
      try {
        setMorning(false);
        setAfternoon(false);
        const response = await getAppointmentSlots(zipCode, electricianId);

        if (response.data.status === 500)
          // eslint-disable-next-line no-throw-literal
          throw { message: response.data.response.statusText };

        const availableSlots = response.data.data.items.map(
          (event) =>
            (event = {
              start: new Date(event.startTime),
              end: new Date(event.endTime),
            })
        );

        const filterMorning = availableSlots.filter(
          (filter) =>
            filter.start.toDateString() === date.toDateString() &&
            filter.start.getHours() < 11 && filter.start.getTime()  > initialDate.getTime()
        );

        const filterAfternoon = availableSlots.filter(
          (filter) =>
            filter.start.toDateString() === date.toDateString() &&
            filter.start.getHours() >= 11 && filter.start.getTime()  > initialDate.getTime()
        );

        const morningDuplicates = new Set();

        const arrayMorningSlots = filterMorning.filter((el) => {
          const duplicate = morningDuplicates.has(el.id);
          morningDuplicates.add(el.id);
          return !duplicate;
        });

        setMorningTimeSlots(arrayMorningSlots);

        const afternoonDuplicates = new Set();

        const arrayAfternoonSlots = filterAfternoon.filter((el) => {
          const duplicate = afternoonDuplicates.has(el.id);
          afternoonDuplicates.add(el.id);
          return !duplicate;
        });

        setAfternoonTimeSlots(arrayAfternoonSlots);

        const startDates = response.data.data.items.map((x) => {
          return x.startTime;
        });

        setAvailableDates(
          startDates
            .filter((x) => {
              if (new Date(x).getTime() < initialDate.getTime()) {
                return false;
              } else {
                return true;
              }
            })
            .map((x) => {
              return new Date(x).toDateString();
            })
        );
      } catch (error) {
        setFail(true);
      }
    };
    retriveAPIdata();
  }, [date, zipCode]);

  useEffect(() => {
    if (cnt > 1) {
      if (availableDates.length === 0) {
        setLoading(false);
      }
    }
  }, [availableDates]);

  const filterDays = (date) => {
    if (availableDates.includes(date.toDateString())) {
      setLoading(false);
      return false;
    } else {
      setLoading(false);
      return true;
    }
  };

  return (
    <>
      <ErrorAlert open={fail} close={() => setFail(false)} />
      <ReturnHome />
      <Divider />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.root2}>
          <Typography variant='subtitle1' color='textPrimary'>
            Step 1 of 2
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.root2}>
          <Typography variant='h3' color='textPrimary'>
            Schedule your Appointment
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.root2}>
          <Typography variant='h4' color='textPrimary'>
            Request ID {requestId}
          </Typography>
        </Grid>
        {availableDates.length > 0 ? (
          <>
            <Grid item xs={12} className={classes.root2}>
              <Typography variant='subtitle1' color='textPrimary'>
                Please select a date and then a time for your appointment.
              </Typography>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{ width: '80%', justifyContent: 'space-between' }}
            >
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <br />
                <br />
                <br />
                <Paper elevation={3}>
                  <Grid container className={classes.root3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        orientation='landscape'
                        ToolbarComponent={() => null}
                        variant='static'
                        openTo='date'
                        disablePast
                        minDate={initialDate}
                        shouldDisableDate={filterDays}
                        value={date}
                        onChange={changeDate}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                <br />
                <br />
                <br />
                <Paper elevation={3} className={classes.times}>
                  <div style={{ marginLeft: '40px', marginRight: '40px' }}>
                    <Typography
                      variant='h4'
                      color='textPrimary'
                      className={classes.title}
                    >
                      <DateRender date={date} /> available times
                    </Typography>
                    <Divider className={classes.divider} />
                    <div className={classes.root}>
                      <div>
                        <Grid container direction={'row'}>
                          <Grid item xs={12}>
                            <Grid container justifyContent='center'>
                              <Grid
                                direction={'column'}
                                item
                                xs={12}
                                sm={5}
                                md={5}
                                lg={5}
                                xl={5}
                              >
                                <Typography
                                  variant='subtitle1'
                                  color='textPrimary'
                                  align='center'
                                >
                                  Morning
                                </Typography>
                                {morningTimeSlots.map((x) => (
                                  <Box className={classes.box}>
                                    <ToggleButton
                                      selected={morning}
                                      className={classes.button}
                                      onClick={() => {
                                        setMorning(true);
                                        setAfternoon(false);
                                        setTime(
                                          moment(x.start).format('h:mm A') +
                                            ' - ' +
                                            moment(x.end).format('h:mm A')
                                        );
                                        setStartTime('12:00:00Z');
                                        setEndTime('16:00:00Z');
                                      }}
                                    >
                                      <Typography variant='body2'>
                                        {moment(x.start).format('h:mm A') +
                                          ' - ' +
                                          moment(x.end).format('h:mm A')}
                                      </Typography>
                                    </ToggleButton>
                                  </Box>
                                ))}
                              </Grid>
                              <Grid
                                direction={'column'}
                                item
                                xs={12}
                                sm={5}
                                md={5}
                                lg={5}
                                xl={5}
                              >
                                <Typography
                                  variant='subtitle1'
                                  color='textPrimary'
                                  align='center'
                                >
                                  Afternoon
                                </Typography>
                                {afternoonTimeSlots.map((x) => (
                                  <Box className={classes.box}>
                                    <ToggleButton
                                      selected={afternoon}
                                      className={classes.button}
                                      onClick={() => {
                                        setMorning(false);
                                        setAfternoon(true);
                                        setTime(
                                          moment(x.start).format('h:mm A') +
                                            ' - ' +
                                            moment(x.end).format('h:mm A')
                                        );
                                        setStartTime('17:00:00Z');
                                        setEndTime('21:00:00Z');
                                      }}
                                    >
                                      <Typography variant='body2'>
                                        {moment(x.start).format('h:mm A') +
                                          ' - ' +
                                          moment(x.end).format('h:mm A')}
                                      </Typography>
                                    </ToggleButton>
                                  </Box>
                                ))}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} className={classes.next}>
                <Button
                  className={classes.next}
                  disabled={!morning && !afternoon}
                  variant='contained'
                  color='primary'
                  onClick={() =>
                    handleNext(
                      requestId,
                      date,
                      time,
                      month,
                      day,
                      year,
                      startTime,
                      endTime,
                      name,
                      address,
                      phone,
                      email,
                      zipCode,
                      booked,
                      electricianId
                    )
                  }
                >
                  {isLastStep ? 'Finish' : 'Next'}
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} className={classes.root2}>
            {loading ? (
              <></>
            ) : (
              <Typography variant='subtitle1' color='textPrimary'>
                There are currently no more appointments left. Sorry for the
                inconvenience.
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}
