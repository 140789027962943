import React, { useState, useEffect } from 'react';
import { Grid, Box, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getPromoCode } from '../../services/api';

const useStyles = makeStyles({
	container: {
		maxWidth: 600,
		margin: 'auto',
		padding: '15px',
	},
	table: {
		minWidth: 300,
	},
	tableContainer: {
		maxWidth: 600,
	},
	marginTop: {
		marginTop: '40px',
	},
});

const CustomerInfoDisplay = ({ formData, statusId, requestType, apis, backendBaseUrl }) => {
	const [evgoCreditCode, setEvgoCreditCode] = useState('');
	const classes = useStyles();

	const getEVGoCreditCode = async (requestId) => {
		const res = await getPromoCode(apis, requestId, backendBaseUrl);
		if (res.data.length && res.data[0].creditCode) {
			setEvgoCreditCode(res.data[0].creditCode);
		} else {
			setEvgoCreditCode('Your EVGo credit code will be provided upon delivery of vehicle');
		}
	};

	useEffect(() => {
		if (requestType === 'EVGo' && statusId === 9 && formData.requestId) {
			getEVGoCreditCode(formData.requestId);
		} else {
			setEvgoCreditCode('Your EVGo credit code will be provided upon delivery of vehicle');
		}
	}, []);

	const contactTable = [
		{ fieldName: 'First Name', fieldValue: formData.firstName },
		{ fieldName: 'Last Name', fieldValue: formData.lastName },
		{ fieldName: 'Email Address', fieldValue: formData.emailAddress },
		{ fieldName: 'Phone Number', fieldValue: formData.phone },
	];

	const addressTable = [
		{ fieldName: 'Address Line 1', fieldValue: formData.address1 },
		{ fieldName: 'Address Line 2', fieldValue: formData.address2 },
		{ fieldName: 'City', fieldValue: formData.city },
		{ fieldName: 'State', fieldValue: formData.state },
		{ fieldName: 'Zip Code', fieldValue: formData.zip },
	];

	const requestTable = [
		{ fieldName: 'Request ID', fieldValue: formData.requestId },
		{ fieldName: 'VIN', fieldValue: formData.vin },
	];

	if (requestType === 'EVGo') {
		requestTable.push({ fieldName: 'EVGo Credit Number', fieldValue: evgoCreditCode });
	}

	const createTable = (rows) => {
		return (
			<TableContainer component={Paper} className={classes.tableContainer}>
				<Table className={classes.table} aria-label="simple table">
					<TableBody>
						{rows.map((row) => (
							<TableRow key={row.fieldName}>
								<TableCell component="th" scope="row" align="center">
									{row.fieldName}
								</TableCell>
								<TableCell align="center">{row.fieldValue}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	let message = '';
	switch (true) {
		case requestType === 'EVGo':
			message = 'You have selected to receive EVGo Credit.';
			break;
		case statusId > 3 && statusId < 9:
			message = 'Your EV Install Form has been submitted!';
			break;
		case statusId > 9 && statusId < 14:
			message = 'Your application has been canceled.';
			break;
		default:
			message = '';
	}

	return (
		<div className={classes.container}>
			<p>{message}</p>
			{requestType === 'EVGo' && (
				<p>
					To see where you can use your EVGo credits,{' '}
					<a href="https://www.evgo.com/find-a-charger/" target="_blank" rel="noreferrer">
						click here
					</a>
					.
				</p>
			)}
			<p className={classes.marginTop}>
				<b>Request Information</b>
			</p>
			{/* {createTable(contactTable)}
			{requestType !== 'EVGo' && (
				<p className={classes.marginTop}>
					<b>Address for Home Installation</b>
				</p>
			)}
			{requestType !== 'EVGo' && createTable(addressTable)}
			<p className={classes.marginTop}>
				<b>Install Request Information</b>
			</p> */}
			{createTable(requestTable)}
		</div>
	);
};

export default CustomerInfoDisplay;
