import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import RadioQuestion from '../UI/QuestionTypes/RadioQuestion';
import SelectQuestion from '../UI/QuestionTypes/SelectQuestion';
import TextQuestion from '../UI/QuestionTypes/TextQuestion';
import Message from '../UI/QuestionTypes/Message';

const Question3 = (props) => {
	const question = 'Type of property?';
	const options = [
		{ value: '3', text: 'House' },
		{ value: '4', text: 'Duplex' },
		{ value: '40', text: 'Condo' },
		{ value: '6', text: 'Townhouse' },
		{ value: '5', text: 'Apartment' },
	];

	return (
		<SelectQuestion
			dataDtm="property detail"
			name="question3"
			value={props.question3}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Question4 = (props) => {
	const question = 'Do you have a dedicated meter?';
	const options = [
		{ value: '18', text: 'Yes' },
		{ value: '19', text: 'No' },
	];

	const tooltipContent = (
		<>
			<p>A dedicated meter measures only the energy used by your home.</p>
			<p>
				A non-dedicated meter (like one in a duplex or condominium) measures the energy usage for you and your
				neighbor on the same device.
			</p>
		</>
	);

	return (
		<RadioQuestion
			dataDtm="property detail"
			name="question4"
			value={props.question4}
			onChange={props.handleChange}
			question={question}
			options={options}
			tooltipContent={tooltipContent}
		/>
	);
};

const Question28 = (props) => {
	const question = 'Approximately, what year was your home built?';
	const options = [
		{ value: '177', text: '2000 to present' },
		{ value: '178', text: '1980 to 1999' },
		{ value: '179', text: '1960 to 1979' },
		{ value: '180', text: '1940 to 1959' },
		{ value: '181', text: 'Prior to 1940' },
	];

	return (
		<SelectQuestion
			dataDtm="property detail"
			name="question28"
			value={props.question28}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Question29 = (props) => {
	const question = "What is your home's approximate square footage?";

	return (
		<TextQuestion
			name="question29"
			dataDtm="property detail"
			value={props.question29}
			onChange={props.handleChange}
			question={question}
			required={true}
			large={true}
			number={true}
			maxLength={255}
		/>
	);
};

const Group2 = (props) => {
	const classes = useStyles();
	const { submitRef, enableNavButton } = props;
	const [question3, setQuestion3] = useState(props.formData.question3);
	const [question4, setQuestion4] = useState(props.formData.question4);
	const [question28, setQuestion28] = useState(props.formData.question28);
	const [question29, setQuestion29] = useState(props.formData.question29);

	useEffect(() => {
		const enable =
			(question3 === '3' && question28 && question29) ||
			((question3 === '4' || question3 === '6' || question3 === '40') && question4 === '18');
		enableNavButton(enable);
	}, [question3, question4, question28, question29]);

	useImperativeHandle(submitRef, () => ({
		validateGroup2Form: () => {
			// add validation if needed
			return [
				true,
				{
					question3: question3,
					question4: question4,
					question28: question28,
					question29: question29,
				},
			];
		},
	}));

	const handleQuestion3Change = (newValue) => {
		setQuestion3(newValue);
	};

	const handleQuestion4Change = (newValue) => {
		setQuestion4(newValue);
	};
	const handleQuestion28Change = (newValue) => {
		setQuestion28(newValue);
	};
	const handleQuestion29Change = (newValue) => {
		setQuestion29(newValue);
	};

	let messageText =
		"Based on your selection, you do not qualify for GM’s installation promotion. Reach out to your authorized dealer about Chevrolet's public charging offer!";

	if (props.formData.dealer.name && props.formData.dealer.phone) {
		messageText = `Based on your selection, you do not qualify for GM’s installation promotion. Reach out to your authorized dealer, 
			${props.formData.dealer.name}, at ${props.formData.dealer.phone} about Chevrolet's public charging offer!`;
	}

	const messageText2 = 'Based on your selection, you do not qualify for home installation at this time.';

	return (
		<>
			<Box>
				<form className={classes.root} autoComplete="off">
					<Question3 question3={question3} handleChange={handleQuestion3Change} />
					{question3 && (question3 === '4' || question3 === '6' || question3 === '40') && (
						<Box mt={2}>
							<div className="paragraph-1--secondary">
								<Question4 question4={question4} handleChange={handleQuestion4Change} />
								{question4 && question4 === '19' && props.getIsDealerAuthorized() && (
									<Message text={messageText} />
								)}
								{question4 && question4 === '19' && !props.getIsDealerAuthorized() && (
									<Message text={messageText2} />
								)}
							</div>
						</Box>
					)}
					<div className="paragraph-1--secondary">
						{question3 && question3 === '5' && props.getIsDealerAuthorized() && (
							<Message text={messageText} />
						)}
						{question3 && question3 === '5' && !props.getIsDealerAuthorized() && (
							<Message text={messageText2} />
						)}
					</div>
					{question3 && question3 === '3' && (
						<Box mt={2}>
							<div className="paragraph-1--secondary">
								<Question28 question28={question28} handleChange={handleQuestion28Change} />
							</div>
						</Box>
					)}
					{question3 && question3 === '3' && question28 && (
						<Box mt={2}>
							<div className="block-quote-lg--primary">
								<Question29 question29={question29} handleChange={handleQuestion29Change} />
							</div>
						</Box>
					)}
				</form>
			</Box>
		</>
	);
};

export default Group2;
