import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import SelectQuestion from '../UI/QuestionTypes/SelectQuestion';
import RadioQuestion from '../UI/QuestionTypes/RadioQuestion';

const Question21 = (props) => {
	const question = 'Where is your proposed location for the EV charging?';
	const options = [
		{ value: '65', text: 'Inside or outside of my garage (same wall as my electrical panel)' },
		{ value: '66', text: 'Inside or outside of my garage (different wall as my electrical panel)' },
		{ value: '206', text: 'Mounted on a standalone pedestal or fence' },
	];

	return (
		<RadioQuestion
			dataDtm="electrical panel location"
			name="question21"
			value={props.question21}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Question8 = (props) => {
	const question = 'Will charging be installed on the same wall as the main electrical panel or a different wall?';
	const options = [
		{ value: '23', text: 'Same' },
		{ value: '24', text: 'Different' },
	];

	return (
		<SelectQuestion
			dataDtm="electrical panel location"
			name="question8"
			value={props.question8}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Question9 = (props) => {
	const question = 'What size garage do you have?';
	const options = [
		{ value: '20', text: '1 car' },
		{ value: '21', text: '2 car' },
		{ value: '22', text: '3 car' },
		{ value: '64', text: '4+ car' },
	];

	return (
		<SelectQuestion
			name="question9"
			dataDtm="electrical panel location"
			value={props.question9}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Group4 = (props) => {
	const classes = useStyles();
	const { submitRef, enableNavButton } = props;
	const [question21, setQuestion21] = useState(props.formData.question21);
	const [question8, setQuestion8] = useState(props.formData.question8);
	const [question9, setQuestion9] = useState(props.formData.question9);

	useEffect(() => {
		const enable = !!question21 && !!question8 && !!question9;
		enableNavButton(enable);
	}, [question21, question8, question9]);

	useImperativeHandle(submitRef, () => ({
		validateGroup4Form: () => {
			// add validation if needed
			return [true, { question21: question21, question8: question8, question9: question9 }];
		},
	}));

	const handleQuestion21Change = (newValue) => {
		setQuestion21(newValue);
	};

	const handleQuestion8Change = (newValue) => {
		setQuestion8(newValue);
	};

	const handleQuestion9Change = (newValue) => {
		setQuestion9(newValue);
	};

	return (
		<>
			<Box>
				<form className={classes.root} autoComplete="off">
					<Question21 question21={question21} handleChange={handleQuestion21Change} />
					{question21 && (
						<Box mt={2}>
							<Question8 question8={question8} handleChange={handleQuestion8Change} />
						</Box>
					)}
					{question8 && (
						<Box mt={2}>
							<Question9 question9={question9} handleChange={handleQuestion9Change} />
						</Box>
					)}
				</form>
			</Box>
		</>
	);
};

export default Group4;
