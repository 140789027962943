import React from "react";
import Routing from "./components/Layout/Router";
import "./App.css";
import "./styles/_brand.scss";
import { MsalProvider } from "@azure/msal-react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";
import styles from "./styles/mui-theme-exports.module.scss";

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function App({ pca }) {
  return (
    <ThemeProvider theme={theme(styles)}>
      <MsalProvider instance={pca}>
        <Routing />
      </MsalProvider>
    </ThemeProvider>
  );
}
