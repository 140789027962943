import React from 'react'
import {
    makeStyles,
} from '@material-ui/core';
import styles from './Footer.css';

const useStyles = makeStyles((theme) => ({
    container: { justifyContent: 'left', padding: '24px', },
    linkContainer: { display: 'flex', flexDirection: 'row', gap: 5, paddingTop: 10, paddingBottom: 10 },
    adChoiceImage: { paddingRight: 10, width: '17.5px' }
}));

const Footer = () => {
    const classes = useStyles();

    return (
        <footer className={classes.container}>
            <div>
                <a href="https://www.gm.com/consumer-privacy.html" target='_blank'><strong>California Residents: Do Not Sell My Personal Information</strong></a>
            </div>
            <div className={classes.linkContainer}>
                <a href="https://www.cadillac.com/legal" target='_blank'>Legal</a>
                <span>|</span>
                <a href="https://www.gm.com/privacy-statement" target='_blank'>GM Privacy Statement</a>
            </div>
            <a href="https://www.cadillac.com/ad-choices" target='_blank'><img src='images/adchoices.png' alt='Ad Choices' className={classes.adChoiceImage} />Ad Choices</a>
        </footer>
    )
}

export default Footer