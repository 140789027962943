import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useStyles } from "../../Questions/styles";

const SelectQuestion = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(props.value);

  const handleChange = (e) => {
    setValue(e.target.value);

    props.onChange(e.target.value);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={10}>
          <Typography component="div">
            <Box fontSize="h7.fontSize" fontWeight="800">
              <div className="block-quote-lg--primary">{props.question}</div>
              <br />
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <FormControl variant="outlined" disabled={props.disabledDropdown}>
            <Select
              value={props.value === "10" ? "" : value}
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
              onChange={handleChange}
              data-dtm={props.dataDtm}
              displayEmpty
              className={`${classes.select} stat-dropdown`}
              required
            >
              <MenuItem value="" disabled>
                {props.selectVehicle ? "Select Vehicle" : <em>--</em>}
              </MenuItem>
              {props.options.map((key, i) => (
                <MenuItem key={i} value={props.options[i].value}>
                  {props.options[i].text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectQuestion;
