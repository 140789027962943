import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import RadioQuestion from '../UI/QuestionTypes/RadioQuestion';
import SelectQuestion from '../UI/QuestionTypes/SelectQuestion';
import Message from '../UI/QuestionTypes/Message';

const Question1 = (props) => {
	const question = 'Tell us a little bit about your situation. Do you rent or own your property?';
	const options = [
		{ value: '39', text: 'Rent' },
		{ value: '2', text: 'Own' },
	];

	return (
		<SelectQuestion
			dataDtm="rent or own"
			name="question1"
			value={props.question1}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Question2a = (props) => {
	const question =
		'Have you received permission from your Homeowner Association (HOA), landlord, or any other necessary third party, to install charging? We will not verify third party approvals; you are solely responsible for obtaining approvals from all necessary third parties prior to installation. Since you are renting, you will likely need approval to install charging. If you haven’t done so already; please reach out to your point of contact to get approval.';
	const options = [
		{ value: '49', text: 'I already have approval or I do not need approval' },
		{ value: '50', text: "I don't have approval yet" },
	];

	return (
		<RadioQuestion
			dataDtm="rent or own"
			name="question2a"
			value={props.question2a}
			onChange={props.handleChange}
			question={question}
			options={options}
			followup={true}
		/>
	);
};

const Question2b = (props) => {
	const question = 'Do you need to get permission from your Homeowner Association (HOA) or any other third parties?';
	const options = [
		{ value: '49', text: 'I already have approval or I do not need approval' },
		{ value: '50', text: "I don't have approval yet" },
	];

	return (
		<RadioQuestion
			dataDtm="rent or own"
			name="question2b"
			value={props.question2b}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Group1 = (props) => {
	const classes = useStyles();
	const { submitRef, enableNavButton } = props;
	const [question1, setQuestion1] = useState(props.formData.question1);
	const [question2a, setQuestion2a] = useState(props.formData.question2a);
	const [question2b, setQuestion2b] = useState(props.formData.question2b);

	useEffect(() => {
		const enable = (question1 === '39' && question2a === '49') || (question1 === '2' && question2b === '49');
		enableNavButton(enable);
	}, [question1, question2a, question2b]);

	useImperativeHandle(submitRef, () => ({
		validateGroup1Form: () => {
			// add validation if needed
			return [true, { question1: question1, question2a: question2a, question2b: question2b }];
		},
	}));

	const handleQuestion1Change = (newValue) => {
		setQuestion1(newValue);
	};

	const handleQuestion2aChange = (newValue) => {
		setQuestion2a(newValue);
	};

	const handleQuestion2bChange = (newValue) => {
		setQuestion2b(newValue);
	};

	const messageText = 'Please return to this site once you have approval.';

	let messageText2 =
		"No longer interested in home charging installation? Reach out to your authorized dealer about Chevrolet's public charging offer";

	if (props.formData.dealer.name && props.formData.dealer.phone) {
		messageText2 = `No longer interested in home charging installation? Reach out to your authorized dealer, ${props.formData.dealer.name}, 
			at ${props.formData.dealer.phone} about Chevrolet's public charging offer`;
	}

	return (
		<>
			<Box>
				<form className={classes.root} autoComplete="off">
					<Question1 question1={question1} handleChange={handleQuestion1Change} />
					{question1 && question1 === '2' && (
						<Box mt={2}>
							<Question2b question2b={question2b} handleChange={handleQuestion2bChange} />
							{question2b && question2b === '50' && (
								<Box mt={2}>
									<div className="paragraph-1--secondary">
										<Message text={messageText} />
										{props.getIsDealerAuthorized() && <Message text={messageText2} />}
									</div>
								</Box>
							)}
						</Box>
					)}
					{question1 && question1 === '39' && (
						<Box mt={2}>
							<Question2a question2a={question2a} handleChange={handleQuestion2aChange} />
							{question2a && question2a === '50' && (
								<Box mt={2}>
									<div className="paragraph-1--secondary">
										<Message text={messageText} />
										{props.getIsDealerAuthorized() && <Message text={messageText2} />}
									</div>
								</Box>
							)}
						</Box>
					)}
				</form>
			</Box>
		</>
	);
};

export default Group1;
