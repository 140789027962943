import React, { useState } from 'react';
import {
  Button,
  Grid,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  getTimeSlots,
  makeAppointment,
  updateRequest,
  cancelAppointment,
} from '../services/api';
import { Typography } from '@material-ui/core';
import AppointmentDetails from '../Appointments/AppointmentDetails';
import CustomerInfo from '../CustomerInfo/CustomerInfocmpnt';
import ReturnHome from '../shared/ReturnHome';
import ErrorAlert from '../shared/ErrorAlert';

const useStyles = makeStyles((theme) => ({
  confirm: {
    color: theme.palette.primary.main,
  },
  cancel: {
    color: theme.palette.error.main,
  },
  loading: {
    margin: '10px',
    padding: '60px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  root2: {
    justifyContent: 'center',
    maxWidth: '80%',
    paddingTop: '25px',
  },
  root3: {
    justifyContent: 'center',
    maxWidth: '80%',
    paddingTop: '10px',
    paddingBottom: '50px',
  },
  button: {
    marginLeft: '10px',
  },
}));

const ReviewAppointment = ({
  activeStep,
  isLastStep,
  handleBack,
  handleConfirm,
  requestId,
  date,
  time,
  startTime,
  endTime,
  name,
  address,
  phone,
  email,
  zipCode,
  booked,
  scheduleId,
  electricianId,
}) => {
  const classes = useStyles();
  const [fail, setFail] = useState(false);

  const handleSubmit = (e) => {
    try {
      retrieveSlots();
    } catch (error) {
      setFail(true);
    }
  };

  //Dynamically find available appointment slots based on the time the user clicks on
  const retrieveSlots = async () => {
    try {
    if (booked) {
      const result = await cancelAppointment(scheduleId);
    }
    const response = await getTimeSlots(
      startTime,
      endTime,
      zipCode,
      electricianId
    );

    sendPatch(response.data.data.items);
    } catch (error) {
      setFail(true);
    }
  };

  //Schedule the appointment
  const sendPatch = async (openSlots) => {
    try {
      const id = openSlots[0].scheduleId;
      let response = await makeAppointment(id, requestId);
      response = await updateRequest(requestId);
      handleConfirm();
    } catch (error) {
      if (booked) {
        const response = await makeAppointment(scheduleId, requestId);
      }
      setFail(true);
    }
  };

  return (
    <>
      <ErrorAlert open={fail} close={() => setFail(false)} />
      <ReturnHome />
      <Divider />
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.root2}>
          <Typography variant='subtitle1' color='textPrimary'>
            Step 2 of 2
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.root2}>
          <Typography variant='h3' color='textPrimary'>
            Review Your Appointment
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.root2}>
          <Typography variant='h4' color='textPrimary'>
            Request ID {requestId}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.root3}>
          <Typography variant='subtitle1' color='textPrimary'>
            Please review your appointment before selecting the <br />
            confirm button.
          </Typography>
        </Grid>
        <Grid style={{ width: '80%' }}>
          <div>
            <CustomerInfo
              name={name}
              address={address}
              phone={phone}
              email={email}
            />
            <br />
            <br />
            <AppointmentDetails time={time} date={date} requestID={requestId} />
            <Grid container style={{ textAlign: 'right', marginTop: '25px' }}>
              <Grid item xs={12}>
                <Button
                  variant='outlined'
                  onClick={() => handleBack()}
                  className={classes.button}
                >
                  Back
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => handleSubmit()}
                  className={classes.button}
                >
                  {booked ? 'Reschedule' : 'Confirm'}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewAppointment;
