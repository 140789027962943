import React from 'react';
import { Grid } from '@material-ui/core';

const Message = (props) => {
	return (
		<>
			<Grid container>
				<Grid item xs={12} sm={10}>
					<p>{props.text}</p>
				</Grid>
			</Grid>
		</>
	);
};

export default Message;
