import React, { useState, useEffect, useImperativeHandle } from "react";
import TermsBox from "../UI/QuestionTypes/TermsBox";
import styles from "../../home-charger-form.module.scss";
import ReactHtmlParser from "html-react-parser";

const PiAcknowledgement = () => {
  return (
    <div>
      <p>
        By clicking Submit the information you provide or upload into this EV
        Install Form, including your name, address, phone number, email address,
        and photos will be shared with Qmerit. Qmerit will use this information
        to facilitate the match to a qualified electrician in your area for
        purposes of installing charging at your home. All information shared
        with Qmerit and Qmerit’s services will be subject to the{" "}
        <a
          href="https://www.qmerit.com/privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          Qmerit Privacy Statement
        </a>
        .
      </p>
    </div>
  );
};

const Terms = (props) => {
  const {
    termsAccepted,
    piAckAccepted,
    submitRef,
    enableNavButton,
    getIsDealerAuthorized,
    getIsCustomerPay,
  } = props;

  const [termsChecked, setTermsChecked] = useState(termsAccepted || false);
  const [piAckChecked, setPiAckChecked] = useState(piAckAccepted || false);

  useEffect(() => {
    if (termsChecked && piAckChecked) {
      enableNavButton(true);
    } else {
      enableNavButton(false);
    }
  }, [termsChecked, piAckChecked]);

  useImperativeHandle(submitRef, () => ({
    validateTermsForm: () => {
      // add validation if needed
      return [true, termsChecked, piAckChecked];
    },
  }));

  const handleTermsCheckedChange = (newValue) => {
    setTermsChecked(newValue);
  };

  const handlePiAckCheckedChange = (newValue) => {
    !getIsDealerAuthorized() && setTermsChecked(newValue);
    setPiAckChecked(newValue);
  };

  return (
    <>
      {getIsDealerAuthorized() && (
        <>
          <h1 className={`block-quote-lg--primary ${styles.no_margin_top}`}>
            Terms and Conditions
          </h1>
          <TermsBox
            name="termsAndConditions"
            value={termsChecked}
            onChange={handleTermsCheckedChange}
          >
            {ReactHtmlParser(props.content)}
          </TermsBox>
        </>
      )}
      <h1 className="block-quote-lg--primary">
        Personal Information Acknowledgement
      </h1>
      <TermsBox
        name="piAcknowledgement"
        value={piAckChecked}
        onChange={handlePiAckCheckedChange}
      >
        <PiAcknowledgement />
      </TermsBox>

      {!getIsDealerAuthorized() && !getIsCustomerPay() && (
        <p>
          Before you can continue with the questionnaire, we need you to verify
          your email address. Clicking the link in the email will allow you to
          complete this form.
        </p>
      )}
    </>
  );
};

export default Terms;
