import axios from 'axios';
import resolve from './resolve.js';
import {
	generateSubmitRequestBody,
	generateGuestAnswersBody,
	generateSnapshotUpdateBody,
	generateFinalSubmitRequestBody,
	getAnswerKeyAndResponseType,
	getQuestionIdFromFormKey,
	generateAddressValidationBody,
	getImageQuestionsList,
} from './questionService';
// import getConfig from 'next/config';

export const submitGuestRequest = async (formData, apis, backendBaseUrl) => {
	return await resolve(
		axios
			.post(`${backendBaseUrl}${apis.createInstallRequest}`, generateSubmitRequestBody(formData), {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((res) => res.data)
	);
};

export const getSession = async (sessionId, apis, backendBaseUrl) => {
	let session = {};
	// const { publicRuntimeConfig } = getConfig();
	// const publicRuntimeConfig = { basePath: '/electric' };
	return await resolve(
		axios
			.get(`${backendBaseUrl}${apis.getInstallRequest}`, {
				headers: {
					'Content-Type': 'application/json',
				},
				params: {
					sessionId: sessionId,
				},
			})
			// .then((res) => {
			// 	session = res.data[0];
			// 	return axios.get(apis.getSnapshot.replace('{snapshotId}', session.snapshotId), {
			// 		headers: {
			// 			'Content-Type': 'application/json',
			// 		},
			// 	});
			// })
			.then((res) => {
				//session.snapshot = res.data;
				session = res.data[0];
				return axios.get(`${backendBaseUrl}${apis.getResponses}`, {
					headers: {
						'Content-Type': 'application/json',
					},
					params: {
						requestId: session.requestId.toString(),
					},
				});
			})
			.then((res) => {
				session.responses = {};

				// Parse question response array
				let answers = [];
				for (let i = 0; i < res.data.length; i++) {
					answers = getAnswerKeyAndResponseType(res.data[i]);
					for (let j in answers) {
						// for multiResponse question types, use the additionalResponse value as the response answer
						session.responses[answers[j].key] = res.data[i][answers[j].responseType]
							? res.data[i][answers[j].responseType].toString()
							: answers[j].responseType === 'multiResponse'
							? res.data[i]['additionalResponse'].toString()
							: '';
					}
				}

				// let body = {
				// 	vendorId: session.bac,
				// };
				return axios
					.post(`${backendBaseUrl}${apis.dealerLookup.replace('{bac}', session.bac)}`, {
						headers: {
							// Transaction: 'vendorIdSearch',
							'Content-Type': 'application/json',
						},
					})
					.then((res) => {
						session.dealerData = res.data;
					})
					.catch((error) => console.log('Error on Dealer Locator: ', error));
			})
			.then(() => {
				let imageQuestionList = [];
				const questionKeyList = getImageQuestionsList();
				for (const question in questionKeyList) {
					if (session.responses[questionKeyList[question]]) {
						imageQuestionList.push(
							`${backendBaseUrl}${apis.s3Download.replace(
								'{objectKey}',
								session.requestId.toString() + '_' + getQuestionIdFromFormKey(questionKeyList[question])
							)}`
							//session.requestId.toString() + '_' + getQuestionIdFromFormKey(questionKeyList[question])
						);
					}
				}
				return imageQuestionList;

				//return s3BulkDownload(apis, imageQuestionList);
			})
			.then((res) => {
				session.images = res;
				return session;
			})
	);
};

export const postAnswers = async (formData, apis, requestId, backendBaseUrl) => {
	return await resolve(
		axios
			.post(`${backendBaseUrl}${apis.saveResponses.replace('{requestId}', requestId.toString())}`, generateGuestAnswersBody(formData), {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((res) => res.data)
			.catch((err) => console.log('Error saving answers: ', err))
	);
};

export const uploadImage = async (apis, file, filename, backendBaseUrl) => {
	const form = new FormData();
	form.append('file', file);
	form.append('filename', filename);
	form.append('mimetype', 'application/octet-stream');

	return await resolve(
		axios.post(`${backendBaseUrl}${apis.s3Upload}`, form, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	);
};

export const deleteImage = async (apis, filename, backendBaseUrl) => {
	return await resolve(
		axios.post(`${backendBaseUrl}${apis.s3Delete.replace('{objectKey}', filename)}`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
	);
};

export const updateSnapshot = async (apis, snapshotId, formData, backendBaseUrl) => {
	return await resolve(
		axios
			.put(`${backendBaseUrl}${apis.updateSnapshot.replace('{snapshotId}', snapshotId)}`, generateSnapshotUpdateBody(formData), {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((res) => res.data)
			.catch((err) => console.log('Error updating customer snapshot: ', err))
	);
};

export const s3BulkDownload = async (apis, objectKeys, backendBaseUrl) => {
	if (objectKeys.length === 0) return [];
	const promises = [];

	for (let i = 0; i < objectKeys.length; i++) {
		promises.push(axios.get(`${backendBaseUrl}${apis.s3Download.replace('{objectKey}', objectKeys[i])}`));
	}

	return checkImageValidity(promises).then((res) => {
		return res;
	});
};

const checkImageValidity = (promises) => {
	let resolvingPromises = promises.map((promise) => {
		return new Promise((resolve) => {
			let imageUrl;
			promise
				.then((res) => {
					if (res.data) {
						imageUrl = res.config.url;
					} else {
						imageUrl = null;
					}
				})
				.catch((error) => {
					imageUrl = null;
				})
				.then(function () {
					resolve(imageUrl);
				});
		});
	});

	let imageUrls = [];

	// Execute all wrapped Promises
	return Promise.all(resolvingPromises).then((res) => {
		res.forEach(function (imageUrl) {
			imageUrls.push(imageUrl);
		});

		return imageUrls;
	});
};

export const getPromoCode = async (apis, requestId, backendBaseUrl) => {
	return await resolve(
		axios
			.get(`${backendBaseUrl}${apis.getPromoCode}`, {
				headers: {
					'Content-Type': 'application/json',
				},
				params: {
					requestId: requestId,
				},
			})
			.then((res) => {
				return res.data;
			})
	);
};

export const submitInstallRequest = async (formData, apis, backendBaseUrl) => {
	return await resolve(
		axios
			.post(`${backendBaseUrl}${apis.submitInstallRequest}`, generateFinalSubmitRequestBody(formData), {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((res) => res.data)
	);
};

export const validateAddress = async (formData, apis, backendBaseUrl) => {
	return await resolve(
		axios
			.post(`${backendBaseUrl}${apis.addrValidation}`, generateAddressValidationBody(formData), {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((res) => res.data)
	);
};

export const vinLookup = async (vin, apis, backendBaseUrl) => {
	return await resolve(
		axios
			.get(`${backendBaseUrl}${apis.vinLookup.replace('{vin}', vin)}`, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((res) => {
				return res.data;
			})
			.catch((error) => {
				throw error;
			})
	);
};

export const orderNumberLookup = async (orderNum, apis, backendBaseUrl) => {
	return await resolve(
		axios
			.get(`${backendBaseUrl}${apis.validateOrder.replace('{orderNumber}', orderNum)}`, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((res) => {
				return res.data;
			})
			.catch((error) => {
				throw error;
			})
	);
};
