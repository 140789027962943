import { Box, IconButton, Collapse } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

const ErrorAlert = (props) => {
  return (
    <div style={{ width: '100%' }}>
      <Collapse in={props.open}>
        <Box marginBottom='1rem'>
          <Alert
            severity='error'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={props.close}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            <AlertTitle>Error!</AlertTitle>
            We are currently experiencing unexpected server issues. Please exit
            and try again, sorry for the inconvenience.
          </Alert>
        </Box>
      </Collapse>
    </div>
  );
};

export default ErrorAlert;
