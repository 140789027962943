import React from 'react';
import Message from '../UI/QuestionTypes/Message';

const Confirmation = ({ isFinalStep }) => {
	const messageCheckEmail = 'Thank you for your request. Please check your email to verify your email address.';
	const messageTextFinal =
		'Your EV Install Form for home installation was successfully submitted! In the next few days, ' +
		"you'll receive an email from Qmerit, our authorized provider, with details about your installer.";
	const messageTextFinal2 =
		'If you have any questions in the meantime, please contact Qmerit at (888) 272-0090 or customerservice@qmerit.com';

	return (
		<div className="block-quote-lg--primary">
			<Message text={isFinalStep ? messageTextFinal : messageCheckEmail} />
			{isFinalStep && <Message text={messageTextFinal2} />}
		</div>
	);
};

export default Confirmation;
