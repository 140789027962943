import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import SelectQuestion from '../UI/QuestionTypes/SelectQuestion';
import RadioQuestion from '../UI/QuestionTypes/RadioQuestion';
// import getConfig from 'next/config';

const Question13 = (props) => {
	const question = 'How many electrical panels do you have?';
	const options = [
		{ value: '16', text: 'One' },
		{ value: '17', text: 'Two' },
		{ value: '41', text: "I don't know" },
	];

	return (
		<SelectQuestion
			name="question13"
			dataDtm="electrical panel details"
			value={props.question13}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Question14 = (props) => {
	const question = 'Where is your panel located?';
	const options = [
		{ value: '7', text: 'Below or near the garage' },
		{ value: '8', text: 'Opposite side of the basement from the garage' },
		{ value: '42', text: 'On the other side of the garage wall' },
		{ value: '43', text: 'Middle of the house' },
		{ value: '67', text: 'Opposite side of the home from the garage' },
		{ value: '68', text: 'On an interior wall of the garage' },
		{ value: '69', text: 'On an exterior wall of the garage' },
	];

	return (
		<RadioQuestion
			name="question14"
			dataDtm="electrical panel details"
			value={props.question14}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Question26 = (props) => {
	const question = 'Where is your main electrical panel located?';
	const options = [
		{ value: '14', text: 'Basement' },
		{ value: '15', text: 'Inside Home' },
		{ value: '13', text: 'Garage Wall' },
		{ value: '70', text: 'Exterior Wall of the Home' },
		{ value: '71', text: 'Other Location' },
	];

	return (
		<RadioQuestion
			name="question26"
			dataDtm="electrical panel details"
			value={props.question26}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Question31 = (props) => {
	const question = 'Is your basement finished or unfinished?';
	const options = [
		{ value: '195', text: 'Finished' },
		{ value: '196', text: 'Unfinished' },
	];

	return (
		<RadioQuestion
			name="question31"
			dataDtm="electrical panel details"
			value={props.question31}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Question15 = (props) => {
	// const { publicRuntimeConfig } = getConfig();
	// const publicRuntimeConfig = { basePath: '/electric' };
	const question =
		'What is the size of the main electrical breaker? (You can commonly find this inside of your electrical box)';

	const options = [
		{ value: '53', text: '60 amps' },
		{ value: '44', text: '100 amps' },
		{ value: '45', text: '125 amps' },
		{ value: '46', text: '150 amps' },
		{ value: '47', text: '200+ amps' },
		{ value: '41', text: "I don't know" },
	];

	return (
		<RadioQuestion
			name="question15"
			dataDtm="electrical panel details"
			value={props.question15}
			onChange={props.handleChange}
			question={question}
			options={options}
			sampleImageUrl={`${props.imagePath}/electricalPanelSize.jpg`}
			sampleImageDesc="electrical panel size"
		/>
	);
};

const Question32 = (props) => {
	const question =
		'What type of access is potentially available to the installer for routing the wiring from the electrical panel to the charging location?';

	const options = [
		{ value: '182', text: 'Along the wall' },
		{ value: '183', text: 'Attic' },
		{ value: '185', text: 'Crawl space below the home' },
		{ value: '186', text: 'Unfinished basement' },
		{ value: '187', text: 'None of the above' },
		{ value: '41', text: "I don't know" },
	];

	return (
		<RadioQuestion
			name="question32"
			dataDtm="electrical panel details"
			value={props.question32}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Question33 = (props) => {
	const question = 'How far away will the installed charging location be from the main electrical panel?';

	const options = [
		{ value: '188', text: 'Under 10 feet' },
		{ value: '189', text: '10 to 29 feet' },
		{ value: '190', text: '30 to 49 feet' },
		{ value: '191', text: '50 feet or more' },
		{ value: '41', text: "I don't know" },
	];

	return (
		<RadioQuestion
			name="question33"
			dataDtm="electrical panel details"
			value={props.question33}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Question34 = (props) => {
	const question =
		'When standing in the driveway facing the garage, which wall do you want your charging location mounted?';

	const options = [
		{ value: '192', text: 'Left wall' },
		{ value: '193', text: 'Right wall' },
		{ value: '194', text: 'Back wall' },
	];

	return (
		<RadioQuestion
			name="question34"
			dataDtm="electrical panel details"
			value={props.question34}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Group7 = (props) => {
	const classes = useStyles();
	const { submitRef, enableNavButton } = props;
	const [question13, setQuestion13] = useState(props.formData.question13);
	const [question14, setQuestion14] = useState(props.formData.question14);
	const [question15, setQuestion15] = useState(props.formData.question15);
	const [question26, setQuestion26] = useState(props.formData.question26);
	const [question31, setQuestion31] = useState(props.formData.question31);
	const [question32, setQuestion32] = useState(props.formData.question32);
	const [question33, setQuestion33] = useState(props.formData.question33);
	const [question34, setQuestion34] = useState(props.formData.question34);

	useEffect(() => {
		const enable =
			!!question13 &&
			!!question14 &&
			!!question15 &&
			!!question32 &&
			!!question33 &&
			!!question34 &&
			((question26 === '14' && !!question31) || (question26 !== '14' && !!question26));
		enableNavButton(enable);
	}, [question13, question14, question15, question26, question31, question32, question33, question34]);

	useImperativeHandle(submitRef, () => ({
		validateGroup7Form: () => {
			// add validation if needed
			return [
				true,
				{
					question13: question13,
					question14: question14,
					question15: question15,
					question26: question26,
					question31: question31,
					question32: question32,
					question33: question33,
					question34: question34,
				},
			];
		},
	}));

	const handleQuestion13Change = (newValue) => {
		setQuestion13(newValue);
	};

	const handleQuestion14Change = (newValue) => {
		setQuestion14(newValue);
	};

	const handleQuestion15Change = (newValue) => {
		setQuestion15(newValue);
	};

	const handleQuestion26Change = (newValue) => {
		setQuestion26(newValue);
	};

	const handleQuestion31Change = (newValue) => {
		setQuestion31(newValue);
	};

	const handleQuestion32Change = (newValue) => {
		setQuestion32(newValue);
	};

	const handleQuestion33Change = (newValue) => {
		setQuestion33(newValue);
	};

	const handleQuestion34Change = (newValue) => {
		setQuestion34(newValue);
	};

	return (
		<>
			<Box>
				<form className={classes.root} autoComplete="off">
					<Question13 question13={question13} handleChange={handleQuestion13Change} />
					<Question26 question26={question26} handleChange={handleQuestion26Change} />
					{question26 && question26 === '14' && (
						<Box mt={2}>
							<Question31 question31={question31} handleChange={handleQuestion31Change} />
						</Box>
					)}
					<Question14 question14={question14} handleChange={handleQuestion14Change} />
					<Question15 question15={question15} handleChange={handleQuestion15Change} imagePath={props.imagePath} />
					<Question32 question32={question32} handleChange={handleQuestion32Change} />
					<Question33 question33={question33} handleChange={handleQuestion33Change} />
					<Question34 question34={question34} handleChange={handleQuestion34Change} />
				</form>
			</Box>
		</>
	);
};

export default Group7;
