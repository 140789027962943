import React, { useState, useEffect } from 'react';
import { Typography, makeStyles, Grid, Divider } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import {
  getRequest,
  getQuestionRequest,
  getRequestStatus,
} from '../services/api';
import CustomerInfo from '../CustomerInfo/CustomerInfo';
import Questions from './Questions';
import ReturnHome from '../shared/ReturnHome';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import {
  useMsal,
  useAccount,
} from '@azure/msal-react';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  root2: {
    justifyContent: 'center',
    maxWidth: '80%',
    paddingTop: '25px',
  },
  root3: {
    justifyContent: 'center',
    maxWidth: '80%',
    paddingTop: '10px',
  },
}));

export default function ViewRequest() {
  let params = useParams();
  const classes = useStyles();
  const [visibility, setVisibility] = useState(false);
  const [results, setResults] = useState(null);
  const [results2, setResults2] = useState();
  const [profileId, setProfileId] = useState(null);
  const { accounts } = useMsal();

  const account = useAccount(accounts[0] || {});
  if (account && account.idTokenClaims.sub && !profileId) {
    setProfileId(account.idTokenClaims.sub);
  }

  let history = useHistory();

  useEffect(() => {
    const searchRequestId = async () => {
      if (params.requestId) {
        const response = await getRequest(params.requestId); //customer data
        if(profileId !== response.profileId) {
          history.push("/"); 
        }
        const response2 = await getQuestionRequest(params.requestId); //questionnaire data
        setResults(response);
        setResults2(response2.data);
      }
    };
    searchRequestId();
  }, [params.requestId, profileId]);

  const handleCallback = () => {
    setVisibility(true);
  };
  return (
    <>
      <ReturnHome />
      <Divider />
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.root2}>
          <Typography variant='h3' color='textPrimary'>
            View your Request
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.root2}>
          <Typography variant='h4' color='textPrimary'>
            {`Request ID ${params.requestId}`}
          </Typography>
        </Grid>
        {results && (
          <>
            <Grid item xs={12} className={classes.root3}>
              <Typography variant='subtitle1' color='textPrimary'>
                {getRequestStatus(results.statusId).status === 'Active' && (
                  <FiberManualRecord style={{ fill: '#17cb49', fontSize: 8 }} />
                )}
                {getRequestStatus(results.statusId).status === 'Pending' && (
                  <FiberManualRecord style={{ fill: '#ff8139', fontSize: 8 }} />
                )}
                {getRequestStatus(results.statusId).status === 'Completed' && (
                  <FiberManualRecord style={{ fill: '#1b73ce', fontSize: 8 }} />
                )}
                {getRequestStatus(results.statusId).status === 'Canceled' && (
                  <FiberManualRecord style={{ fill: 'red', fontSize: 8 }} />
                )}
                {`  ${getRequestStatus(results.statusId).status}`}
              </Typography>
            </Grid>
            <Grid style={{ width: '80%' }}>
              <CustomerInfo
                snapshot={results}
                parentCallback={handleCallback}
              />
              <br></br>
              <br></br>
              {visibility ? <Questions data={results2} /> : <div></div>}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
