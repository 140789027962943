const environments = {
  dev: "dev",
  qa: "qa",
  uat: "uat",
  perf: "perf",
  prodExt: "www.gmhomecharging.com",
  prod: "prod",
  akamai: "www-gmhomecharging-com",
};

const getAzureRedirectUrl = () => {
  const host = window.location.host;

  switch (true) {
    case host.includes("localhost"):
      return "http://localhost:3000/login";
    case host.includes(environments.dev):
      return "https://home-charging-customer-dev.op-epg14mi.gm.com/login";
    case host.includes(environments.qa):
      return "https://home-charging-customer-qa.op-epg14mi.gm.com/login";
    case host.includes(environments.uat):
      return "https://home-charging-customer-uat.op-epg14mi.gm.com/login";
    case host.includes(environments.perf):
      return "https://home-charging-customer-perf.op-epg14mi.gm.com/login";
    case host.includes(environments.prod):
      return "https://home-charging-customer-prod.op15i.gm.com/login";
    case host.includes(environments.prodExt):
      return "https://www.gmhomecharging.com/login";
    case host.includes(environments.akamai):
      return "https://www-gmhomecharging-com.cdn-prv.gm.com/login";
    default:
      return "https://home-charging-customer-dev.op-epg14mi.gm.com/login";
  }
};

const host = window.location.host;
let isProd =
  host.includes(environments.prod) ||
  host.toLowerCase().includes(environments.prodExt) ||
  host.toLowerCase().includes(environments.akamai);

const tenant = isProd
  ? "gmb2cprod.onmicrosoft.com"
  : "gmb2ctest.onmicrosoft.com";
const signInPolicy = "b2c_1a_seamlessmigration_signuporsignin";
// const applicationID = isProd ? "bf10dee2-0516-4d0c-bd50-a2b26e4c03f1" : "5ad7668e-0cf4-4ad0-ba73-323bb1e15581";
const reactRedirectUri = getAzureRedirectUrl();
const instance = isProd
  ? "https://custlogin.gm.com/"
  : "https://gmb2ctest.b2clogin.com/";
const signInAuthority = `${instance}${tenant}/${signInPolicy}`;

export const msalConfig = {
  auth: {
    authority: signInAuthority,
    // clientId: applicationID,
    redirectUri: reactRedirectUri,
    postLogoutRedirectUri: "/#/loggedout",
    knownAuthorities: [instance],
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    allowRedirectInIframe: true,
  },
};

export const loginRequest = {
  scopes: ["openid"],
  extraQueryParameters: {
    channel: "charging",
  },
};
