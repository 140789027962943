import React, { useState, useEffect, useImperativeHandle } from "react";
import { Box, TextField } from "@material-ui/core";
import { useStyles } from "./styles";
import RadioQuestion from "../UI/QuestionTypes/RadioQuestion";
import Message from "../UI/QuestionTypes/Message";
import SelectQuestion from "../UI/QuestionTypes/SelectQuestion";
import { vinLookup, orderNumberLookup } from "../../services/api";
import * as hciProps from "../../props.json";

const Question18 = (props) => {
  const question = "What vehicle are you planning to charge at home?";
  const options = [
    { value: "37", text: "2022 Bolt EV" },
    { value: "38", text: "2022 Bolt EUV" },
    { value: "207", text: "2023 Bolt EV" },
    { value: "208", text: "2023 Bolt EUV" },
    { value: "209", text: "2024 Blazer EV" },
    // { value: "213", text: "2024 Equinox EV" },
  ];

  return (
    <SelectQuestion
      name="question18"
      dataDtm="vehicle model year"
      value={props.question18}
      onChange={props.handleChange}
      question={question}
      options={options}
      disabledDropdown={props.disabledDropdown}
      selectVehicle={true}
    />
  );
};

const Group0 = (props) => {
  const hciProps2 = hciProps;
  const classes = useStyles();
  const { submitRef, enableNavButton } = props;
  const [question18, setQuestion18] = useState(props.question18 || "");
  const [question19, setQuestion19] = useState(props.question19 || "");
  const [errorMsg, setErrMsg] = useState("");
  const [vin, setVIN] = useState(props.vin);
  const [disabledDropdown, setdisabledDropdown] = useState(
    props.disabledDropdown
  );

  useEffect(() => {
    enableNavButton(question18 && (question18 !== "10" || !!question19));
  }, [question18, question19]);

  useImperativeHandle(submitRef, () => ({
    validateGroup0Form: () => {
      // add validation if needed
      return [true, question18, question19, vin, disabledDropdown];
    },
  }));

  const handleQuestion18 = (newValue) => {
    setQuestion18(newValue);
  };

  const handleQuestion19 = (newValue) => {
    setQuestion19(newValue);
  };

  const formatModelName = (name) => {
    let formatted = name.replace("_", " ");
    return formatted;
  };

  const getVehicleYMM = async (vin, apis, backendBaseUrl) => {
    const ymmInfo = await vinLookup(vin, apis, backendBaseUrl);
    if (!ymmInfo.data || !ymmInfo.data.VINLookupResponse) {
      throw new Error("Please enter a valid VIN");
    }
    return ymmInfo.data.VINLookupResponse;
  };

  const getVehicleOrderNumber = async (orderNum, apis, backendBaseUrl) => {
    const orderNumberInfo = await orderNumberLookup(
      orderNum,
      apis,
      backendBaseUrl
    );
    if (!orderNumberInfo.data) {
      throw new Error("Please enter a valid Order Number");
    }
    return orderNumberInfo.data;
  };

  const vinValidationHandler = async (event) => {
    try {
      setErrMsg("");
      setQuestion19("");
      setVIN(event.target.value);
      setdisabledDropdown(false);
      setQuestion18("10");
      if (event.target.value.length >= 1) {
        setdisabledDropdown(true);
      }

      if (event.target.value.length === 6) {
        const orderNumRes = await getVehicleOrderNumber(
          event.target.value,
          hciProps2.meta.qmerit,
          props.backendBaseUrl
        );

        if (orderNumRes.brand.toLowerCase() === "cadillac") {
          throw new Error(
            "Not a Chevrolet Order Number, please enter a Chevrolet Order Number."
          );
        }
        if (orderNumRes.requestId) {
          throw new Error(
            "Order Number is already registered, please try a different Order Number."
          );
        }
        const model = `${orderNumRes?.modelYear} ${
          orderNumRes?.brand
        } ${formatModelName(orderNumRes.vehicleModel)}`;
        setQuestion19(model);
      }

      //VIN
      else if (event.target.value.length === 17) {
        const ymmRes = await getVehicleYMM(
          event.target.value,
          hciProps2.meta.qmerit,
          props.backendBaseUrl
        );

        if (ymmRes.division?.divisionName.toLowerCase().includes("cadillac")) {
          throw new Error("Not a Chevrolet VIN, please enter a Chevrolet VIN.");
        }

        const ymm = `${ymmRes?.year} ${
          ymmRes.division?.divisionName
        } ${formatModelName(ymmRes.modelDescription)}`;

        setQuestion19(ymm);
      }
    } catch (error) {
      setErrMsg(error.message);
    }
  };

  const errorHandler = (vin) => {
    if (vin.length !== 0 && vin.length !== 17 && vin.length !== 6) {
      return "VIN must be 17 digits. Order # must be 6 Digits";
    } else if (errorMsg !== "") {
      return errorMsg;
    }
  };

  const messageText =
    "Congratulations on your new Bolt! To take advantage of Chevrolet's installation promotion, reach out to your authorized and participating Chevrolet dealer.";

  const messageText2 =
    "If you will not be using the Chevrolet promotion, please continue.";

  const messageText3 =
    "Congratulations on your new Blazer EV! To take advantage of Chevrolet's installation promotion, reach out to your authorized and participating Chevrolet dealer.";

  const messageText4 =
    "Congratulations on your new Equinox EV! To take advantage of Chevrolet's installation promotion, reach out to your authorized and participating Chevrolet dealer.";

  const promotionMessage = (model) => {
    if (model.toLowerCase().includes("bolt")) {
      return (
        <div className="paragraph-1--secondary">
          <Message text={messageText} />
          <Message text={messageText2} />
        </div>
      );
    } else if (model.toLowerCase().includes("blazer")) {
      return (
        <div className="paragraph-1--secondary">
          <Message text={messageText3} />
          <Message text={messageText2} />
        </div>
      );
    }
    // } else if (model.toLowerCase().includes("equinox")) {
    //   return (
    //     <div className="paragraph-1--secondary">
    //       <Message text={messageText4} />
    //       <Message text={messageText2} />
    //     </div>
    //   );
    // }
  };

  return (
    <>
      <Box>
        <form className={classes.root} autoComplete="off">
          <Question18
            question18={question18}
            handleChange={handleQuestion18}
            question19={question19}
            handleOtherChange={handleQuestion19}
            disabledDropdown={disabledDropdown}
          />
          {question18 &&
            (question18 === "37" ||
              question18 === "38" ||
              question18 === "207" ||
              question18 === "208") && (
              <div className="paragraph-1--secondary">
                <Message text={messageText} />
                <Message text={messageText2} />
              </div>
            )}

          {question18 && question18 === "209" && (
            <div className="paragraph-1--secondary">
              <Message text={messageText3} />
              <Message text={messageText2} />
            </div>
          )}
          {/* {question18 && question18 === "213" && (
            <div className="paragraph-1--secondary">
              <Message text={messageText4} />
              <Message text={messageText2} />
            </div>
          )} */}
        </form>
        <Box sx={{ marginTop: "15px", marginBottom: "20px" }}>or</Box>
        <TextField
          id="outlined-basic"
          label="Input Order # or  VIN"
          variant="outlined"
          inputProps={{ className: classes.inputField }}
          value={vin}
          onChange={vinValidationHandler}
          error={!!errorHandler(vin)}
          helperText={errorHandler(vin) || question19}
        />
        {promotionMessage(question19)}
      </Box>
    </>
  );
};

export default Group0;
