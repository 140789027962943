import React, { useState } from 'react';
import { Grid, Typography, Box, TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	inputField: {
		// need input specificity to override styles on AEM :c
		"&.MuiInputBase-input": {
			padding: multiline => multiline ? 'unset' : '18.5px 14px',
			height: multiline => multiline ? 'unset' : '1.1876em',
			margin: '0',
			boxSizing: 'content-box',
			border: '0',
			background: 'none',
			font: 'inherit',
			boxShadow: 'none'
		}
	}}));

const TextQuestion = (props) => {
	// props can include the below:
	//		number - if true restricts text input to only numeric
	//		multiline - if true allows for text input box to expand to accomodate multiline
	//			inputs unless rows are defined
	//		rows - if defined rows defines number of rows in a multiline text input box
	//		large - if true makes the question text the same font styling as main questions

	const classes = useStyles(props.multiline);
	const [value, setValue] = useState(props.value);

	const handleChange = (e) => {
		setValue(e.target.value);

		props.onChange(e.target.value);
	};

	const handleNumberChange = (e) => {
		const num = e.target.value.replace(/[^0-9]/g, '');

		setValue(num);
		props.onChange(num);
	};

	return (
		<>
			<Grid container>
				<Grid item xs={12} sm={10}>
					<Typography component="div">
						<Box fontSize="h7.fontSize" fontWeight="800">
							<div className={props.large ? 'block-quote-lg--primary' : 'paragraph-1--secondary'}>
								{props.question}
							</div>
						</Box>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<TextField
						required={props.required}
						name={props.name}
						label={props.label}
						variant="outlined"
						value={value}
						onChange={props.number ? handleNumberChange : handleChange}
						size={props.size}
						inputProps={{ maxLength: props.maxLength, className: classes.inputField, inputMode: props.number && 'numeric'}}
						fullWidth={props.fullWidth}
						placeholder={props.placeholderText}
						multiline={props.multiline}
						minRows={props.rows}
					/>
				</Grid>
			</Grid>
		</>
	);
};

TextQuestion.defaultProps = {
	multiline: false,
	number: false,
};

export default TextQuestion;
