import React from 'react';
import CustomTooltip from '../UIElements/CustomTooltip';
import InfoIcon from '@material-ui/icons/Info';
import { useStyles } from '../../Questions/styles';

const Tooltip = ({ content }) => {
	const classes = useStyles();

	return (
		<>
			<CustomTooltip title={content}>
				<InfoIcon className={classes.inlineIcon} color="primary" fontSize="small" />
			</CustomTooltip>
		</>
	);
};

export default Tooltip;
