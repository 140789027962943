export const stateList = [
	{ name: 'Alabama', abbr: 'AL' },
	{ name: 'Alaska', abbr: 'AK' },
	{ name: 'Arizona', abbr: 'AZ' },
	{ name: 'Arkansas', abbr: 'AR' },
	{ name: 'California', abbr: 'CA' },
	{ name: 'Colorado', abbr: 'CO' },
	{ name: 'Connecticut', abbr: 'CT' },
	{ name: 'Delaware', abbr: 'DE' },
	{ name: 'District of Columbia', abbr: 'DC' },
	{ name: 'Florida', abbr: 'FL' },
	{ name: 'Georgia', abbr: 'GA' },
	{ name: 'Hawaii', abbr: 'HI' },
	{ name: 'Idaho', abbr: 'ID' },
	{ name: 'Illinois', abbr: 'IL' },
	{ name: 'Indiana', abbr: 'IN' },
	{ name: 'Iowa', abbr: 'IA' },
	{ name: 'Kansas', abbr: 'KS' },
	{ name: 'Kentucky', abbr: 'KY' },
	{ name: 'Louisiana', abbr: 'LA' },
	{ name: 'Maine', abbr: 'ME' },
	{ name: 'Maryland', abbr: 'MD' },
	{ name: 'Massachusetts', abbr: 'MA' },
	{ name: 'Michigan', abbr: 'MI' },
	{ name: 'Minnesota', abbr: 'MN' },
	{ name: 'Mississippi', abbr: 'MS' },
	{ name: 'Missouri', abbr: 'MO' },
	{ name: 'Montana', abbr: 'MT' },
	{ name: 'Nebraska', abbr: 'NE' },
	{ name: 'Nevada', abbr: 'NV' },
	{ name: 'New Hampshire', abbr: 'NH' },
	{ name: 'New Jersey', abbr: 'NJ' },
	{ name: 'New Mexico', abbr: 'NM' },
	{ name: 'New York', abbr: 'NY' },
	{ name: 'North Carolina', abbr: 'NC' },
	{ name: 'North Dakota', abbr: 'ND' },
	{ name: 'Ohio', abbr: 'OH' },
	{ name: 'Oklahoma', abbr: 'OK' },
	{ name: 'Oregon', abbr: 'OR' },
	{ name: 'Pennsylvania', abbr: 'PA' },
	{ name: 'Puerto Rico', abbr: 'PR' },
	{ name: 'Rhode Island', abbr: 'RI' },
	{ name: 'South Carolina', abbr: 'SC' },
	{ name: 'South Dakota', abbr: 'SD' },
	{ name: 'Tennessee', abbr: 'TN' },
	{ name: 'Texas', abbr: 'TX' },
	{ name: 'Utah', abbr: 'UT' },
	{ name: 'Vermont', abbr: 'VT' },
	{ name: 'Virginia', abbr: 'VA' },
	{ name: 'Washington', abbr: 'WA' },
	{ name: 'West Virginia', abbr: 'WV' },
	{ name: 'Wisconsin', abbr: 'WI' },
	{ name: 'Wyoming', abbr: 'WY' },
];

export const fieldAttributes = {
	firstName: {
		maxlength: 30,
		pattern: /^(?!.*\s{2,})(?!^ )([A-Za-z\s'-]{1,30})$/,
	},
	lastName: {
		maxlength: 30,
		pattern: /^(?!.*\s{2,})(?!^ )([A-Za-z\s'-]{1,30})$/,
	},
	zip: {
		maxlength: 5,
		pattern: /^[0-9]{5}$/,
	},
	emailAddress: {
		pattern:
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	},
	phone: {
		pattern: /^([(]{1}[0-9]{3}[)]{1}[ ]{1}[0-9]{3}[-]{1}[0-9]{4})$/,
	},
};

export const convertState = (state, formatFrom, formatTo) => {
	for (let i = 0; i < stateList.length; i++) {
		if (stateList[i][formatFrom] === state) {
			return stateList[i][formatTo];
		}
	}
};
