import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import TextQuestion from '../UI/QuestionTypes/TextQuestion';
import MultiResponseQuestion from '../UI/QuestionTypes/MultiResponseQuestion';

const Question35 = (props) => {
	const question = 'How many central air conditioning units does your home have?';

	return (
		<TextQuestion
			name="question35"
			dataDtm="load calculation"
			value={props.question35}
			onChange={props.handleChange}
			question={question}
			required={true}
			large={true}
			number={true}
			maxLength={255}
		/>
	);
};

const Question36 = (props) => {
	const question = 'How many refrigerators does your home have?';

	return (
		<TextQuestion
			name="question36"
			dataDtm="load calculation"
			value={props.question36}
			onChange={props.handleChange}
			question={question}
			required={true}
			large={true}
			number={true}
			maxLength={255}
		/>
	);
};

const Question37 = (props) => {
	const question = 'Please select any other electrical appliances that you may have.';

	const options = [
		{ value: '197', text: 'Electric dryer' },
		{ value: '198', text: 'Electric hot water heater' },
		{ value: '199', text: 'Electric oven' },
		{ value: '200', text: 'Electric cooktop' },
		{ value: '201', text: 'Electric sauna' },
		{ value: '202', text: 'Electric heater' },
		{ value: '203', text: 'Steam shower' },
		{ value: '187', text: 'None of the above' },
	];

	return (
		<MultiResponseQuestion
			name="question37"
			dataDtm="load calculation"
			value={props.question37}
			onChange={props.handleChange}
			question={question}
			options={options}
			large={true}
		/>
	);
};

const Question38 = (props) => {
	const question = 'If you have a pool or spa, how many electric pumps does it have?';

	return (
		<TextQuestion
			name="question38"
			dataDtm="load calculation"
			value={props.question38}
			onChange={props.handleChange}
			question={question}
			large={true}
			number={true}
			maxLength={255}
		/>
	);
};

const Group9 = (props) => {
	const classes = useStyles();

	const { submitRef, enableNavButton } = props;
	const [question35, setQuestion35] = useState(props.formData.question35);
	const [question36, setQuestion36] = useState(props.formData.question36);
	const [question37, setQuestion37] = useState(props.formData.question37);
	const [question38, setQuestion38] = useState(props.formData.question38);

	useEffect(() => {
		const enable = !!question35 && !!question36 && !!question37;
		enableNavButton(enable);
	}, [question35, question36, question37, question38]);

	useImperativeHandle(submitRef, () => ({
		validateGroup9Form: () => {
			// add validation if needed
			return [
				true,
				{ question35: question35, question36: question36, question37: question37, question38: question38 },
			];
		},
	}));

	const handleQuestion35Change = (newValue) => {
		setQuestion35(newValue);
	};

	const handleQuestion36Change = (newValue) => {
		setQuestion36(newValue);
	};

	const handleQuestion37Change = (newValue) => {
		setQuestion37(newValue);
	};

	const handleQuestion38Change = (newValue) => {
		setQuestion38(newValue);
	};

	return (
		<>
			<Box>
				<form className={classes.root} autoComplete="off">
					<Box mt={2}>
						<Question35 question35={question35} handleChange={handleQuestion35Change} />
					</Box>
					<Box mt={2}>
						<Question36 question36={question36} handleChange={handleQuestion36Change} />
					</Box>
					<Box mt={2}>
						<Question37 question37={question37} handleChange={handleQuestion37Change} />
					</Box>
					<Box mt={2}>
						<Question38 question38={question38} handleChange={handleQuestion38Change} />
					</Box>
				</form>
			</Box>
		</>
	);
};
export default Group9;
