import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import React, { useState } from 'react';
import { useStyles } from '../../Questions/styles';

const MultiResponseQuestion = (props) => {
	const classes = useStyles();

	const handleInitialSelection = (values, initialOptions) => {
		if (!values.length > 0) {
			return initialOptions;
		}
		// when form has saved data for a multi select, populate checked: true keys to existing values
		const initialValues = initialOptions.map((opt) => {
			if (values.includes(opt.value)) {
				return { ...opt, checked: true };
			}
			return opt;
		});
		return initialValues;
	};

	// map out the checkbox options and default checked key to false
	const initialSelection = props.options.map((opt) => ({ ...opt, checked: false }));

	const [selections, setSelections] = useState(() => {
		const init = handleInitialSelection(props.value, initialSelection);
		return init;
	});

	// function to format the response to be a string of only the outcomeIds
	const formatResponse = (arr) => {
		const response = arr.filter((element) => element.checked === true).map((e) => e.value);
		return response.toString();
	};

	const handleChange = (e, index, optionValue) => {
		// handles unchecking of all other values when none of the above selected
		if (optionValue === '187' && e.target.checked === true) {
			const nullArray = [
				...initialSelection.slice(0, index),
				{ ...initialSelection[index], checked: true },
				...initialSelection.slice(index + 1),
			];
			setSelections(nullArray);
			props.onChange(formatResponse(nullArray));
			return;
		}
		// uncheck none of the above for any other case
		const noneIndex = selections.findIndex((element) => element.value === '187');
		let selected = selections;
		if (selections[noneIndex]?.checked === true) {
			selected = [
				...selections.slice(0, noneIndex),
				{ ...selections[noneIndex], checked: false },
				...selections.slice(noneIndex + 1),
			];
		}
		const newSelections = [
			...selected.slice(0, index),
			{ ...selected[index], checked: e.target.checked },
			...selected.slice(index + 1),
		];
		setSelections(newSelections);
		props.onChange(formatResponse(newSelections));
	};

	return (
		<>
			<Grid container>
				<Grid item xs={12} sm={10}>
					<Typography component="div">
						<Box fontSize="h7.fontSize" fontWeight="800">
							<div className="block-quote-lg--primary">{props.question}</div>
							<br />
						</Box>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<FormGroup>
						{props.options?.length > 0 &&
							props.options.map((option, i) => (
								<FormControlLabel
									key={i}
									data-dtm={props.dataDtm}
									control={
										<Checkbox
											checked={selections[i]?.checked}
											icon={<CircleUnchecked />}
											checkedIcon={<CheckCircleIcon color="primary" value={option.value} />}
											onClick={(e) => handleChange(e, i, option.value)}
										/>
									}
									label={<div className="paragraph-1--secondary">{option.text}</div>}
								></FormControlLabel>
							))}
					</FormGroup>
				</Grid>
			</Grid>
		</>
	);
};
export default MultiResponseQuestion;
