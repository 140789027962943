import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { getEndpointUrls } from "../services/api";
import { useState, useContext } from 'react';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 1000,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modalContainer: {
    marginTop: '15px',
    overflow: 'scroll',
    maxWidth: '70%',
    maxHeight: '90%',
    textAlign: 'center',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  imageStyle: {
    maxWidth: '850px',
    maxHeight: '850px',
  },
  formImageStyle: {
    maxWidth: '425px',
    maxHeight: '425px',
  },
}));

const PhotoModal = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const endPointUrls = getEndpointUrls();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div className={classes.modalContainer}>
      <strong>{props.questionTitle}</strong>
      <br></br>
      <br></br>
      <img
        className={classes.imageStyle}
        width
        src={`${endPointUrls.backendURL}/s3/download/${props.additionalResponse}`}
        alt="Broken"
      ></img>
    </div>
  );

  return (
    <div>
      <img
        className={classes.formImageStyle}
        onClick={handleOpen}
        src={`${endPointUrls.backendURL}/s3/download/${props.additionalResponse}`}
        alt="Broken"
      ></img>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
        
      </Modal>
    </div>
  );
};

export default PhotoModal;
