export const requestStatusMap = {
  1: { status: 'Pending', scheduleButton: 'disabled' },
  2: { status: 'Pending', scheduleButton: 'disabled' },
  3: { status: 'Pending', scheduleButton: 'disabled' },
  4: { status: 'Pending', scheduleButton: 'disabled' },
  5: { status: 'Pending', scheduleButton: 'disabled' },
  6: { status: 'Pending', scheduleButton: 'disabled' },
  7: { status: 'Pending', scheduleButton: 'disabled' },
  8: { status: 'Completed', scheduleButton: 'disabled' },
  9: { status: 'Completed', scheduleButton: 'disabled' },
  10: { status: 'Canceled', scheduleButton: 'disabled' },
  11: { status: 'Canceled', scheduleButton: 'disabled' },
  12: { status: 'Canceled', scheduleButton: 'disabled' },
  13: { status: 'Canceled', scheduleButton: 'disabled' },
  14: { status: 'Canceled', scheduleButton: 'disabled' },
  15: { status: 'Canceled', scheduleButton: 'disabled' },
  16: { status: 'Pending', scheduleButton: 'disabled' },
  17: { status: 'Pending', scheduleButton: 'disabled' },
  18: { status: 'Active', scheduleButton: 'schedule' },
  19: { status: 'Active', scheduleButton: 'view' },
  20: { status: 'Active', scheduleButton: 'disabled' },
  21: { status: 'Pending', scheduleButton: 'disabled' },
  22: { status: 'Pending', scheduleButton: 'disabled' },
  23: { status: 'Pending', scheduleButton: 'disabled' },
  24: { status: 'Pending', scheduleButton: 'disabled' },
  25: { status: 'Active', scheduleButton: 'disabled' },
  26: { status: 'Pending', scheduleButton: 'disabled' },
  27: { status: 'Completed', scheduleButton: 'view' },
  28: { status: 'Completed', scheduleButton: 'view' },
  29: { status: 'Active', scheduleButton: 'schedule' },
  30: { status: 'Active', scheduleButton: 'view' },
  31: { status: 'Active', scheduleButton: 'view' },
};
