import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: '20px',
    textAlign: 'left',
  },
  body: {
    paddingTop: '10px',
    textAlign: 'left',
    paddingBottom: '10px',
    textTransform: 'none',
  },
  info: {
    paddingTop: '20px',
    paddingBottom: '20px',
    textAlign: 'left',
    justifyContent: 'center',
  },
}));

const CustomerInfo = (props) => {
  const classes = useStyles();

  return (
    <>
      <Paper elevation={3} square>
        <div style={{ marginLeft: '40px', marginRight: '40px' }}>
          <Typography
            variant='h4'
            color='textPrimary'
            className={classes.title}
          >
            Your Information
          </Typography>
          <Typography
            variant='caption'
            color='textPrimary'
            className={classes.body}
          >
            If you need to update your personal information please reach out to
            the GM admin.
          </Typography>
          <Divider style={{ marginTop: '10px', marginBottom: '20px' }} />
          <Grid container spacing={3} className={classes.info}>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Typography variant='body2' color='textPrimary'>
                First and Last Name:
              </Typography>
              <br></br>
              <Typography variant='h5' color='textPrimary' style={{ overflowWrap: 'break-word' }}>
                {props.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Typography variant='body2' color='textPrimary'>
                Home Address:
              </Typography>
              <br></br>
              <Typography variant='h5' color='textPrimary'>
                {props.address}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Typography variant='body2' color='textPrimary'>
                Phone Number:
              </Typography>
              <br></br>
              <Typography variant='h5' color='textPrimary'>
                {props.phone}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Typography variant='body2' color='textPrimary'>
                Email Address:
              </Typography>
              <br></br>
              <Typography
                variant='h5'
                color='textPrimary'
                style={{ overflowWrap: 'anywhere' }}
              >
                {props.email}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </>
  );
};
export default CustomerInfo;
