import { loginRequest } from "../../authConfig";

export const handleLogin = (instance) => {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
};
  
export const handleLogout = (instance) => {
  sessionStorage.removeItem('gm.requests');
  sessionStorage.removeItem('gm.profileId');
  instance.logoutRedirect().catch((e) => {});
};
