import { loginRequest } from "../../authConfig";
import { useLocation } from 'react-router-dom'
import { Button } from "@material-ui/core";

const LoginButton = (props) => {
    const sessionId = new URLSearchParams(window.location.hash).get("sessionId");
    const sessionId2 = new URLSearchParams(window.location.search).get("sessionId");
    const location = useLocation();
    const sessionId3 = new URLSearchParams(location.search).get('sessionId');
    loginRequest.state = sessionId || sessionId2 || sessionId3;

    const handleLogin = () => {
      props.instance.loginRedirect(loginRequest).catch((e) => {
          console.error(e);
      });
    }
  return (
    <Button
      className={props.classes}
      variant='contained'
      onClick={() =>  {
        if(props.handleOnClick) {
          props.handleOnClick();
        }
        handleLogin();
      }}
      color='primary'
    >
      Sign In
    </Button>
  );
};

export default LoginButton;
