import React, { useEffect, useRef, useState } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import {
  Welcome,
  Terms,
  Group0,
  Group1,
  Group2,
  Group3,
  Group4,
  Group5,
  Confirmation,
  Group7,
  Group8,
  Group9,
  Group10,
} from "./components/Questions";
import CustomerInfoDisplay from "./components/Forms/CustomerInfoDisplay";
import AddressForm from "./components/Forms/AddressForm";
import styles from "./home-charger-form.module.scss";
import NavButton from "./components/UI/QuestionTypes/NavButton";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { makeStyles } from "@material-ui/core/styles";
import {
  getSession,
  postAnswers,
  submitGuestRequest,
  submitInstallRequest,
  validateAddress,
  vinLookup,
} from "./services/api";
import {
  determineLatestStep,
  checkMandatoryQuestions,
} from "./services/questionService";
import Alert from "@material-ui/lab/Alert";
import Modal from "./components/UI/Modal/Modal";
import * as hciProps from "./props.json";
import ChargerType from "./components/Questions/ChargerType";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  modalButtons: {
    // need specificity to override button styles in AEM
    "&.button": {
      marginBottom: `${theme.spacing(3)}px`,
      marginTop: `${theme.spacing(3)}px`,
      "&:hover": {
        color: "#fff",
      },
    },
  },
  buttonDisabled: {
    cursor: "not-allowed",
    pointerEvents: "none",
    "&.button[disabled]": {
      color: "#373637",
      backgroundColor: "#fafafa",
      opacity: "1",
    },
  },
  stepper: {
    padding: "24px 0px 24px 0px",
  },
  stepIcon: {
    cursor: "pointer",
  },
  modalRoot: {
    width: "100%",
    left: "0",
    top: "10%",
    [theme.breakpoints.up("md")]: {
      width: "1000px",
      left: "calc(50% - 500px)",
    },
  },
  addrModalContent: {
    textAlign: "center",
    padding: "15px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "25px",
      paddingRight: "25px",
    },
  },
  lineDivide: {
    [theme.breakpoints.up("sm")]: {
      borderRight: "solid 1px LightGray",
    },
    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
      borderBottom: "solid 1px LightGray",
    },
  },
  errorMsg: {
    paddingTop: "15px",
    paddingBottom: "15px",
  },
}));

const HomeChargerForm = ({ backendBaseUrl }) => {
  const props = hciProps;
  const imagePath = props.meta.qmerit.imagePath;
  const classes = useStyles();
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [isDealerAuthorized, setIsDealerAuthorized] = useState(null);
  const [isCustomerPay, setIsCustomerpay] = useState(null);
  const [requestId, setRequestId] = useState("");
  const [customerImages, setCustomerImages] = useState({});
  const [statusId, setStatusId] = useState(null);
  const [requestType, setRequestType] = useState("");
  const [loading, setLoading] = useState(true);
  //const [snapshotId, setSnapshotId] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [matchedAddress, setMatchedAddress] = useState({});
  const [showAddressValidationModal, setShowAddressValidationModal] =
    useState(false);
  const [disabledDropdown, setdisabledDropdown] = useState(false);
  // Error messages
  const [addressError, setAddressError] = useState(false);
  const [addressServerError, setAddressServerError] = useState(false);
  const [responseSaveError, setResponseSaveError] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const [formData, setFormData] = useState({
    termsAccepted: false,
    piAckAccepted: false,
    chargerType: "",
    dealer: {},
    requestId: "",
    orderNumber: "",
    vin: "",
    willContinue: "",
    model: "",
    question1: "",
    question2a: "",
    question2b: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
    question21: "",
    question8: "",
    question9: "",
    question10: "",
    question11: "",
    question12: "",
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    emailAddress: "",
    phone: "",
    question13: "",
    question14: "",
    question15: "",
    question32: "",
    question33: "",
    question34: "",
    question26: "",
    question31: "",
    question16: "",
    question18: "",
    question19: "",
    question20: "",
    question30: "",
    question22: "",
    question23: "",
    question24: "",
    question25: "",
    question27: "",
    question28: "",
    question29: "",
    question35: "",
    question36: "",
    question37: "",
    question38: "",
    question39: "",
    question40: "",
    question42: "",
    question43: "",
  });

  const [isNavButtonEnabled, setIsNavButtonEnabled] = useState(false);
  // const childRefs = useRef();
  const termRef = useRef();
  const addrRef = useRef();
  const chargerTypeRef = useRef();
  const group0Ref = useRef();
  const group1Ref = useRef();
  const group2Ref = useRef();
  const group3Ref = useRef();
  const group4Ref = useRef();
  const group5Ref = useRef();
  const group7Ref = useRef();
  const group8Ref = useRef();
  const group9Ref = useRef();
  const group10Ref = useRef();
  const stepRefs = useRef([]);

  const closeConfirmationModal = (goToStep) => {
    if (showConfirmationModal) {
      setShowConfirmationModal(false);
    }
    setActiveStep(goToStep);
  };

  const openConfirmationModal = () => {
    const revisitStep = checkMandatoryQuestions(formData, steps);
    if (!revisitStep) {
      if (!showConfirmationModal) {
        setShowConfirmationModal(true);
      }
    } else {
      for (let i = 0; i < steps.length; i++) {
        if (revisitStep === steps[i]) {
          setActiveStep(i);
        }
      }
    }
  };

  const closeAddressValidationModal = () => {
    if (showAddressValidationModal) {
      setShowAddressValidationModal(false);
    }
  };

  const openAddressValidationModal = () => {
    if (!showAddressValidationModal) {
      setShowAddressValidationModal(true);
    }
  };

  const clearErrorMsgs = () => {
    setAddressError(false);
    setAddressServerError(false);
    setResponseSaveError(false);
    setSubmitError(false);
  };

  const selectAddress = (useSuggested) => {
    if (useSuggested) {
      setFormData((preState) => ({
        ...preState,
        address1: matchedAddress.address1,
        city: matchedAddress.city,
        state: matchedAddress.state,
        zip: matchedAddress.zip,
      }));
    }
    closeAddressValidationModal();
    setActiveStep(activeStep + 1);
    clearErrorMsgs();
  };

  const submitForm = async () => {
    if (!submitting && !submitted) {
      setSubmitting(true);
      let res;
      if (requestId) {
        res = await submitInstallRequest(
          formData,
          props.meta.qmerit,
          backendBaseUrl
        );
      } else {
        res = await submitGuestRequest(
          formData,
          props.meta.qmerit,
          backendBaseUrl
        );
      }

      if (res.data) {
        clearErrorMsgs();
        closeConfirmationModal(activeStep + 1);
        setSubmitted(true);
      } else {
        setResponseSaveError(true);
        closeConfirmationModal(activeStep);
      }

      setSubmitting(false);
    }
  };

  const phoneFormatter = (number) => {
    const x = number.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
  };

  const nameFormatter = (name) => {
    const formatted = name.replace(/\w\S*/g, (text) => {
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
    });
    return formatted;
  };

  const formatModelName = (name) => {
    let formatted = name.replace("_", " ");
    return formatted;
  };

  const onStepIconClick = (step) => {
    if (step < activeStep) {
      setActiveStep(step);
    }
  };

  const loadPi = (snapshot, updatedFormData) => {
    updatedFormData.firstName = snapshot["person"]["first_name"] || "";
    updatedFormData.lastName = snapshot["person"]["last_name"] || "";
    updatedFormData.address1 = snapshot["addresses"][0]["address_line1"] || "";
    updatedFormData.address2 = snapshot["addresses"][0]["address_line2"] || "";
    updatedFormData.city = snapshot["addresses"][0]["city_name"] || "";
    updatedFormData.state = snapshot["addresses"][0]["state_or_province"] || "";
    updatedFormData.zip = snapshot["addresses"][0]["postal_code"] || "";
    updatedFormData.emailAddress = snapshot["emails"][0]["email_address"] || "";
    updatedFormData.phone =
      phoneFormatter(snapshot["phones"][0]["phone_number"]) || "";

    return updatedFormData;
  };

  const loadAnswers = (answers, updatedFormData) => {
    let key;
    for (let i = 0; i < Object.keys(answers).length; i++) {
      key = Object.keys(answers)[i];
      if (Object.prototype.hasOwnProperty.call(formData, key)) {
        updatedFormData[key] = answers[key];
      }
    }

    return updatedFormData;
  };

  // function to populate dealer info into formData under the dealer object
  const loadDealerInfo = (dealerData, session, updatedFormData) => {
    updatedFormData.dealer.bac = session.data["bac"] || "";
    // guard clause to prevent undefined errors when the dealer data returned is not as expected
    if (!dealerData.locatorResult?.[0]?.vendor?.contact?.length) {
      return updatedFormData;
    }
    // map through the dealer data to find the 'main' contact info values
    // dealerData.locatorResult[0].vendor.contact.map((dealerContacts) => {
    // 	if (dealerContacts.type === 'main') {
    // 		updatedFormData.dealer.name = nameFormatter(dealerContacts.name[0].value) || '';
    // 		updatedFormData.dealer.address = dealerContacts.address[0] || {};
    // 		updatedFormData.dealer.email = dealerContacts.email;
    // 		dealerContacts.phone.map((dealerPhone) => {
    // 			if (dealerPhone.type === 'dealerMain') {
    // 				updatedFormData.dealer.phone = phoneFormatter(dealerPhone.number);
    // 			}
    // 		});
    // 	}
    // });
    // using our EVIQ dealer find API we take the first element as the main contact
    const dealerContact = dealerData.locatorResult[0].vendor.contact[0];
    updatedFormData.dealer.name =
      nameFormatter(dealerContact.name[0].value) || "";
    updatedFormData.dealer.address = dealerContact.address[0] || {};
    updatedFormData.dealer.email = dealerContact.email;
    dealerContact.phone.map((dealerPhone) => {
      if (dealerPhone.type === "dealerMain") {
        updatedFormData.dealer.phone = phoneFormatter(dealerPhone.number);
      }
    });
    return updatedFormData;
  };

  const loadImages = (images) => {
    let savedImages = {};
    let imageQuestionId;
    let imageMap = {
      47: "question22",
      48: "question23",
      49: "question24",
      72: "question25",
      125: "question42",
      126: "question43",
    };
    for (let image in images) {
      if (images[image]) {
        imageQuestionId = imageMap[images[image].split("_").slice(-1)[0]];
        savedImages[imageQuestionId] = images[image];
      }
    }

    setCustomerImages(savedImages);
  };

  const determineSteps = (requestType, formData, images) => {
    let setupSteps;
    if (requestType === "Install") {
      setupSteps = [
        // Dealer Pre-Authorized User
        "Welcome",
        "Address",
        "Rent or Own",
        "Property Detail",
        "Garage Type",
        "Electrical Panel Details",
        "Install Location",
        "Load Calculation",
        "Additional Site Information",
        "Upload Images",
        "Confirmation",
      ];
    } else if (requestType === "Self_Pay") {
      setupSteps = [
        // Email-Verified Guest User
        "Welcome",
        "Address",
        "Vehicle Model Year",
        "Charger Type",
        "Rent or Own",
        "Property Detail",
        "Garage Type",
        "Charging Unit or Outlet",
        "Electrical Panel Details",
        "Install Location",
        "Load Calculation",
        "Additional Site Information",
        "Upload Images",
        "Confirmation",
      ];
    } else {
      setupSteps = []; // If not Install or Self pay, show error
    }

    if (formData) {
      if (
        !formData.termsAccepted &&
        (requestType === "Install" || requestType === "Self_Pay")
      ) {
        setupSteps.splice(setupSteps.length - 1, 0, "Terms");
      }

      const latestStep = determineLatestStep(formData, setupSteps, images);
      for (let i = 0; i < setupSteps.length; i++) {
        if (latestStep === setupSteps[i]) {
          setActiveStep(i);
        }
      }
    } else {
      setupSteps.splice(setupSteps.length - 1, 0, "Terms");
      console.log("No form data - Cannot determine latest step");
    }

    setSteps(setupSteps);
  };

  const setSession = async (sessionId) => {
    const session = await getSession(
      sessionId,
      props.meta.qmerit,
      backendBaseUrl
    );
    let updatedFormData = formData;

    if (
      session.data &&
      session.data["requestId"] &&
      session.data["brand"]?.toLowerCase() === "chevrolet"
    ) {
      setRequestId(session.data["requestId"]);
      setStatusId(session.data["statusId"]);
      setRequestType(session.data["requestType"]);
      //setSnapshotId(session.data['snapshotId']);
      updatedFormData.requestId = session.data["requestId"] || "";
      updatedFormData.orderNumber = session.data["orderNumber"] || "";
      updatedFormData.vin = session.data["vin"] || "";
      updatedFormData.termsAccepted = !!session.data["termsAccepted"];
      updatedFormData.piAckAccepted = !!session.data["termsAccepted"];
      if (session.data["images"]) {
        loadImages(session.data["images"]); // Populate images
      }
      if (session.data["snapshot"]) {
        updatedFormData = loadPi(session.data["snapshot"], updatedFormData); // Populate PI info
      }
      if (session.data["responses"]) {
        updatedFormData = loadAnswers(
          session.data["responses"],
          updatedFormData
        ); // Populate answers
      }
      if (session.data["dealerData"]) {
        updatedFormData = loadDealerInfo(
          session.data["dealerData"],
          session,
          updatedFormData
        ); // Populate dealer info
      }

      setFormData({ ...updatedFormData });
      if (session.data["requestType"] === "Install") {
        setIsDealerAuthorized(true);
      }

      if (
        session.data["requestType"] === "Self_Pay" &&
        session.data["orderNumber"]
      ) {
        setIsCustomerpay(true);
      }

      if (session.data["statusId"] < 4) {
        determineSteps(
          session.data["requestType"],
          updatedFormData,
          session.data["images"]
        );
      }
    } else {
      console.log("Error loading user session: ", session.error);
      setLoading(false);
    }
  };

  const getVehicleYMM = async (vin, apis, backendBaseUrl) => {
    const ymmInfo = await vinLookup(vin, apis, backendBaseUrl);
    if (!ymmInfo.data || !ymmInfo.data.VINLookupResponse) {
      return;
    }
    return ymmInfo.data.VINLookupResponse;
  };

  useEffect(() => {
    const hashString = window.location.hash;
    console.log(hashString);
    const queryString = hashString
      ? hashString.split("?")[1]
      : window.location.search.slice(1);
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    let urlSessionId = urlParams.get("sessionId");
    console.log(urlSessionId);
    if (urlSessionId) {
      setSession(urlSessionId);
    } else {
      // New Guest user
      setSteps([
        "Welcome",
        "Address",
        "Vehicle Model Year",
        "Charger Type",
        "Rent or Own",
        "Property Detail",
        "Garage Type",
        "Terms",
        "Confirmation",
      ]);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (statusId && requestId) {
      setLoading(false);
    }
  }, [statusId, requestId]);

  useEffect(() => {
    if (activeStep !== 0 && stepRefs.current[activeStep - 1]) {
      setTimeout(() => {
        stepRefs.current[activeStep - 1].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 250);
    }
  }, [activeStep]);

  const validateAndSaveStep = async () => {
    if (steps[activeStep] === "Terms") {
      const [success, termsChecked, piAckChecked] =
        termRef.current.validateTermsForm();
      if (success) {
        setFormData((preState) => ({
          ...preState,
          termsAccepted: termsChecked,
          piAckAccepted: piAckChecked,
        }));

        openConfirmationModal();
      }
      return false;
    } else if (steps[activeStep] === "Address") {
      clearErrorMsgs();
      const [success, data] = addrRef.current.validateAddressForm();
      if (success && !requestId) {
        setFormData((preState) => ({
          ...preState,
          firstName: data.firstName,
          lastName: data.lastName,
          address1: data.address1,
          address2: data.address2,
          city: data.city,
          state: data.state,
          zip: data.zip,
          emailAddress: data.emailAddress,
          phone: data.phone,
        }));

        const validateAddrRes = await validateAddress(
          data,
          props.meta.qmerit,
          backendBaseUrl
        );
        if (
          validateAddrRes.data &&
          validateAddrRes.data.MatchResult === "PartialMatch"
        ) {
          const suggestedAddress =
            validateAddrRes.data.ListOfAddresses.Address[0];
          setMatchedAddress((preState) => ({
            ...preState,
            address1: suggestedAddress.AddressLine1,
            city: suggestedAddress.City,
            state: suggestedAddress.Province_ISO,
            zip: suggestedAddress.Zipcode,
          }));

          openAddressValidationModal();
          return false;
        } else if (validateAddrRes.error) {
          if (validateAddrRes.error.response.status === 404) {
            console.log("Address not valid");
            setAddressError(true);
            return false;
          } else {
            setAddressServerError(true);
            return false;
          }
        } else {
          return true;
        }
      } else if (success && requestId) {
        // go to next step if no vin entered
        if (!formData.vin) {
          return true;
        }
        // call vinlookup on Request Information section for dealer promo requests if vin exists
        const ymmRes = await getVehicleYMM(
          formData.vin,
          props.meta.qmerit,
          backendBaseUrl
        );
        const ymm = `${ymmRes.year} ${
          ymmRes.division?.divisionName
        } ${formatModelName(ymmRes.modelDescription)}`;

        let vehicleModel = "";
        if (
          ymm === "2022 Chevrolet Bolt EV" ||
          ymm === "2022 Chevrolet BOLT EV"
        ) {
          vehicleModel = "37";
        }
        if (
          ymm === "2022 Chevrolet Bolt EUV" ||
          ymm === "2022 Chevrolet BOLT EUV"
        ) {
          vehicleModel = "38";
        }
        if (
          ymm === "2023 Chevrolet Bolt EV" ||
          ymm === "2023 Chevrolet BOLT EV"
        ) {
          vehicleModel = "207";
        }
        if (
          ymm === "2023 Chevrolet Bolt EUV" ||
          ymm === "2023 Chevrolet BOLT EUV"
        ) {
          vehicleModel = "208";
        }
        setFormData((preState) => ({
          ...preState,
          question18: vehicleModel || "10",
          question19: !vehicleModel ? ymm : "",
        }));
        // send ymm info into qmerit form
        if (requestId && !submitted) {
          return postAnswers(
            {
              question18: vehicleModel || "10",
              question19: !vehicleModel ? ymm : "",
            },
            props.meta.qmerit,
            requestId,
            backendBaseUrl
          )
            .then((res) => {
              if (res.data) {
                return success;
              } else {
                setResponseSaveError(true);
                return false;
              }
            })
            .catch((err) => {
              setResponseSaveError(true);
              return false;
            });
        } else {
          return success;
        }
      } else {
        return false;
      }
    } else if (steps[activeStep] === "Vehicle Model Year") {
      const [success, q18, q19, vin, disabledDropdown] =
        group0Ref.current.validateGroup0Form();
      if (success) {
        setFormData((preState) => ({
          ...preState,
          question18: q18,
          question19: q19,
          vin: vin,
        }));
        setdisabledDropdown(disabledDropdown);
        if (requestId && !submitted) {
          return postAnswers(
            {
              question18: q18,
              question19: q19,
            },
            props.meta.qmerit,
            requestId,
            backendBaseUrl
          )
            .then((res) => {
              if (res.data) {
                return success;
              } else {
                setResponseSaveError(true);
                return false;
              }
            })
            .catch((err) => {
              setResponseSaveError(true);
              return false;
            });
        } else {
          return success;
        }
      } else {
        return false;
      }
    } else if (steps[activeStep] === "Charger Type") {
      const [success, chargerType] =
        chargerTypeRef.current.validateChargerTypeForm();
      if (success) {
        setFormData((preState) => ({
          ...preState,
          chargerType: chargerType,
        }));
        setdisabledDropdown(disabledDropdown);
        if (requestId && !submitted) {
          return postAnswers(
            {
              chargerType: chargerType,
            },
            props.meta.qmerit,
            requestId,
            backendBaseUrl
          )
            .then((res) => {
              if (res.data) {
                return success;
              } else {
                setResponseSaveError(true);
                return false;
              }
            })
            .catch((err) => {
              setResponseSaveError(true);
              return false;
            });
        } else {
          return success;
        }
      }
    } else if (steps[activeStep] === "Rent or Own") {
      const [RentOrOwnSuccess, RentOrOwnData] =
        group1Ref.current.validateGroup1Form();
      // Clear unasked question values
      if (RentOrOwnData.question1 === "39") {
        RentOrOwnData.question2b = "";
      }
      if (RentOrOwnData.question1 === "2") {
        RentOrOwnData.question2a = "";
      }
      if (RentOrOwnSuccess && RentOrOwnData) {
        setFormData((preState) => ({
          ...preState,
          question1: RentOrOwnData.question1,
          question2a: RentOrOwnData.question2a,
          question2b: RentOrOwnData.question2b,
        }));

        if (requestId && !submitted) {
          return postAnswers(
            {
              question1: RentOrOwnData.question1,
              question2a: RentOrOwnData.question2a,
              question2b: RentOrOwnData.question2b,
            },
            props.meta.qmerit,
            requestId,
            backendBaseUrl
          )
            .then((res) => {
              if (res.data) {
                return RentOrOwnSuccess;
              } else {
                setResponseSaveError(true);
                return false;
              }
            })
            .catch((err) => {
              setResponseSaveError(true);
              return false;
            });
        } else {
          return RentOrOwnSuccess;
        }
      } else {
        return false;
      }
    } else if (steps[activeStep] === "Property Detail") {
      const [success, data] = group2Ref.current.validateGroup2Form();
      // If 'House' was selected, autopopulate 'has dedicated meter' to 'yes'
      if (data.question3 === "3") {
        data.question4 = "18";
      }
      // Clear unasked question values
      if (data.question3 !== "3") {
        data.question28 = "";
        data.question29 = "";
      }
      if (success) {
        setFormData((preState) => ({
          ...preState,
          question3: data.question3,
          question4: data.question4,
          question28: data.question28,
          question29: data.question29,
        }));

        if (requestId && !submitted) {
          return postAnswers(
            {
              question3: data.question3,
              question4: data.question4,
              question28: data.question28,
              question29: data.question29,
            },
            props.meta.qmerit,
            requestId,
            backendBaseUrl
          )
            .then((res) => {
              if (res.data) {
                return success;
              } else {
                setResponseSaveError(true);
                return false;
              }
            })
            .catch((err) => {
              setResponseSaveError(true);
              return false;
            });
        } else {
          return success;
        }
      } else {
        return false;
      }
    } else if (steps[activeStep] === "Garage Type") {
      const [success, data] = group3Ref.current.validateGroup3Form();
      // Clear unasked question values
      if (data.question5 === "25") {
        data.question6 = "";
        data.question20 = "";
        data.question30 = "";
      }
      if (success) {
        setFormData((preState) => ({
          ...preState,
          question5: data.question5,
          question6: data.question6,
          question20: data.question20,
          question30: data.question30,
        }));

        if (requestId && !submitted) {
          return postAnswers(
            {
              question5: data.question5,
              question6: data.question6,
              question20: data.question20,
              question30: data.question30,
            },
            props.meta.qmerit,
            requestId,
            backendBaseUrl
          )
            .then((res) => {
              if (res.data) {
                return success;
              } else {
                setResponseSaveError(true);
                return false;
              }
            })
            .catch((err) => {
              setResponseSaveError(true);
              return false;
            });
        } else {
          return success;
        }
      } else {
        return false;
      }
    } else if (steps[activeStep] === "Install Location") {
      const [success, data] = group4Ref.current.validateGroup4Form();
      if (success) {
        setFormData((preState) => ({
          ...preState,
          question21: data.question21,
          question8: data.question8,
          question9: data.question9,
        }));

        if (requestId && !submitted) {
          return postAnswers(
            {
              question21: data.question21,
              question8: data.question8,
              question9: data.question9,
            },
            props.meta.qmerit,
            requestId,
            backendBaseUrl
          )
            .then((res) => {
              if (res.data) {
                return success;
              } else {
                setResponseSaveError(true);
                return false;
              }
            })
            .catch((err) => {
              setResponseSaveError(true);
              return false;
            });
        } else {
          return success;
        }
      } else {
        return false;
      }
    } else if (steps[activeStep] === "Charging Unit or Outlet") {
      const [success, data] = group5Ref.current.validateGroup5Form();
      // Clear unasked question values
      if (data.question10 === "27") {
        data.question11 = "";
        data.question12 = "";
      }
      if (success) {
        setFormData((preState) => ({
          ...preState,
          question10: data.question10,
          question11: data.question11,
          question12: data.question12,
        }));

        if (requestId && !submitted) {
          return postAnswers(
            {
              question10: data.question10,
              question11: data.question11,
              question12: data.question12,
            },
            props.meta.qmerit,
            requestId,
            backendBaseUrl
          )
            .then((res) => {
              if (res.data) {
                return success;
              } else {
                setResponseSaveError(true);
                return false;
              }
            })
            .catch((err) => {
              setResponseSaveError(true);
              return false;
            });
        } else {
          return success;
        }
      } else {
        return false;
      }
    } else if (steps[activeStep] === "Electrical Panel Details") {
      const [success, data] = group7Ref.current.validateGroup7Form();
      // Clear unasked question values
      if (data.question26 !== "14") {
        data.question31 = "";
      }
      if (success) {
        setFormData((preState) => ({
          ...preState,
          question13: data.question13,
          question14: data.question14,
          question15: data.question15,
          question26: data.question26,
          question31: data.question31,
          question32: data.question32,
          question33: data.question33,
          question34: data.question34,
        }));

        if (requestId && !submitted) {
          return postAnswers(
            {
              question13: data.question13,
              question14: data.question14,
              question15: data.question15,
              question26: data.question26,
              question31: data.question31,
              question32: data.question32,
              question33: data.question33,
              question34: data.question34,
            },
            props.meta.qmerit,
            requestId,
            backendBaseUrl
          )
            .then((res) => {
              if (res.data) {
                return success;
              } else {
                setResponseSaveError(true);
                return false;
              }
            })
            .catch((err) => {
              setResponseSaveError(true);
              return false;
            });
        } else {
          return success;
        }
      } else {
        return false;
      }
    } else if (steps[activeStep] === "Load Calculation") {
      const [success, data] = group9Ref.current.validateGroup9Form();
      if (success) {
        setFormData((preState) => ({
          ...preState,
          question35: data.question35,
          question36: data.question36,
          question37: data.question37,
          question38: data.question38 ? data.question38 : "0",
        }));

        if (requestId && !submitted) {
          return postAnswers(
            {
              question35: data.question35,
              question36: data.question36,
              question37: data.question37,
              question38: data.question38 ? data.question38 : "0",
            },
            props.meta.qmerit,
            requestId,
            backendBaseUrl
          )
            .then((res) => {
              if (res.data) {
                return success;
              } else {
                setResponseSaveError(true);
                return false;
              }
            })
            .catch((err) => {
              setResponseSaveError(true);
              return false;
            });
        } else {
          return success;
        }
      } else {
        return false;
      }
    } else if (steps[activeStep] === "Additional Site Information") {
      const [success, data] = group10Ref.current.validateGroup10Form();
      if (success) {
        setFormData((preState) => ({
          ...preState,
          question39: data.question39,
          question40: data.question40,
        }));

        if (requestId && !submitted) {
          return postAnswers(
            {
              question39: data.question39,
              question40: data.question40,
            },
            props.meta.qmerit,
            requestId,
            backendBaseUrl
          )
            .then((res) => {
              if (res.data) {
                return success;
              } else {
                setResponseSaveError(true);
                return false;
              }
            })
            .catch((err) => {
              setResponseSaveError(true);
              return false;
            });
        } else {
          return success;
        }
      } else {
        return false;
      }
    } else if (steps[activeStep] === "Upload Images") {
      const [success, data] = group8Ref.current.validateGroup8Form();
      if (success) {
        setFormData((preState) => ({
          ...preState,
          question16: data.question16,
          question22: data.question22,
          question23: data.question23,
          question24: data.question24,
          question25: data.question25,
          question27: data.question27,
          question42: data.question42,
          question43: data.question43,
        }));

        if (requestId && steps[steps.length - 2] === "Terms") {
          if (submitted) return success;

          return postAnswers(
            {
              question16: data.question16,
              question22: data.question22,
              question23: data.question23,
              question24: data.question24,
              question25: data.question25,
              question27: data.question27,
              question42: data.question42,
              question43: data.question43,
            },
            props.meta.qmerit,
            requestId,
            backendBaseUrl
          )
            .then((res) => {
              if (res.data) {
                return success;
              } else {
                setResponseSaveError(true);
                return false;
              }
            })
            .catch((err) => {
              setResponseSaveError(true);
              return false;
            });
        } else {
          openConfirmationModal();
          return false;
        }
      } else {
        return false;
      }
    }
    //console.log('No validation/save for step ', steps[activeStep]);
    return true;
  };

  const handleNext = async () => {
    let isValidated = await validateAndSaveStep(activeStep);
    if (isValidated) {
      setActiveStep(activeStep + 1);
      clearErrorMsgs();
    }
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    clearErrorMsgs();
  };

  const getIsDealerAuthorized = () => {
    return isDealerAuthorized;
  };

  const getIsCustomerPay = () => {
    return isCustomerPay;
  };

  const generateStepLabel = (label) => {
    if (requestId) {
      return label === "Address" ? "Request Information" : label;
    } else {
      return label === "Confirmation" ? "Email Verification" : label;
    }
  };

  const getStepContent = (step) => {
    switch (steps[step]) {
      case "Welcome":
        return (
          <Welcome
            getIsDealerAuthorized={getIsDealerAuthorized}
            enableNavButton={setIsNavButtonEnabled}
          />
        );
      case "Terms":
        return (
          <Terms
            termsAccepted={formData.termsAccepted}
            piAckAccepted={formData.piAckAccepted}
            submitRef={termRef}
            enableNavButton={setIsNavButtonEnabled}
            getIsDealerAuthorized={getIsDealerAuthorized}
            getIsCustomerPay={getIsCustomerPay}
            content={props.content}
          />
        );
      case "Address":
        return (
          <AddressForm
            formData={formData}
            submitRef={addrRef}
            readOnly={!!requestId}
            getIsDealerAuthorized={getIsDealerAuthorized}
            handleNext={handleNext}
            enableNavButton={setIsNavButtonEnabled}
          />
        );
      case "Vehicle Model Year":
        return (
          <Group0
            question18={formData.question18}
            question19={formData.question19}
            submitRef={group0Ref}
            enableNavButton={setIsNavButtonEnabled}
            backendBaseUrl={backendBaseUrl}
            vin={formData.vin}
            disabledDropdown={disabledDropdown}
          />
        );
      case "Charger Type":
        return (
          <ChargerType
            chargerType={formData.chargerType}
            submitRef={chargerTypeRef}
            enableNavButton={setIsNavButtonEnabled}
            backendBaseUrl={backendBaseUrl}
            disabledDropdown={disabledDropdown}
          />
        );
      case "Rent or Own":
        return (
          <Group1
            submitRef={group1Ref}
            getIsDealerAuthorized={getIsDealerAuthorized}
            formData={{
              dealer: formData.dealer,
              question1: formData.question1,
              question2a: formData.question2a,
              question2b: formData.question2b,
            }}
            enableNavButton={setIsNavButtonEnabled}
          />
        );
      case "Property Detail":
        return (
          <Group2
            submitRef={group2Ref}
            getIsDealerAuthorized={getIsDealerAuthorized}
            formData={{
              dealer: formData.dealer,
              question3: formData.question3,
              question4: formData.question4,
              question28: formData.question28,
              question29: formData.question29,
            }}
            enableNavButton={setIsNavButtonEnabled}
          />
        );
      case "Garage Type":
        return (
          <Group3
            submitRef={group3Ref}
            getIsDealerAuthorized={getIsDealerAuthorized}
            formData={{
              dealer: formData.dealer,
              question5: formData.question5,
              question6: formData.question6,
              question20: formData.question20,
              question30: formData.question30,
            }}
            enableNavButton={setIsNavButtonEnabled}
            imagePath={imagePath}
          />
        );
      case "Install Location":
        return (
          <Group4
            submitRef={group4Ref}
            getIsDealerAuthorized={getIsDealerAuthorized}
            formData={{
              question21: formData.question21,
              question8: formData.question8,
              question9: formData.question9,
            }}
            enableNavButton={setIsNavButtonEnabled}
          />
        );
      case "Load Calculation":
        return (
          <Group9
            submitRef={group9Ref}
            formData={{
              question35: formData.question35,
              question36: formData.question36,
              question37: formData.question37,
              question38: formData.question38,
            }}
            enableNavButton={setIsNavButtonEnabled}
          />
        );
      case "Charging Unit or Outlet":
        return (
          <Group5
            submitRef={group5Ref}
            formData={{
              question10: formData.question10,
              question11: formData.question11,
              question12: formData.question12,
            }}
            enableNavButton={setIsNavButtonEnabled}
          />
        );
      case "Electrical Panel Details":
        return (
          <Group7
            submitRef={group7Ref}
            formData={{
              question13: formData.question13,
              question14: formData.question14,
              question15: formData.question15,
              question26: formData.question26,
              question31: formData.question31,
              question32: formData.question32,
              question33: formData.question33,
              question34: formData.question34,
            }}
            enableNavButton={setIsNavButtonEnabled}
            imagePath={imagePath}
          />
        );
      case "Upload Images":
        return (
          <Group8
            submitRef={group8Ref}
            formData={{
              question16: formData.question16,
              question22: formData.question22,
              question23: formData.question23,
              question24: formData.question24,
              question25: formData.question25,
              question27: formData.question27,
              question42: formData.question42,
              question43: formData.question43,
            }}
            enableNavButton={setIsNavButtonEnabled}
            requestId={requestId}
            images={customerImages}
            apis={props.meta.qmerit}
            setFormImages={setCustomerImages}
            backendBaseUrl={backendBaseUrl}
            imagePath={imagePath}
          />
        );
      case "Additional Site Information":
        return (
          <Group10
            submitRef={group10Ref}
            formData={{
              question39: formData.question39,
              question40: formData.question40,
            }}
            enableNavButton={setIsNavButtonEnabled}
          />
        );
      case "Confirmation":
        return <Confirmation isFinalStep={!!requestId} />;
      default:
        throw new Error("Unknown step");
    }
  };

  return (
    <section className={`${styles.container}`}>
      {loading && (
        <div className={`${styles.spinner}`}>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <div>
          {(statusId >= 4 || requestType === "EVGo") && (
            <CustomerInfoDisplay
              formData={formData}
              statusId={statusId}
              requestType={requestType}
              apis={props.meta.qmerit}
              backendBaseUrl={backendBaseUrl}
            />
          )}
          {statusId < 4 && requestType !== "EVGo" && (
            <>
              {steps.length === 0 && (
                <div>
                  <div>
                    <Alert severity="error">
                      We&apos;re sorry, please try again later.
                    </Alert>
                  </div>
                </div>
              )}
              {steps.length > 0 && (
                <Stepper
                  activeStep={activeStep}
                  orientation="vertical"
                  classes={{ root: classes.stepper }}
                >
                  {steps.map((label, index) => (
                    <Step
                      key={label}
                      ref={(el) => (stepRefs.current[index] = el)}
                    >
                      {label === "Terms" || label === "Confirmation" ? (
                        <StepLabel
                          classes={{ label: `headline-5--primary` }}
                          StepIconProps={{
                            classes: {
                              text: `small--primary`,
                              root: index < activeStep ? classes.stepIcon : "",
                            },
                          }}
                          icon="&#9679;"
                        >
                          {generateStepLabel(label)}
                        </StepLabel>
                      ) : (
                        <StepLabel
                          classes={{ label: `headline-5--primary` }}
                          StepIconProps={{
                            classes: {
                              text: `small--primary`,
                              root: index < activeStep ? classes.stepIcon : "",
                            },
                          }}
                          onClick={() => {
                            onStepIconClick(index);
                          }}
                        >
                          {generateStepLabel(label)}
                        </StepLabel>
                      )}
                      <StepContent>
                        <div>{getStepContent(index)}</div>
                        {submitError && (
                          <div>
                            <Alert severity="error">
                              We&apos;re sorry, there was an error submitting
                              your EV Install Form.
                            </Alert>
                          </div>
                        )}
                        {responseSaveError && (
                          <div className={classes.errorMsg}>
                            <Alert severity="error">
                              We&apos;re sorry, there was an error saving your
                              response.
                            </Alert>
                          </div>
                        )}
                        {addressError && (
                          <div className={classes.errorMsg}>
                            <Alert severity="error">
                              Please enter a valid address.
                            </Alert>
                          </div>
                        )}
                        {addressServerError && (
                          <div className={classes.errorMsg}>
                            <Alert severity="error">
                              We&apos;re sorry, there is a server error.
                            </Alert>
                          </div>
                        )}
                        {activeStep !== steps.length - 1 ? (
                          <div className={classes.actionsContainer}>
                            <div>
                              <NavButton
                                currentStep={activeStep + 1}
                                totalSteps={steps.length}
                                hideBackAction="true"
                                handleNext={handleNext}
                                handleBack={handleBack}
                                backButtonEnabled={activeStep > 0}
                                nextButtonEnabled={isNavButtonEnabled}
                              />
                            </div>
                          </div>
                        ) : null}
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              )}
            </>
          )}
          <Modal
            classes={classes.modalRoot}
            show={showConfirmationModal}
            hideModal={() => {
              closeConfirmationModal(activeStep);
            }}
          >
            <div>
              <center>
                {requestId
                  ? "Are you sure you want to submit?"
                  : "Please click continue to receive your email verification"}
              </center>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={5} md={3}>
                  <button
                    data-dtm="request installation"
                    className={`${
                      classes.modalButtons
                    } stat-button-link button ${
                      submitting || submitted
                        ? classes.buttonDisabled
                        : "button-primary"
                    }`}
                    onClick={submitForm}
                    disabled={submitting || submitted}
                  >
                    {requestId ? "Yes, submit" : "Yes, Continue"}
                  </button>
                </Grid>
                <Grid item xs={5} md={3}>
                  <button
                    data-dtm="request installation"
                    className={`${classes.modalButtons} stat-button-link button button-secondary`}
                    onClick={() => {
                      closeConfirmationModal(activeStep);
                    }}
                  >
                    No, go back
                  </button>
                </Grid>
              </Grid>
            </div>
          </Modal>

          <Modal
            show={showAddressValidationModal}
            hideModal={closeAddressValidationModal}
            classes={classes.modalRoot}
          >
            <div>
              <Grid container spacing={3} className={classes.addrModalContent}>
                <Grid item xs={12}>
                  <div className={"block-quote-lg--primary"}>
                    <p>Please select the correct address</p>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} className={classes.lineDivide}>
                  <div className={"block-quote-lg--primary"}>
                    <p>Suggested Address</p>
                  </div>

                  <p>{`${matchedAddress.address1}`}</p>
                  <p>{`${formData.address2}`}</p>
                  <p>{`${matchedAddress.city}, ${matchedAddress.state} ${matchedAddress.zip}`}</p>
                  <button
                    className={`${classes.modalButtons} button button-primary`}
                    onClick={() => {
                      selectAddress(true);
                    }}
                  >
                    Use Suggested Address
                  </button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className={"block-quote-lg--primary"}>
                    <p>Original Address</p>
                  </div>

                  <p>{`${formData.address1}`}</p>
                  <p>{`${formData.address2}`}</p>
                  <p>{`${formData.city}, ${formData.state} ${formData.zip}`}</p>
                  <button
                    className={`${classes.modalButtons} button button-primary`}
                    onClick={() => {
                      selectAddress(false);
                    }}
                  >
                    Use Original Address
                  </button>
                </Grid>
              </Grid>
            </div>
          </Modal>
        </div>
      )}
    </section>
  );
};

export default HomeChargerForm;
