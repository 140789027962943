import React, { useEffect, useReducer, useState, useImperativeHandle } from 'react';
import styles from '../../home-charger-form.module.scss';
import Message from '../UI/QuestionTypes/Message';
import dataValidation from './dataValidation';
import { ErrorOutline } from '@material-ui/icons';
import {
	Grid,
	Typography,
	Box,
	FormControl,
	MenuItem,
	InputLabel,
	InputAdornment,
	IconButton,
	OutlinedInput,
	Tooltip,
	makeStyles,
	withStyles,
	Select,
} from '@material-ui/core';
import ExpandMoreOutlinedIcon from '@material-ui/core/SvgIcon/SvgIcon';
import { fieldAttributes, stateList, convertState } from './fieldsConfig';

const AddressForm = ({ formData, submitRef, readOnly, enableNavButton, getIsDealerAuthorized }) => {
	const useStyles = makeStyles((theme) => ({
		// update to override styles on AEM :c
		inputField: {
			"&.MuiOutlinedInput-input": {
				padding: '18.5px 14px',
				height: '1.1876em',
				margin: '0',
				boxSizing: 'content-box',
				border: '0',
				background: 'none',
				font: 'inherit'
			}
		},
		unChecked: {
			color: '#5f5e5f',
			width: '40px',
			height: '40px',
		},
		checked: {
			color: '#0079BF',
			width: '40px',
			height: '40px',
		},
		errorIcon: {
			color: 'red',
		},
		selectAdornment: {
			marginRight: theme.spacing(3),
		}
	}));

	const CustomTooltip = withStyles((theme) => ({
		tooltip: {
			backgroundColor: theme.palette.common.white,
			fontSize: '16px',
			color: 'red',
		},
	}))(Tooltip);

	const classes = useStyles();

	const [submitting, setSubmitting] = useState(false);
	const [editablePhone, setEditablePhone] = useState(false);

	const formReducer = (state, event) => {
		if (event.reset) {
			return {
				firstName: formData.firstName || '',
				lastName: formData.lastName || '',
				address1: formData.address1 || '',
				address2: formData.address2 || '',
				city: formData.city || '',
				state: formData.state || '',
				zip: formData.zip || '',
				emailAddress: formData.emailAddress || '',
				phone: formData.phone || '',
			};
		}
		return {
			...state,
			[event.name]: event.value,
		};
	};
	const [personalInfo, setPersonalInfo] = useReducer(formReducer, {
		firstName: formData.firstName || '',
		lastName: formData.lastName || '',
		address1: formData.address1 || '',
		address2: formData.address2 || '',
		city: formData.city || '',
		state: formData.state ? convertState(formData.state, 'abbr', 'name') : '',
		zip: formData.zip || '',
		emailAddress: formData.emailAddress || '',
		phone: formData.phone || '',
	});

	useEffect(() => {
		setPersonalInfo({
			reset: true,
		});

		enableNavButton(true);
	}, []);

	const change = (event) => {
		const isPhone = event.target.name === 'phone';

		setPersonalInfo({
			name: event.target.name,
			value: isPhone ? phoneFormatter(event.target.value) : event.target.value,
		});
	};

	const phoneFormatter = (number) => {
		const x = number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
		return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
	};

	const toggleEditPhone = () => {
		setEditablePhone(!editablePhone);
	};

	useImperativeHandle(submitRef, () => ({
		validateAddressForm: () => {
			if (formData.requestId) return [true, {}]; //Because we are not getting existing PI

			setSubmitting(true);
			let errors = dataValidation(personalInfo);
			if (errors.length > 0) {
				return [false, {}];
			} else {
				return [true, personalInfo];
			}
		},
	}));

	const submit = (e) => {
		e.preventDefault();
		setSubmitting(true);
		// let errors = dataValidation(personalInfo);
		// //let errors = [];

		// if (errors.length > 0) {
		// 	console.log(errors);
		// } else {
		// 	console.log('submitting');
		// 	handleSubmit({ personalInfo: personalInfo });
		// }
	};

	const messageText = 'The Chevrolet installation offer is only applicable for home installations.';

	const dealerMessage =
		'This information is populated based on the Dealer’s purchase order information. If this information is incorrect, contact your dealer. Only the person identified on the purchase order is authorized to complete this form and participate.';

	return (
		<>
			<div className="block-quote-lg--primary">
				{readOnly ? <p>Request Information</p> : <p>Enter your Contact Information</p>}
			</div>
			{readOnly && (
				<div>
					{getIsDealerAuthorized() && (
						<div className="paragraph-1--secondary">
							<Message text={dealerMessage} />
						</div>
					)}
					<form noValidate onSubmit={submit}>
						<Grid container spacing={1}>
							<Grid item xs={12} md={3}>
								<Box fontWeight={800}>Request ID: </Box>
							</Grid>
							<Grid item xs={12} md={9}>
								{formData.requestId}
							</Grid>
							{formData.dealer && (
								<>
									{formData.dealer.name && (
										<>
											<Grid item xs={12} md={3}>
												<Box fontWeight={800}>Dealer Name: </Box>
											</Grid>
											<Grid item xs={12} md={9}>
												{formData.dealer.name}
											</Grid>
										</>
									)}
									{formData.dealer.phone && (
										<>
											<Grid item xs={12} md={3}>
												<Box fontWeight={800}>Dealer Phone Number: </Box>
											</Grid>
											<Grid item xs={12} md={9}>
												{formData.dealer.phone}
											</Grid>
										</>
									)}
								</>
							)}
							{formData.orderNumber && (
								<>
									<Grid item xs={12} md={3}>
										<Box fontWeight={800}>Vehicle Order Number: </Box>
									</Grid>
									<Grid item xs={12} md={9}>
										{formData.orderNumber}
									</Grid>
								</>
							)}
							{formData.vin && (
								<>
									<Grid item xs={12} md={3}>
										<Box fontWeight={800}>VIN: </Box>
									</Grid>
									<Grid item xs={12} md={9}>
										{formData.vin}
									</Grid>
								</>
							)}
							{/* <Grid item xs={4} md={3}>
								<Box fontWeight={800}>First Name: </Box>
							</Grid>
							<Grid item xs={8} md={9}>
								{personalInfo.firstName}
							</Grid>
							<Grid item xs={4} md={3}>
								<Box fontWeight={800}>Last Name: </Box>
							</Grid>
							<Grid item xs={8} md={9}>
								{personalInfo.lastName}
							</Grid>
							<Grid item xs={4} md={3}>
								<Box fontWeight={800}>Email Address: </Box>
							</Grid>
							<Grid item xs={8} md={9}>
								{personalInfo.emailAddress}
							</Grid>
							{!editablePhone && (
								<>
									<Grid item xs={4} md={3}>
										<Box fontWeight={800}>Phone Number: </Box>
									</Grid>
									<Grid item xs={8} md={9}>
										{personalInfo.phone}
									</Grid>
									<Grid item xs={12}>
										<a href="#" onClick={toggleEditPhone}>
											Edit Phone
										</a>
									</Grid>
								</>
							)}
							{editablePhone && (
								<Grid item xs={12}>
									<FormControl variant="outlined">
										{submitting && !fieldAttributes.phone.pattern.test(personalInfo['phone']) ? (
											<InputLabel htmlFor="phone" required error>
												Enter Valid Phone Number
											</InputLabel>
										) : (
											<InputLabel htmlFor="phone" required>
												Phone Number
											</InputLabel>
										)}
										<OutlinedInput
											id="phone"
											error={submitting && !fieldAttributes.phone.pattern.test(personalInfo['phone'])}
											name="phone"
											onChange={change}
											value={personalInfo['phone'] || ''}
											inputProps={{
												maxLength: 100,
											}}
											endAdornment={
												submitting && !fieldAttributes.phone.pattern.test(personalInfo['phone']) ? (
													<InputAdornment position="end">
														<CustomTooltip
															title="Please type your phone number"
															className={classes.toolTip}
														>
															<IconButton aria-label="error icon" edge="end">
																<ErrorOutline className={classes.errorIcon} />
															</IconButton>
														</CustomTooltip>
													</InputAdornment>
												) : (
													''
												)
											}
										/>
									</FormControl>
								</Grid>
							)}
							<Grid item xs={4} md={3}>
								<Box fontWeight={800}>Address 1: </Box>
							</Grid>
							<Grid item xs={8} md={9}>
								{personalInfo.address1}
							</Grid>
							<Grid item xs={4} md={3}>
								<Box fontWeight={800}>Address 2: </Box>
							</Grid>
							<Grid item xs={8} md={9}>
								{personalInfo.address2}
							</Grid>
							<Grid item xs={4} md={3}>
								<Box fontWeight={800}>City: </Box>
							</Grid>
							<Grid item xs={8} md={9}>
								{personalInfo.city}
							</Grid>
							<Grid item xs={4} md={3}>
								<Box fontWeight={800}>State: </Box>
							</Grid>
							<Grid item xs={8} md={9}>
								{personalInfo.state}
							</Grid>
							<Grid item xs={4} md={3}>
								<Box fontWeight={800}>Zip: </Box>
							</Grid>
							<Grid item xs={8} md={9}>
								{personalInfo.zip}
							</Grid> */}
						</Grid>
					</form>
				</div>
			)}
			{!readOnly && (
				<div>
					<form className={`${styles.contact_form}`} noValidate onSubmit={submit}>
						<div className={styles.formGrid}>
							<FormControl variant="outlined">
								{submitting && !fieldAttributes.firstName.pattern.test(personalInfo['firstName']) ? (
									<InputLabel htmlFor="firstName" required error>
										Enter Valid First Name
									</InputLabel>
								) : (
									<InputLabel htmlFor="firstName" required>
										First Name
									</InputLabel>
								)}
								<OutlinedInput
									id="firstName"
									name="firstName"
									label="First Name"
									className="stat-input-field"
									data-dtm="address"
									error={
										submitting && !fieldAttributes.firstName.pattern.test(personalInfo['firstName'])
									}
									onChange={change}
									value={personalInfo['firstName'] || ''}
									inputProps={{
										maxLength: fieldAttributes.firstName.maxlength,
										className: classes.inputField
									}}
									endAdornment={
										submitting &&
										!fieldAttributes.firstName.pattern.test(personalInfo['firstName']) ? (
											<InputAdornment position="end">
												<CustomTooltip
													title="Please type your first name"
													className={classes.toolTip}
												>
													<IconButton aria-label="error icon" edge="end">
														<ErrorOutline className={classes.errorIcon} />
													</IconButton>
												</CustomTooltip>
											</InputAdornment>
										) : (
											''
										)
									}
								/>
							</FormControl>
							<FormControl variant="outlined">
								{submitting && !fieldAttributes.lastName.pattern.test(personalInfo['lastName']) ? (
									<InputLabel htmlFor="lastName" required error>
										Enter Valid Last Name
									</InputLabel>
								) : (
									<InputLabel htmlFor="lastName" required>
										Last Name
									</InputLabel>
								)}
								<OutlinedInput
									id="lastName"
									error={
										submitting && !fieldAttributes.lastName.pattern.test(personalInfo['lastName'])
									}
									name="lastName"
									className="stat-input-field"
									data-dtm="address"
									onChange={change}
									value={personalInfo['lastName'] || ''}
									inputProps={{
										maxLength: fieldAttributes.lastName.maxlength,
										className: classes.inputField
									}}
									endAdornment={
										submitting &&
										!fieldAttributes.lastName.pattern.test(personalInfo['lastName']) ? (
											<InputAdornment position="end">
												<CustomTooltip
													title="Please type your last name"
													className={classes.toolTip}
												>
													<IconButton aria-label="error icon" edge="end">
														<ErrorOutline className={classes.errorIcon} />
													</IconButton>
												</CustomTooltip>
											</InputAdornment>
										) : (
											''
										)
									}
								/>
							</FormControl>
							<FormControl variant="outlined">
								{submitting &&
								!fieldAttributes.emailAddress.pattern.test(personalInfo['emailAddress']) ? (
									<InputLabel htmlFor="emailAddress" required error>
										Enter Valid Email Address
									</InputLabel>
								) : (
									<InputLabel htmlFor="emailAddress" required>
										Email Address
									</InputLabel>
								)}
								<OutlinedInput
									id="emailAddress"
									error={
										submitting &&
										!fieldAttributes.emailAddress.pattern.test(personalInfo['emailAddress'])
									}
									name="emailAddress"
									className="stat-input-field"
									data-dtm="address"
									onChange={change}
									value={personalInfo['emailAddress'] || ''}
									inputProps={{
										maxLength: 100,
										className: classes.inputField
									}}
									endAdornment={
										submitting &&
										!fieldAttributes.emailAddress.pattern.test(personalInfo['emailAddress']) ? (
											<InputAdornment position="end">
												<CustomTooltip
													title="Please type your e-mail address in the format yourname@example.com"
													className={classes.toolTip}
												>
													<IconButton aria-label="error icon" edge="end">
														<ErrorOutline className={classes.errorIcon} />
													</IconButton>
												</CustomTooltip>
											</InputAdornment>
										) : (
											''
										)
									}
								/>
							</FormControl>
							<FormControl variant="outlined">
								{submitting && !fieldAttributes.phone.pattern.test(personalInfo['phone']) ? (
									<InputLabel htmlFor="phone" required error>
										Enter Valid Phone Number
									</InputLabel>
								) : (
									<InputLabel htmlFor="phone" required>
										Phone Number
									</InputLabel>
								)}
								<OutlinedInput
									id="phone"
									error={submitting && !fieldAttributes.phone.pattern.test(personalInfo['phone'])}
									name="phone"
									className="stat-input-field"
									data-dtm="address"
									onChange={change}
									value={personalInfo['phone'] || ''}
									inputProps={{
										maxLength: 100,
										className: classes.inputField
									}}
									endAdornment={
										submitting && !fieldAttributes.phone.pattern.test(personalInfo['phone']) ? (
											<InputAdornment position="end">
												<CustomTooltip
													title="Please type your phone number"
													className={classes.toolTip}
												>
													<IconButton aria-label="error icon" edge="end">
														<ErrorOutline className={classes.errorIcon} />
													</IconButton>
												</CustomTooltip>
											</InputAdornment>
										) : (
											''
										)
									}
								/>
							</FormControl>
							<FormControl variant="outlined">
								<InputLabel
									htmlFor="address1"
									required
									error={submitting && personalInfo['address1'] === ''}
								>
									Address Line 1
								</InputLabel>
								<OutlinedInput
									id="address1"
									name="address1"
									label="Address Line 1"
									className="stat-input-field"
									data-dtm="address"
									error={submitting && personalInfo['address1'] === ''}
									onChange={change}
									value={personalInfo['address1'] || ''}
									inputProps={{
										maxLength: 100,
										className: classes.inputField
									}}
									endAdornment={
										submitting && personalInfo['address1'] === '' ? (
											<InputAdornment position="end">
												<CustomTooltip
													title="Please type your Address Line 1"
													className={classes.toolTip}
												>
													<IconButton aria-label="error icon" edge="end">
														<ErrorOutline className={classes.errorIcon} />
													</IconButton>
												</CustomTooltip>
											</InputAdornment>
										) : (
											''
										)
									}
								/>
							</FormControl>
							<FormControl variant="outlined">
								<InputLabel htmlFor="address2">Address Line 2</InputLabel>
								<OutlinedInput
									id="address2"
									name="address2"
									className="stat-input-field"
									data-dtm="address"
									onChange={change}
									value={personalInfo['address2'] || ''}
									inputProps={{
										maxLength: 100,
										className: classes.inputField
									}}
								/>
							</FormControl>
							<FormControl variant="outlined">
								<InputLabel htmlFor="city" required error={submitting && personalInfo['city'] === ''}>
									City
								</InputLabel>
								<OutlinedInput
									id="city"
									error={submitting && personalInfo['city'] === ''}
									name="city"
									className="stat-input-field"
									data-dtm="address"
									onChange={change}
									value={personalInfo['city'] || ''}
									inputProps={{
										maxLength: 50,
										className: classes.inputField
									}}
									endAdornment={
										submitting && personalInfo['city'] === '' ? (
											<InputAdornment position="end">
												<CustomTooltip
													title="Please type your city"
													className={classes.toolTip}
												>
													<IconButton aria-label="error icon" edge="end">
														<ErrorOutline className={classes.errorIcon} />
													</IconButton>
												</CustomTooltip>
											</InputAdornment>
										) : (
											''
										)
									}
								/>
							</FormControl>
							<FormControl variant="outlined">
								<InputLabel htmlFor="state" required error={submitting && personalInfo['state'] === ''}>
									State
								</InputLabel>
								<Select
									id="state"
									error={submitting && personalInfo['state'] === ''}
									name="state"
									className="stat-dropdown"
									data-dtm="address"
									onChange={change}
									value={personalInfo['state'] || ''}
									endAdornment={
										submitting && personalInfo['state'] === '' ? (
											<InputAdornment className={classes.selectAdornment} position="end">
												<CustomTooltip
													title="Please select your state"
													className={classes.toolTip}
												>
													<IconButton aria-label="error icon" edge="end">
														<ErrorOutline className={classes.errorIcon} />
													</IconButton>
												</CustomTooltip>
											</InputAdornment>
										) : (
											''
										)
									}
									IconComponent={ExpandMoreOutlinedIcon}
								>
									<MenuItem value="" disabled>
										Select State
									</MenuItem>
									{stateList.map((state) => (
										<MenuItem key={state.name} value={state.abbr}>
											{state.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl variant="outlined">
								{submitting && !fieldAttributes.zip.pattern.test(personalInfo['zip']) ? (
									<InputLabel htmlFor="zip" required error>
										Enter Valid Zip Code
									</InputLabel>
								) : (
									<InputLabel htmlFor="zip" required>
										Zip Code
									</InputLabel>
								)}
								<OutlinedInput
									id="zip"
									className={`${classes.textInput} stat-input-field`}
									error={submitting && !fieldAttributes.zip.pattern.test(personalInfo['zip'])}
									name="zip"
									data-dtm="address"
									onChange={change}
									value={personalInfo['zip'] || ''}
									inputProps={{
										maxLength: fieldAttributes.zip.maxlength,
										className: classes.inputField
									}}
									endAdornment={
										submitting && !fieldAttributes.zip.pattern.test(personalInfo['zip']) ? (
											<InputAdornment position="end">
												<CustomTooltip
													title="Please type your zip code"
													className={classes.toolTip}
												>
													<IconButton aria-label="error icon" edge="end">
														<ErrorOutline className={classes.errorIcon} />
													</IconButton>
												</CustomTooltip>
											</InputAdornment>
										) : (
											''
										)
									}
								/>
							</FormControl>
						</div>
					</form>
				</div>
			)}
		</>
	);
};

export default AddressForm;
