import React, { useState } from 'react';
import { Typography, Divider, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import DateRender from '../Schedule/DateRender';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CancelAppointment from '../Forms/CancelAppointment';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: '20px',
    textAlign: 'left',
  },
  body: {
    paddingTop: '10px',
    textAlign: 'left',
    paddingBottom: '10px',
  },
  info: {
    paddingTop: '20px',
    paddingBottom: '20px',
    textAlign: 'left',
    // alignItems: 'center',
  },
  buttonContainer: {
    alignItems: 'center',
    padding: '10px',
  },
  button: {
    minWidth: '35%',
  },
  checkcircle: {
    color: '#4caf50',
  },
}));

export default function AppointmentDetails(props) {
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const classes = useStyles();

  return (
    <Paper elevation={3} square>
      <div style={{ marginLeft: '40px', marginRight: '40px' }}>
        <Typography variant='h4' color='textPrimary' className={classes.title}>
          Appointment Details
        </Typography>
        <Divider style={{ marginTop: '10px', marginBottom: '20px' }} />
        <Grid container spacing={3} className={classes.info}>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Typography variant='body2' color='textPrimary'>
              Request ID
            </Typography>
            <br />
            <Typography variant='h5' color='textPrimary'>
              {props.requestID}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Typography variant='body2' color='textPrimary'>
              Date & Time
            </Typography>
            <br></br>
            <Typography variant='h5' color='textPrimary'>
              <DateRender date={props.date} />
              <br />
              {props.time}
            </Typography>
          </Grid>
          {props.status === 'Completed' ? (
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant='h6' color='textPrimary'>
                    <CheckCircleIcon
                      className={classes.checkcircle}
                      fontSize='inherit'
                    />
                    &nbsp; Successfully Completed
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            props.showButton && (
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  {props.booked && (
                    <Grid
                      container
                      spacing={3}
                      className={classes.buttonContainer}
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button
                          variant='contained'
                          color='primary'
                          component={Link}
                          to={`/schedule/${props.requestID}/${props.scheduleId}`}
                          className={classes.button}
                        >
                          Reschedule
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button
                          className={classes.button}
                          onClick={() => {
                            setShowCancelDialog(true);
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </Grid>
            )
          )}
        </Grid>
        <CancelAppointment
          open={showCancelDialog}
          close={() => setShowCancelDialog(false)}
          scheduleId={props.scheduleId}
          requestId={props.requestID}
          date={props.date}
          time={props.time}
        />
      </div>
    </Paper>
  );
}
