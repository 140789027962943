import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import RadioQuestion from '../UI/QuestionTypes/RadioQuestion';
import Message from '../UI/QuestionTypes/Message';
import ToggleQuestion from '../UI/QuestionTypes/ToggleQuestion';
// import getConfig from 'next/config';

const Question5 = (props) => {
	// const { publicRuntimeConfig } = getConfig();
	// const publicRuntimeConfig = { basePath: '/electric' };
	const question = 'Is your garage attached or detached?';
	const options = [
		{
			value: '25',
			text: 'Attached',
			imageUrl: `${props.imagePath}/attachedGarageSample.jpg`,
		},
		{
			value: '26',
			text: 'Detached',
			imageUrl: `${props.imagePath}/detachedGarageSample.jpg`,
		},
	];

	return (
		<RadioQuestion
			dataDtm="garage type"
			name="question5"
			value={props.question5}
			onChange={props.handleChange}
			question={question}
			options={options}
			radioStyle="row"
		/>
	);
};

const Question30 = (props) => {
	const question = 'Does your detached garage have an installed electrical panel?';
	const options = [
		{
			value: '18',
			text: 'Yes',
		},
		{
			value: '19',
			text: 'No',
		},
	];

	return (
		<RadioQuestion
			name="question30"
			dataDtm="garage type"
			value={props.question30}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Question6 = (props) => {
	const question =
		'You do not qualify for Chevrolet’s Covered Home Charging Installation Promotion. However, GM would like to cover up to $1,000 of your installation, as well as your permit fee (up to $250). Would you like to continue? You will be responsible for all additional costs.';
	const options = [
		{ value: '19', text: 'No' },
		{ value: '18', text: 'Yes' },
	];

	return (
		<ToggleQuestion
			name="question6"
			dataDtm="garage type"
			value={props.question6}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Question20 = (props) => {
	const question =
		'Do you intend to install your 240-volt outlet on the side of your house (so you can charge from your driveway?)';
	const options = [
		{ value: '18', text: 'Yes' },
		{ value: '19', text: 'No' },
	];

	return (
		<RadioQuestion
			name="question20"
			dataDtm="garage type"
			value={props.question20}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const Group3 = (props) => {
	const classes = useStyles();
	const { submitRef, enableNavButton } = props;
	const [question5, setQuestion5] = useState(props.formData.question5);
	const [question6, setQuestion6] = useState(props.formData.question6);
	const [question20, setQuestion20] = useState(props.formData.question20);
	const [question30, setQuestion30] = useState(props.formData.question30);

	useEffect(() => {
		let enable;
		if (props.getIsDealerAuthorized()) {
			enable = question5 === '25' || (!!question6 && question6 === '18') || question20 === '18';
		} else {
			enable = question5 === '25' || (question5 === '26' && !!question30);
		}
		enableNavButton(enable);
	}, [question5, question6, question20, question30]);

	useImperativeHandle(submitRef, () => ({
		validateGroup3Form: () => {
			// add validation if needed
			return [
				true,
				{
					question5: question5,
					question6: question6,
					question20: question20,
					question30: question30,
				},
			];
		},
	}));

	const handleQuestion5Change = (newValue) => {
		setQuestion5(newValue);
	};

	const handleQuestion6Change = (newValue) => {
		setQuestion6(newValue);
	};

	const handleQuestion20Change = (newValue) => {
		setQuestion20(newValue);
	};

	const handleQuestion30Change = (newValue) => {
		setQuestion30(newValue);
	};

	let messageText = "Reach out to your authorized dealer about Chevrolet's public charging offer!";
	if (props.formData.dealer.name && props.formData.dealer.phone) {
		messageText = `Reach out to your authorized dealer, ${props.formData.dealer.name}, at ${props.formData.dealer.phone} 
			about Chevrolet's public charging offer!`;
	}

	const messageText2 = 'Based on your selection, you do not qualify for home installation at this time.';

	return (
		<>
			<Box>
				<form className={classes.root} autoComplete="off">
					<Question5 question5={question5} handleChange={handleQuestion5Change} imagePath={props.imagePath} />
					{question5 && question5 === '26' && (
						<>
							<div>
								<Question30 question30={question30} handleChange={handleQuestion30Change} />
							</div>
							{props.getIsDealerAuthorized() &&
								question30 &&
								(question30 === '19' || question30 === '18') && (
									<div>
										<Question20 question20={question20} handleChange={handleQuestion20Change} />
										{question20 && question20 === '19' && (
											<>
												<div>
													<Question6
														question6={question6}
														handleChange={handleQuestion6Change}
													/>
												</div>
												{question6 && question6 === '19' && (
													<div className="paragraph-1--secondary">
														<Message text={messageText2} />
														<Message text={messageText} />
													</div>
												)}
											</>
										)}
									</div>
								)}
						</>
					)}
				</form>
			</Box>
		</>
	);
};

export default Group3;
