import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { getSnapshot } from '../services/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Divider } from '@material-ui/core';
import { Backdrop } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: '20px',
    textAlign: 'left',
  },
  body: {
    paddingTop: '10px',
    textAlign: 'left',
    paddingBottom: '10px',
  },
  info: {
    paddingTop: '20px',
    paddingBottom: '20px',
    textAlign: 'left',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const CustomerInfo = (props) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const searchSnapshotId = async (snapshotId) => {
      setLoading(true);
      const response = await getSnapshot(snapshotId);

      setName(`${response.first_name} ${response.last_name}`);
      setAddress(
        `${response.street_address}${
          response.street_address2 ? ` ${response.street_address2}` : ''
        }, ${response.city_name}, ${response.state_or_province}, ${
          response.postal_code
        }`
      );
      setPhone(`${response.phone_number}`);
      setEmail(`${response.email_address}`);
      setLoading(false);
      props.parentCallback();
    };
    searchSnapshotId(props.snapshot.snapshotId);
  }, [props.snapshot]);

  return (
    <>
      <br></br>
      <Paper elevation={3} square>
        {loading ? (
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color='inherit' />
          </Backdrop>
        ) : (
          <div style={{ marginLeft: '40px', marginRight: '40px' }}>
            <Typography
              variant='h4'
              color='textPrimary'
              className={classes.title}
            >
              Your Information
            </Typography>
            <Typography
              variant='caption'
              color='textPrimary'
              className={classes.body}
            >
              If you need to update your personal information please reach out
              to the GM admin.
            </Typography>
            <Divider style={{ marginTop: '10px', marginBottom: '20px' }} />
            <Grid container spacing={3} className={classes.info}>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <Typography variant='body2' color='textPrimary'>
                  First and Last Name:
                </Typography>
                <br></br>
                <Typography variant='h5' color='textPrimary' style={{ overflowWrap: 'break-word' }}>
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <Typography variant='body2' color='textPrimary'>
                  Home Address:
                </Typography>
                <br></br>
                <Typography variant='h5' color='textPrimary'>
                  {address}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <Typography variant='body2' color='textPrimary'>
                  Phone Number:
                </Typography>
                <br></br>
                <Typography variant='h5' color='textPrimary'>
                  {phone}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <Typography variant='body2' color='textPrimary'>
                  Email Address:
                </Typography>
                <br></br>
                <Typography
                  variant='h5'
                  color='textPrimary'
                  style={{ overflowWrap: 'anywhere' }}
                >
                  {email}
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}
      </Paper>
    </>
  );
};
export default CustomerInfo;
