import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import InfoIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	takeAPhotoContainer: {
		//width:'fit-content'
		width: '200px',
	},

	CameraIconContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		borderRadius: '5px',
		padding: '20px 0 5px 0',
		marginTop: '10px',
		backgroundColor: '#f5f5f0',
	},

	Dot: {
		position: 'absolute',
		zIndex: '100',
		color: 'white',
		margin: '-6px 0 0 22px',
	},

	InfoIcon: {
		fontSize: '17px',
		color: 'gray',
		transform: 'rotate(180deg)',
	},

	InfoSection: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '5px',
	},

	InfoTextContainer: {
		fontSize: '10px',
		color: 'gray',
		paddingLeft: '5px',
		// additional styling needed for AEM
		lineHeight: '14px'
	},

	TakePhotoTextContainer: {
		fontSize: 'x-small',
		marginTop: '20px',
	},
}));

const TakeAPhoto = (props) => {
	const classes = useStyles();
	const showInfoTextContainer = props.showInfoTextContainer;
	const takeAPhotoText = props.takeAPhotoText;

	const infoText = props.bottomContainerText;
	const additionalInfoText =
		' Uploading inappropriate, obscene, or offensive photos is strictly prohibited; GM has the sole and exclusive discretion to disqualify any Customer from this Promotion in the event such photos are uploaded.';

	function openDropZoneDialog() {
		props.openDialog([]);
	}

	return (
		<>
			<div className={`${classes.takeAPhotoContainer} stat-button-link`} data-dtm="upload images">
				<a
					href="#"
					onClick={(e) => {
						e.preventDefault();
						openDropZoneDialog();
          			}}
        		>
					<div className={classes.CameraIconContainer}>
						<div className={classes.Dot}>.</div>
						<PhotoCameraIcon fontSize="large" color={'primary'} />
						<div className={classes.TakePhotoTextContainer}>{takeAPhotoText}</div>
					</div>
				</a>

				{showInfoTextContainer && (
					<div className={classes.InfoSection}>
						<InfoIcon className={classes.InfoIcon} />
						<div className={classes.InfoTextContainer}>
							{infoText}
							{infoText && <br />}
							{additionalInfoText}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default TakeAPhoto;
