import React from 'react'
import {
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: { display: 'flex', justifyContent: 'center', padding: '0px 24px 24px 24px' },
    image: { height: '100px' },
}));

const Header = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <img src='images/cadillac-logo.png' alt='Logo' className={classes.image} />
        </div>
    )
}

export default Header