import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { HomeRoute } from '../Layout/Router';

const ReturnHome = (props) => {
  return (
    <Button
      variant='text'
      startIcon={<ArrowBack />}
      size='large'
      style={{ margin: '15px' }}
      component={Link}
      to={HomeRoute}
    >
      Return home
    </Button>
  );
};

export default ReturnHome;
