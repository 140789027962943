import { createTheme } from '@material-ui/core/styles';

const theme = (style) => {
	if (style && style.default) {
		let { primary, secondary, bpXSmall, bpSmall, bpMedium, bpLarge, bpXLarge } = style.default;

		if (!primary || !secondary) {
			primary = '#cd9834';
			secondary = '#b3b3b3';
		}

		// configure breakpoints from style
		// start with default values
		const breakpointKeys = ['xs', 'sm', 'md', 'lg', 'xl'];
		const breakpointValues = {
			xs: 0,
			sm: +bpXSmall || 600,
			md: +bpSmall || 960,
			lg: +bpMedium || 1280,
			xl: +bpLarge || 1920,
		};

		// apply xxl breakpoint if provided in theme
		if (bpXLarge) {
			breakpointKeys.push('xxl');
			breakpointValues['xxl'] = +bpXLarge;
		}

		return createTheme({
			palette: {
				primary: {
					main: primary,
				},
				secondary: {
					main: secondary,
				},
			},
			overrides: {
				MuiRadio: {
					colorSecondary: {
						color: '',
						'&$checked': {
							color: primary,
						},
					},
				},
				MuiDialog: {
					paper: {
						zIndex: '107159',
					}
				},
				MuiButton: {
					text: {
						"&.Mui-disabled": {
							backgroundColor: 'transparent',
							color: 'rgba(0, 0, 0, 0.26)'
						},
						"&:hover": {
							color: primary
						}
					}
				},
				MuiDropzonePreviewList: {
					image: {
						height: 'auto',
						maxHeight: '400px'
					}
				}
			},
			breakpoints: {
				keys: breakpointKeys,
				values: breakpointValues,
			},
			zIndex: {
				modal: 2000,
			},
		});
	}
};

export default theme;
