import { createTheme } from '@mui/material/styles';

const theme = (style) => {
  if (style) {
    let { primary, secondary, bpXSmall, bpSmall, bpMedium, bpLarge, bpXLarge, bpXXLarge, fonts, whiteFont } = style;

    if (!primary || !secondary) {
      primary = '#cd9834';
      secondary = '#b3b3b3';
    }

    // configure breakpoints from style
    // start with default values
    const breakpointKeys = ['xs', 'sm', 'md', 'lg', 'xl'];
    const breakpointValues = {
      xs: +bpXSmall || 0,
      sm: +bpSmall || 600,
      md: +bpMedium || 960,
      lg: +bpLarge || 1280,
      xl: +bpXLarge || 1920,
    };


    return createTheme({
      palette: {
        primary: {
          main: primary,
        },
        secondary: {
          main: secondary,
        },
        text: {
          primary: primary,
          white: whiteFont,
        },
      },
      typography: {
        fontFamily: [fonts].join(','),
      },
      overrides: {
        MuiRadio: {
          colorSecondary: {
            color: '',
            '&$checked': {
              color: primary,
            },
          },
        },
      },
      breakpoints: {
        keys: breakpointKeys,
        values: breakpointValues,
      },
    });
  }
};

export default theme;
